import * as React from 'react';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';
import { Link } from 'react-router-dom';

interface IProps {
    active: boolean;
    className?: string;
    label: string;
    onClick?: any;
    value?: string;
    linkTo?: string;
    extraComponent?: any;
}

const Tab = (props: IProps) => {
    const { active = false, className, label, onClick, value, linkTo = undefined, extraComponent = undefined } = props;

    const handleClick = () => {
        onClick(value);
    };

    const Component = linkTo ? Link : 'a';

    return (
        <Component
            className={classnames('tab', active ? 'tab--active' : '', className)}
            onClick={handleClick}
            to={linkTo}
        >
            {label}
            {extraComponent && extraComponent}
        </Component>
    );
};

export default Tab;
