import * as React from 'react';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';

export interface IProps {
    id?: string;
    type?: string;
    name?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    onChange?: any;
    onClick?: any;
    onKeyDown?: any;
    value?: string;
    required?: boolean;
    readOnly?: boolean;

    // TODO: maybe inherit from HTMLInputProps
    step?: string;
    min?: number;
    max?: number;
    inputMode?: any;
    onBlur?: any;
    onFocus?: any;
}

const Input = React.forwardRef((props: any, ref: any) => {
    const { className, disabled, onChange, onClick, value, required, readOnly, ...inputProps } = props;

    return (
        <input
            ref={ref}
            className={classnames('input', className)}
            onChange={onChange}
            onClick={onClick}
            disabled={disabled}
            value={value || ''}
            required={required}
            readOnly={readOnly}
            {...inputProps}
        />
    );
});

export default Input;
