import * as React from 'react';
import moment from 'moment';
import { BooleanField, DateField, DecimalField, IntegerField, SelectField, StringField } from '../Fields';
import { useController, useWatch } from 'react-hook-form';
import classnames from '../../../core_updated/utils/classnames';
import { useTranslation } from 'react-i18next';

import InlineSelect from './InlineSelect';
import Switch from '../../../core/components/Switch';
import ListField from '../ListField';
import JSONField from '../JSONField';
import { SliderField } from './Slider';
import { convertPythonDateTimeFormat } from '../../../utils/dates';

import './FieldsForm.scss';
import { CURRENCIES } from '../../../utils';

// based on ValueType from backend
const FIELDS = {
    string: StringField,
    integer: IntegerField,
    float: DecimalField,
    decimal: DecimalField,
    boolean: BooleanField,
    date: DateField,
    datetime: DateField,
    dict: JSONField,
    list: ListField,
};

const PercentageSliderField = ({ initialValue, onUpdate, ...props }) => {
    return (
        <SliderField
            initialValue={initialValue ? initialValue * 100 : undefined}
            onUpdate={(value) => value !== undefined && onUpdate(value / 100)}
            {...props}
            min={0}
            max={100}
            step={1}
        />
    );
};

const getInputControlComponent = (t, formField, value) => {
    // special cases
    let component;

    if (formField.name === 'date_format') {
        component = (props) => {
            const sampleDate = new Date();
            sampleDate.setMonth(0);
            sampleDate.setDate(31);

            const choices = ['%d.%m.%Y', '%d/%m/%Y', '%d-%m-%Y', '%m/%d/%Y'];

            return (
                <InlineSelect
                    {...props}
                    allowCustomValue={true}
                    customSample={(value) =>
                        value &&
                        !choices.includes(value) &&
                        moment(sampleDate).format(convertPythonDateTimeFormat(value))
                    }
                    choices={choices.map((choice) => ({
                        label: choice,
                        value: choice,
                        sample: moment(sampleDate).format(convertPythonDateTimeFormat(choice)),
                    }))}
                />
            );
        };
    } else if (formField.name === 'decimal_separator') {
        component = (props) => {
            const formatSample = (value) => `567${value}89`;
            return (
                <InlineSelect
                    {...props}
                    allowCustomValue={false}
                    customSample={(value) => value && !['.', ','].includes(value) && formatSample(value)}
                    choices={[
                        { label: t('documentConfig.separators.comma'), value: ',', sample: formatSample(',') },
                        { label: t('documentConfig.separators.dot'), value: '.', sample: formatSample('.') },
                    ]}
                />
            );
        };
    } else if (formField.name === 'conversion_style') {
        component = (props) => (
            <InlineSelect
                {...props}
                allowCustomValue={true}
                choices={[
                    // TODO: should this be public?
                    { label: t('documentConfig.conversionStyles.NO_CONVERSION'), value: 'NO_CONVERSION' },
                    { label: t('documentConfig.conversionStyles.ROUND_UP'), value: 'ROUND_UP' },
                    { label: t('documentConfig.conversionStyles.THIRTY_PERCENT_ROUND'), value: 'THIRTY_PERCENT_ROUND' },
                ]}
            />
        );
    } else if (formField.name === 'default_currency') {
        component = (props) => (
            <SelectField options={CURRENCIES.map((currency) => ({ value: currency, label: currency }))} {...props} />
        );
    } else if (formField.name === 'mapping') {
        component = JSONField;
    } else if (formField.name === 'validation_checks') {
        component = ValidationCheckList;
    } else if (formField.name === 'keywords_to_assistance') {
        component = ListField;
    } else if (
        formField.name === 'confidence_threshold' ||
        formField.name === 'header_confidence_threshold' ||
        formField.name === 'line_item_confidence_threshold' ||
        formField.name === 'remove_delivery_address_threshold' ||
        formField.name === 'allow_only_master_data_address'
    ) {
        component = PercentageSliderField;
    } else {
        component = FIELDS[formField.valueType] || StringField;
    }

    return {
        component: component,
    };
};

const ValidationCheckList = ({ initialValue, onUpdate }) => {
    const { t } = useTranslation('config');

    const validationChecks = [
        'forgotten_line_items',
        'date_order',
        'erp_lock',
        'line_item_sum',
        'max_quantity',
        'pos_number',
        'remove_bulk_order_summary',
        'total_amount',
        'customer_internal_note',
        'order_matching',
        'offer_matching',
        'multiple_sales_orgs',
        'thousand_separator_quantity',
        'unique_order_number',
    ];

    const requiredValidationChecks = [];

    const addValidationCheck = (name) => {
        onUpdate([...initialValue, name]);
    };

    const removeValidationCheck = (name) => {
        onUpdate(initialValue?.filter((item) => item !== name) || []);
    };

    const handleToggleSwitch = (name, checked) => {
        if (checked) {
            addValidationCheck(name);
        } else {
            removeValidationCheck(name);
        }
    };

    return validationChecks.map((name) => (
        <div className={classnames('form-field')}>
            <label htmlFor={name} className="form-field__label">
                <span>{t(`documentConfig.validationCheckNames.${name}`)}</span>
                <Switch
                    value={initialValue?.includes(name) || requiredValidationChecks.includes(name)}
                    onValueChange={(checked) => handleToggleSwitch(name, checked)}
                    disabled={requiredValidationChecks.includes(name)}
                />
            </label>
        </div>
    ));
};

export const useFieldsForm = ({ availableFields, configuredFields, translationPrefix }) => {
    const { t } = useTranslation('config');

    const initialValuesByField = {}; // What we get from the database as current value
    const defaultValuesByField = {}; // What we get from the database as default value (e.g. from django fields)
    const formSectionsByField = {};

    const baseFields = configuredFields?.filter((field) => !field.name.includes('__')) || [];

    for (let baseField of baseFields) {
        const formFieldsBySection = {};
        const initialValues = {}; // What we get from the database as current value
        const defaultValues = {}; // What we get from the database as default value (e.g. from django fields)

        configuredFields
            ?.filter(
                (field) =>
                    field.name === baseField.name ||
                    (field.name.startsWith(`${baseField.name}__`) && field.options.length > 0)
            )
            .forEach((field) => {
                formFieldsBySection[field.name] = field.options;
                initialValues[field.name] = {};
                defaultValues[field.name] = {};
                field.options.forEach((configFormField) => {
                    initialValues[field.name][configFormField.name] = configFormField.value;
                    defaultValues[field.name][configFormField.name] = configFormField.defaultValue;
                });
            });

        const formSections = Object.entries(formFieldsBySection).map(([sectionName, formFields]) => ({
            name: sectionName,
            label: t(`${translationPrefix}.${sectionName}`),
            formFields,
        }));
        // sort by name in availableFields
        formSections.sort((a, b) => {
            const indexA = availableFields.findIndex((f) => f.name === a.name);
            const indexB = availableFields.findIndex((f) => f.name === b.name);
            return indexA - indexB;
        });

        initialValuesByField[baseField.name] = initialValues;
        defaultValuesByField[baseField.name] = defaultValues;
        formSectionsByField[baseField.name] = formSections;
    }

    return {
        formSections: formSectionsByField,
        initialValues: initialValuesByField,
        defaultValues: defaultValuesByField,
    };
};

const FormField = ({ control, name, label, render, className, nullable, defaultValue = '', valueType }: any) => {
    if (!defaultValue) defaultValue = '';

    const {
        field: { onChange, onBlur },
    } = useController({
        name: name,
        control: control,
    });

    // field.value uses useWatch with "exact = true" which doesn't work for nested fields,
    // so we need to make it explicit without
    const value = useWatch({ control, name: name });

    const isBoolean = valueType === 'boolean';

    const handleChange = (value) => {
        onChange(value);
    };

    const handleToggleSwitch = (checked) => {
        if (isBoolean) {
            onChange(checked);
        } else if (nullable) {
            onChange(checked ? defaultValue : null);
        }
    };

    return (
        <div className={classnames('form-field', className)}>
            <label htmlFor={name} className="form-field__label">
                <span>{label}</span>
                {(isBoolean || nullable) && (
                    <Switch
                        value={isBoolean ? value : value != undefined}
                        onValueChange={(checked) => handleToggleSwitch(checked)}
                    />
                )}
            </label>
            {!isBoolean &&
                ((nullable && value != undefined) || !nullable) &&
                render?.({ name, value, onChange: handleChange })}
        </div>
    );
};

export const FieldsForm = React.forwardRef(
    (
        {
            form,
            formName,
            fieldName = undefined,
            formSections,
            hideFirstSectionLabel = false, // used for when there is only one section or when the first section is for group options
            initialValues = undefined,
            defaultValues = undefined,
            loading = undefined,
            defaultGetInputControlComponent = getInputControlComponent,
            FormFieldComponent = FormField,
            ...formProps
        }: any,
        ref: any
    ) => {
        const { t } = useTranslation('config');

        return (
            <div className="fields-form" ref={ref}>
                {formSections?.map((section: any, idx) => {
                    return (
                        <div key={section.name} className="fields-form__section js-section" data-name={section.name}>
                            {(!hideFirstSectionLabel || idx !== 0) && (
                                <h3 className="fields-form__section-title">{section.label}</h3>
                            )}
                            {section.formFields?.map((formField) => (
                                <FormFieldComponent
                                    control={form.control}
                                    fieldName={formField.name}
                                    name={`${formName}.${fieldName}.${section.name}.${formField.name}`}
                                    label={t(`documentConfig.fieldNames.${formField.name}`)}
                                    defaultValue={defaultValues?.[section.name]?.[formField.name]}
                                    nullable={formField.isNullable != undefined ? formField.isNullable : true}
                                    valueType={formField.valueType}
                                    render={({ name, value, onChange, onFocus, onBlur }) => {
                                        let ComponentInfo = defaultGetInputControlComponent(t, formField, value);
                                        // TODO: maybe refactor fields to use better prop names e.g. onUpdate -> onValueChange
                                        return (
                                            <ComponentInfo.component
                                                name={name}
                                                id={name}
                                                initialValue={value}
                                                onUpdate={onChange}
                                                inputProps={{
                                                    onFocus: onFocus,
                                                    onBlur: onBlur,
                                                    ...(ComponentInfo.inputProps || {}),
                                                }}
                                                {...(ComponentInfo.fieldProps || {})}
                                            />
                                        );
                                    }}
                                    {...formProps}
                                />
                            ))}
                        </div>
                    );
                })}
            </div>
        );
    }
);

export default FieldsForm;
