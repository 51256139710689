import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import { useTranslation } from 'react-i18next';

import type { IUser } from '../../../users/interfaces';

import Card from '../../../core/components/Card';
import { SecondaryButton } from '../../../core/components/Button';
import Avatar from '../../../core/components/Avatar';

import './style.scss';

interface IProps {
    id: string;
    url: string;
    name: string;
    description?: string;
    isDefault?: string;
    users?: IUser[];
    className?: string;
}

const MAX_USERS = 11;

const TeamCard = (props: IProps) => {
    const { id, url, name, description, isDefault, users = [], className } = props;

    const { t } = useTranslation('teams');

    const more = Math.max(0, users.length - MAX_USERS);

    return (
        <Card className={classnames('team-card', 'card--link', className)} linkTo={url}>
            <div className="team-card__head">
                <h3 className="team-card__title">{name}</h3>
                {isDefault && <div className="team-card__badge"> {t('teamCard.default')} </div>}
            </div>

            {description && <div className="team-card__description">{description}</div>}

            <div className="team-card__users">
                {users.slice(0, MAX_USERS).map((user) => (
                    <Avatar key={user.id} className="team-card__user" fallbackText={user.firstName[0]} />
                ))}
                {more > 0 && <div className="avatar team-card__user team-card__user--more">{more}</div>}
            </div>

            <SecondaryButton label={t('teamCard.button')} className="team-card__button" linkTo={url} />
        </Card>
    );
};

export default TeamCard;
