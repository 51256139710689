import { useMutation, useQuery } from '@apollo/client';

import {
    faArrowDownToLine,
    faEllipsisV,
    faExternalLink,
    faInbox,
    faPlus,
    faXmarkCircle,
} from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../../core_updated/utils/classnames';

import i18n from 'i18next';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringField, useForm } from '../../../assistance/containers/Fields';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import { SecondaryButton } from '../../../core/components/Button';
import Loader from '../../../core/components/Loader';
import MenuItem from '../../../core/components/MenuItem';

import Page from '../../../core/components/Page';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import DropDown, { DropDownMenu, DropDownToggle } from '../../../core/containers/DropDown';
import ProgressButton from '../../../core/containers/ProgressButton';
import { withIcon } from '../../../core_updated/components/Icon';
import { GET_NAVIGATION_CHANNELS } from '../../../core_updated/components/Navigation/internal/queries';
import { CREATE_OUTLOOK_ADDIN, DELETE_OUTLOOK_ADDIN, GET_OUTLOOK_ADDINS, UPDATE_OUTLOOK_ADDIN } from '../../queries';
import { MANIFEST_URL } from './constants';

const AddAddInModal = (props) => {
    const { className, children, onConfirm, onCancel, addInToEdit, ...modalProps } = props;

    const { getFieldProps, formData } = useForm({
        initialData: { name: !!addInToEdit ? addInToEdit.name : '' },
    });
    const { t } = useTranslation('customer');

    // TODO when new channel type available add them
    const [selectedChannels, setSelectedChannels] = useState(
        !!addInToEdit
            ? [
                  ...addInToEdit.orderChannels,
                  ...addInToEdit.invoiceChannels,
                  ...addInToEdit.orderConfirmationChannels,
                  ...addInToEdit.rfqChannels,
                  ...addInToEdit.deliveryNoteChannels,
              ]
            : []
    );

    const {
        data: channelData,
        error: channelError,
        loading: loading,
    } = useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: false,
    });
    // Get a flat list of channels, in a proper Format

    const toChannel = (channelFromOverview) => {
        return {
            id: channelFromOverview.id,
            name: channelFromOverview.name,
            __typename: channelFromOverview.__typename,
        };
    };
    const availableChannels = channelData
        ? Object.keys(channelData)
              .map((key) => channelData[key])
              .flatMap((elem) => elem)
              .map(toChannel)
        : [];

    const handleConfirm = () => {
        onConfirm({
            id: addInToEdit?.id,
            name: formData['name'],
            orderChannels: selectedChannels
                .filter((channel) => channel.__typename === 'OrderProcessingChannelType')
                .map((channel) => channel.id),
            invoiceChannels: selectedChannels
                .filter((channel) => channel.__typename === 'InvoiceProcessingChannelType')
                .map((channel) => channel.id),
            orderConfirmationChannels: selectedChannels
                .filter((channel) => channel.__typename === 'OrderConfirmationProcessingChannelType')
                .map((channel) => channel.id),
            rfqChannels: selectedChannels
                .filter((channel) => channel.__typename === 'RfQProcessingChannelType')
                .map((channel) => channel.id),
            deliveryNoteChannels: selectedChannels
                .filter((channel) => channel.__typename === 'DeliveryNoteProcessingChannelType')
                .map((channel) => channel.id),
        });
    };

    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    const handleChannelSelect = (name, data) => {
        setSelectedChannels([...selectedChannels, data]);
        setShowDropdownMenu(false);
    };
    const handleChannelDeleteFromList = (channelToDelete) => {
        setSelectedChannels(selectedChannels.filter((channel) => channel.id !== channelToDelete.id));
    };

    const AddIcon = withIcon(faPlus);

    const DeleteIcon = withIcon(faXmarkCircle);
    const channelMenuItems = availableChannels.map((channel) => (
        <MenuItem
            key={channel.id}
            value={channel.name}
            label={channel.name}
            description=""
            onClick={() => handleChannelSelect(channel.id, channel)}
            disabled={selectedChannels.some((selectedChannel) => channel.id === selectedChannel.id)}
        />
    ));

    const channelSelectedListItems = selectedChannels.map((channel) => (
        <div className="flex justify-between items-center">
            <span>{channel.name}</span>
            <button className="px-3 py-2 hover:bg-secondary rounded cursor-pointer active:bg-tertiary">
                <DeleteIcon onClick={() => handleChannelDeleteFromList(channel)} />
            </button>
        </div>
    ));

    return (
        <ConfirmModal
            className={classnames('add-addin-modal', className)}
            title={t('integrations.outlookAddIn.addAddInModal.title')}
            onConfirm={handleConfirm}
            onCancel={onCancel}
            labelConfirm={t('integrations.outlookAddIn.addAddInModal.confirm')}
            labelCancel={t('integrations.outlookAddIn.addAddInModal.cancel')}
            noHeader={false}
            {...modalProps}
        >
            {loading ? (
                <Loader />
            ) : (
                <div className="form">
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">{t('integrations.outlookAddIn.addAddInModal.name')}</label>
                        <StringField {...getFieldProps('name')} label="" className="w-full" />
                    </div>
                    <div className="flex justify-between">
                        <label className="font-medium">{t('integrations.outlookAddIn.addAddInModal.channel')}</label>
                        <DropDown
                            className=""
                            onCloseMenu={() => setShowDropdownMenu(false)}
                            menuOrientation="right"
                            menuVisible={showDropdownMenu}
                        >
                            <DropDownToggle>
                                <SecondaryButton
                                    label={
                                        <span className="flex gap-2 items-center">
                                            <AddIcon /> {t('integrations.outlookAddIn.addAddInModal.addChannel')}
                                        </span>
                                    }
                                    onClick={() => setShowDropdownMenu(true)}
                                />
                            </DropDownToggle>
                            <DropDownMenu
                                className="menu--scroll"
                                children={channelMenuItems}
                                loading={loading}
                                emptyItemsContent={
                                    <MenuItem
                                        value=""
                                        label={t('integrations.outlookAddIn.addAddInModal.noChannels')}
                                        disabled={true}
                                    ></MenuItem>
                                }
                            />
                        </DropDown>
                    </div>
                    <div>
                        {channelSelectedListItems.length ? (
                            channelSelectedListItems
                        ) : (
                            <div className="border border-primary w-full p-5 text-center border-solid flex items-center justify-center h-24 text-sm rounded">
                                {t('integrations.outlookAddIn.addAddInModal.noChannelsConnected')}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ConfirmModal>
    );
};

const OutlookAddIn = (props) => {
    const { user } = props;

    const { t } = useTranslation('customer');

    const [addAddInModalVisible, setAddAddInModalVisible] = useState(false);

    const [addInToEdit, setAddInToEdit] = useState();

    moment.locale(user?.language || i18n.language);

    const DownloadIcon = withIcon(faArrowDownToLine);
    const DropdownToggleIcon = withIcon(faEllipsisV);
    const ExternalLinkIcon = withIcon(faExternalLink);
    const TitleLogo = withIcon(faInbox);

    const {
        data: data,
        error: addInError,
        loading: loading,
        refetch: refetch,
    } = useQuery(GET_OUTLOOK_ADDINS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    const addins = data?.outlookAddins || [];

    const [createAddIn, { loading: createAddInLoading, error: createAddInError }] = useMutation(CREATE_OUTLOOK_ADDIN);

    const [updateAddIn, { loading: updateAddInLoading, error: updateAddInError }] = useMutation(UPDATE_OUTLOOK_ADDIN);

    const handleCreateAddIn = (data) => {
        return createAddIn({ variables: { ...data } })
            .then((res) => {
                setAddAddInModalVisible(false);
                refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleUpdateAddIn = (data) => {
        return updateAddIn({ variables: { ...data } })
            .then((res) => {
                setAddInToEdit(null);
                refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [deleteAddIn, { loading: deleteAddInLoading, error: deleteAddInError }] = useMutation(DELETE_OUTLOOK_ADDIN);
    const handleDeleteToken = (id) => {
        return deleteAddIn({ variables: { id } })
            .then((res) => void refetch())
            .catch((error) => {
                console.error(error);
            });
    };

    const error = [addInError, createAddInError, deleteAddInError].find((value) => value);

    const getManifestDownloadURL = (addIn) => {
        return `${MANIFEST_URL}/${addIn.id}/download`;
    };

    const getChannelCountOfAddIn = (addIn) => {
        return [
            ...addIn.orderChannels,
            ...addIn.invoiceChannels,
            ...addIn.orderConfirmationChannels,
            ...addIn.rfqChannels,
            ...addIn.deliveryNoteChannels,
        ].length;
    };

    return (
        <Page className="page overflow-visible">
            <div className="flex justify-between items-center">
                <div className="text-xl font-medium flex gap-4 items-center justify-start">
                    <div className="w-12 gap-1">
                        <TitleLogo className="text-xl" />
                    </div>
                    <div className="gap-1">{t('integrations.outlookAddIn.subTitle')}</div>
                </div>
                <div className="flex gap-4">
                    <a href={t('integrations.outlookAddIn.documentationLink')} target={'_blank'}>
                        <SecondaryButton
                            label={
                                <span className="flex gap-2 items-center">
                                    {t('integrations.outlookAddIn.documentation')}
                                    <ExternalLinkIcon />{' '}
                                </span>
                            }
                        />
                    </a>
                    <ProgressButton
                        label={t('integrations.outlookAddIn.addButton')}
                        onClick={() => setAddAddInModalVisible(true)}
                    />
                </div>
            </div>
            {addAddInModalVisible && (
                <AddAddInModal
                    visible={addAddInModalVisible}
                    onConfirm={handleCreateAddIn}
                    addInToEdit={addInToEdit}
                    onCancel={() => setAddAddInModalVisible(false)}
                />
            )}
            {!!addInToEdit && (
                <AddAddInModal
                    visible={!!addInToEdit}
                    onConfirm={handleUpdateAddIn}
                    addInToEdit={addInToEdit}
                    onCancel={() => setAddInToEdit(null)}
                />
            )}

            {error && (
                <Alert severity="error">
                    <AlertTitle>{error?.message}</AlertTitle>
                </Alert>
            )}

            {[loading, createAddInLoading, deleteAddInLoading, updateAddInLoading].some((value) => value) ? (
                <Loader />
            ) : (
                <div className="mt-6">
                    <div className="">
                        <div className="">
                            {addins.length ? (
                                addins.map((addin) => {
                                    return (
                                        <div className="border border-primary w-full p-5  border-solid flex items-center justify-between rounded">
                                            <div className="flex flex-col gap-3">
                                                <h3 className="font-semibold text-primary">{addin.name}</h3>
                                                <div className="flex gap-2 text-secondary">
                                                    <span>
                                                        {t('integrations.outlookAddIn.channelCount', {
                                                            channelCount: getChannelCountOfAddIn(addin),
                                                        })}
                                                    </span>
                                                    <span>&bull;</span>
                                                    <span>
                                                        {t('integrations.outlookAddIn.updatedAt', {
                                                            updatedAt: moment(addin.updatedAt).format(
                                                                'Do MMMM YYYY, HH:mm'
                                                            ),
                                                        })}
                                                    </span>
                                                    <span>&bull;</span>
                                                    <span>
                                                        {t('integrations.outlookAddIn.version', {
                                                            version: addin.version,
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <a
                                                    href={getManifestDownloadURL(addin)}
                                                    download
                                                    className="px-3 py-2 hover:bg-secondary rounded cursor-pointer active:bg-tertiary text-primary"
                                                >
                                                    <DownloadIcon />
                                                </a>
                                                <DropDown>
                                                    <DropDownToggle>
                                                        <button className="px-3 py-2 hover:bg-secondary rounded cursor-pointer active:bg-tertiary">
                                                            <DropdownToggleIcon />
                                                        </button>
                                                    </DropDownToggle>
                                                    <DropDownMenu>
                                                        <MenuItem
                                                            value={t('integrations.outlookAddIn.editButton')}
                                                            onClick={() => setAddInToEdit(addin)}
                                                        />
                                                        <MenuItem
                                                            value={t('integrations.outlookAddIn.deleteButton')}
                                                            className="text-error"
                                                            onClick={() => handleDeleteToken(addin.id)}
                                                        />
                                                    </DropDownMenu>
                                                </DropDown>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="border border-primary w-full p-5 text-center border-solid flex items-center justify-center h-48 text-sm rounded">
                                    {t('integrations.outlookAddIn.noAddIns')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Page>
    );
};

export default OutlookAddIn;
