import * as React from 'react';
import classnames from '../../../../core_updated/utils/classnames';
import { Slot } from '@radix-ui/react-slot';

interface HeaderButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    active?: boolean;
    variant?: 'outline' | 'ghost';
    asChild?: boolean;
}

const PageHeaderButton = React.forwardRef(
    ({ className, asChild, active = false, variant = 'outline', ...props }: HeaderButtonProps, ref: any) => {
        const Comp: any = asChild ? Slot : 'button';
        return (
            <Comp
                className={classnames(
                    'flex items-center justify-center border border-solid border-secondary outline-none px-3 gap-2 rounded transition-colors hover:bg-secondary h-8 cursor-pointer text-sm font-medium text-primary hover:text-primary disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-brand data-[state=open]:text-brand',
                    variant === 'outline' && 'bg-primary border-primary shadow-sm data-[state=open]:border-brand',
                    active && variant === 'outline' && '!border-brand',
                    variant === 'ghost' && 'bg-transparent border-transparent',
                    active && '!bg-brand !text-brand',
                    className
                )}
                {...props}
                ref={ref}
            />
        );
    }
);

export const PageHeaderIconButton = React.forwardRef(({ className, ...props }: HeaderButtonProps, ref: any) => {
    return <PageHeaderButton className={classnames('w-8 h-8 px-0', className)} {...props} ref={ref} />;
});

export default PageHeaderButton;
