import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import './style.scss';

export interface IProps {
    children?: any;
    id?: string;
    className?: string;
    onSubmit?: any;
}

const Form = (props: IProps) => {
    const { children, className, onSubmit, ...formProps } = props;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (onSubmit) onSubmit(e);
    };

    return (
        <form method="post" className={classnames('form', className)} onSubmit={handleSubmit} {...formProps}>
            {children}
        </form>
    );
};

export default Form;
