import * as React from 'react';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';

interface IProps {
    size?: number;
    imageUrl?: string;
    className?: string;
    fallbackText?: string;
}

const Avatar = ({ size = 36, imageUrl, className, fallbackText }: IProps) => {
    const style = {
        width: `${size}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        fontSize: `${size * 0.4}px`,
        backgroundImage: `url(${imageUrl})`,
    };

    return (
        <span className={classnames('avatar', className)} style={style}>
            {imageUrl ? '' : fallbackText}
        </span>
    );
};

export default Avatar;
