import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { getChildrenByType } from '../../utils/children';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';

export interface IProps {
    className?: string;
    children?: any;
    onChange?: any;
    visible?: boolean;
    activeValue?: string;
}

export const MenuDivider = (props) => {
    const { className } = props;
    return <span className={classnames('menu__divider', className)} />;
};

MenuDivider.displayName = 'MenuDivider';

const Menu = React.forwardRef((props: IProps, ref: any) => {
    const internalRef = useRef(undefined);
    const controllableRef = ref || internalRef;
    const { className, children, onChange, visible, activeValue } = props;

    const items = getChildrenByType(children, ['MenuItem', 'MenuDivider', 'MenuItemWithTooltip']);

    const childrenCount = items.length;
    const [activeIndex, setActiveIndex] = useState(-1);

    useEffect(
        function syncActiveIndex() {
            for (let i = 0; i < items.length; i++) {
                const child = items[i] as any;
                const value = child.props.value !== undefined ? child.props.value : i;
                const active = child.props.active !== undefined ? child.props.active : value === activeValue;
                if (active) {
                    setActiveIndex(i);
                    break;
                }
            }
        },
        [children]
    );

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'ArrowDown') {
                event.preventDefault();
                setActiveIndex(activeIndex < childrenCount - 1 ? activeIndex + 1 : 0);
            } else if (event.code === 'ArrowUp') {
                event.preventDefault();
                setActiveIndex(activeIndex > 0 ? activeIndex - 1 : childrenCount - 1);
            } else if (event.code == 'Enter') {
                event.preventDefault();
                if (activeIndex == -1) {
                    return;
                }
                const activeChild = items[activeIndex] as any;
                if (activeChild.props.disabled) {
                    return;
                }
                activeChild.props.onClick(undefined);
                onChange?.(activeChild.props.value);
            }
        };

        if (visible && controllableRef.current) {
            controllableRef.current
                .querySelector('.menu-item--active')
                ?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }

        if (!visible && activeIndex !== -1) {
            // reset on close
            setActiveIndex(-1);
        }

        if (visible) window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [visible, activeIndex, items]);

    return (
        <nav
            ref={controllableRef}
            className={classnames('menu', visible ? 'menu--visible' : 'menu--collapsed', className)}
        >
            {items.map((child: any, i) => {
                const value = child.props.value !== undefined ? child.props.value : i;
                const _onClick = child.props.onClick;
                // Decorate onclick handler to trigger listener
                const handleClick = () => {
                    onChange?.(value);
                    _onClick?.(value);
                };

                return (
                    <child.type
                        {...child.props}
                        key={i}
                        value={value}
                        active={activeIndex == i}
                        onClick={handleClick}
                    />
                );
            })}
        </nav>
    );
});

Menu.displayName = 'Menu';

export default Menu;
