import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import { url } from '../../../core/utils/link';
import { ORDER_BY_FILTER_KEY, useTaskFilters } from '../../../core/utils/filterQuery';
import { AUTO_UPDATE_INTERVAL } from '../../../../constants';
import { canUseSupportMode } from '../../../users/utils';
import { useCurrentChannel } from '../../../core/utils/hooks/useCurrentChannel';
import { capitalizeFirstLetter } from '../../../utils/string';
import { PROCESSING_STATUS_COLOR_CLASS } from '../../../assistance/constants';
import { GET_NAVIGATION_CHANNELS } from '../../../core_updated/components/Navigation/internal/queries';
import RecordTable, {
    RecordTableColumn,
    ROW_CONTROLS_KEY,
    RowControlIconButton,
    RowControlIconButtonProps,
} from '../../../assistance/containers/RecordTable';
import Pagination from '../../../core_updated/components/Pagination';
import DropdownMenu from '../../../core_updated/components/DropdownMenu';
import { withIcon } from '../../../core_updated/components/Icon';
import {
    faArchive,
    faCheckCircle,
    faEllipsisVertical,
    faExternalLink,
    faLock,
    faSpinnerThird,
    faTimesCircle,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import Page from '../../../core_updated/components/Page';
import classnames from '../../../core_updated/utils/classnames';
import { useToaster } from '../../../core/components/Toast';
import { formatCustomer, formatSender, formatSubject, formatTime } from '../../../assistance/containers/Overview';
import { useSteprunInfo } from '../../../core/utils/steprunConfig';
import Tooltip from '../../../core/components/Tooltip';
import { camelCase } from 'lodash';
import {
    FINISHED_STATUS_FILTER_CHOICES_WITH_DELETED,
    OPEN_STATUS_FILTER_CHOICES,
    StatusIndicator,
    TESTING_STATUS_FILTER_CHOICES,
} from './internal/StatusFilter';
import UploadFileModal from './internal/UploadFileModal';
import { AllChannelsHeader, SingleChannelHeader } from './internal/Header';
import FilterBar from './internal/FilterBar';
import { OverviewContextProvider } from './internal/OverviewContext';
import AddChannelModal from './internal/AddChannelModal';
import EmptyState from './internal/EmptyState';
import { useApplicationContext } from '../../../core_updated/contexts/ApplicationContext';
import AutomationRateModal from './internal/AutomationRateModal';
import { appInsights } from '../../../core/analytics/applicationInsights';
import { deleteRecordEvent, retryDocumentEvent, reUploadDocumentEvent } from '../../../core/analytics/customEvents';
import SelectField from '../../../core_updated/components/Fields/SelectField';
import Button from '../../../core_updated/components/Button';
import DiscardModal from '../../../assistance/containers/DiscardModal';

const VALUES_MATCH_TRANSLATION_KEY = 'values_match';
const MAX_ITEMS = 500;

const MoreIcon = withIcon(faEllipsisVertical);
const ExternalLinkIcon = withIcon(faExternalLink);
const LoadingIcon = withIcon(faSpinnerThird);
const LockedIcon = withIcon(faLock);
const MatchIcon = withIcon(faCheckCircle);
const NoMatchIcon = withIcon(faTimesCircle);
const ArchiveIcon = withIcon(faArchive);
const DeleteIcon = withIcon(faTrash);

const isRecordLockedByUser = (record, user) => record.lockedBy && record.lockedBy?.email !== user?.email;

const MoreButton = ({
    record,
    showDebug,
    onRetryStep,
    onDebugStep,
    onDelete,
    ...props
}: RowControlIconButtonProps & {
    record: any;
    showDebug: boolean;
    onRetryStep: any;
    onDebugStep: any;
    onDelete: any;
}) => {
    const { t } = useTranslation('assistance');
    const { user } = useApplicationContext();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const { steprunConfig, executionStatus } = useSteprunInfo(record, record?.stepRun);

    const isLocked = isRecordLockedByUser(record, user);
    const isFailed = ['FAILED'].includes(executionStatus);
    const canRetry = steprunConfig?.canRetry ?? true;
    const canMarkAsCompleted = steprunConfig?.canMarkAsCompleted ?? false;

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <RowControlIconButton {...props}>
                        <MoreIcon />
                    </RowControlIconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content align="end">
                    {isFailed && onRetryStep && canRetry && (
                        <DropdownMenu.Item onClick={onRetryStep}>
                            {t('overview.recordRow.actions.retry')}
                        </DropdownMenu.Item>
                    )}
                    {isFailed && onRetryStep && canMarkAsCompleted && (
                        // Note: This only works for the pass-through import step right now
                        <DropdownMenu.Item onClick={onRetryStep}>
                            {t('overview.recordRow.actions.markAsCompleted')}
                        </DropdownMenu.Item>
                    )}
                    {onRetryStep && canRetry && showDebug && (
                        <DropdownMenu.Item onClick={onDebugStep}>
                            {t('overview.recordRow.actions.debug')}
                        </DropdownMenu.Item>
                    )}
                    <DropdownMenu.Item
                        className="text-error hover:text-error hover:!bg-error"
                        onClick={() => setDeleteModalVisible(true)}
                        disabled={isLocked}
                    >
                        {t('overview.recordRow.actions.delete')}
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu>
            <ConfirmModal
                onConfirm={() => {
                    setDeleteModalVisible(false);
                    return onDelete?.();
                }}
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                danger
            >
                <p>{t('overview.recordRow.deleteModalText')}</p>
            </ConfirmModal>
        </>
    );
};

const StatusBadge = ({ status, className }: any) => {
    const { t } = useTranslation('assistance');
    const statusColorGroup = PROCESSING_STATUS_COLOR_CLASS[status];

    return (
        <span
            className={classnames(
                'inline-flex rounded text-xs font-medium items-baseline px-2 py-1 gap-2 bg-secondary text-primary border border-solid border-secondary',
                statusColorGroup == 'success' && 'bg-success text-success border-success',
                statusColorGroup == 'warning' && 'bg-warning text-warning border-warning',
                statusColorGroup == 'error' && 'bg-error text-error border-error',
                className
            )}
        >
            <StatusIndicator status={status} />
            {t(`overview.filters.status.options.${status}`)}
        </span>
    );
};

const getArchivedInfo = (record) => {
    if (record.deletedAt) {
        return {
            archivedAt: record.deletedAt,
            archivedBy: record.deletedBy
                ? {
                      email: record.deletedBy.email,
                      name: `${record.deletedBy.firstName} ${record.deletedBy.lastName}`.trim(),
                  }
                : null,
        };
    } else if (record.discardRecord) {
        return {
            archivedAt: record.discardRecord.createdAt,
            archivedBy: record.discardRecord.user
                ? {
                      email: record.discardRecord.user.email,
                      name: `${record.discardRecord.user.firstName} ${record.discardRecord.user.lastName}`.trim(),
                  }
                : null,
        };
    } else if (record.finishedAt) {
        return {
            archivedAt: record.finishedAt,
            archivedBy: record.assistedBy
                ? {
                      email: record.assistedBy.email,
                      name: `${record.assistedBy.firstName} ${record.assistedBy.lastName}`.trim(),
                  }
                : null,
        };
    }
};

const UserCell = ({ user }) => {
    if (!user?.name) {
        return user.email;
    }

    return (
        <span className="inline-flex flex-col gap-1 text-primary">
            <span>{user.name}</span>
            <span className="text-xs text-tertiary">{user.email}</span>
        </span>
    );
};

const BatchOperationFloatingMenu = ({
    selectedRowKeys,
    setSelectedRowKeys,
    documentConfiguration,
    urlSearchParams,
    tableRefetch,
    className,
    ...props
}: React.ComponentPropsWithoutRef<'div'> & {
    selectedRowKeys: string[];
    setSelectedRowKeys: any;
    documentConfiguration: any;
    urlSearchParams: any;
    tableRefetch: any;
}) => {
    const { t } = useTranslation('assistance');

    const { publishToast } = useToaster();

    const [batchAction] = useMutation(documentConfiguration.BATCH_ACTION);

    const [isDiscardModalVisible, setDiscardModalVisible] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    const handleOpen = () => {
        selectedRowKeys.forEach((recordId) => {
            window.open(
                url(documentConfiguration.constants.ASSISTANCE_PATH, { recordId }, { search: urlSearchParams }),
                '_blank'
            );
        });

        setSelectedRowKeys([]);
    };

    const handleDiscard = async (reason: string) => {
        let response: any;
        try {
            response = await batchAction({
                variables: {
                    recordIds: selectedRowKeys,
                    action: 'DISCARD',
                    payload: { reason },
                },
            });
        } catch (error) {}

        await tableRefetch();

        if (response?.data?.[`${documentConfiguration.documentTypeName}BatchAction`]?.success) {
            publishToast({ description: t('overview.batchActions.discardSuccess', { count: selectedRowKeys.length }) });
            setSelectedRowKeys([]);
        } else {
            publishToast({ description: t('overview.batchActions.discardError') });
        }

        setDiscardModalVisible(false);
    };

    const handleDelete = async () => {
        let response: any;
        try {
            response = await batchAction({
                variables: {
                    recordIds: selectedRowKeys,
                    action: 'DELETE',
                },
            });
        } catch (error) {}

        await tableRefetch();

        if (response?.data?.[`${documentConfiguration.documentTypeName}BatchAction`]?.success) {
            publishToast({ description: t('overview.batchActions.deleteSuccess', { count: selectedRowKeys.length }) });
            setSelectedRowKeys([]);
        } else {
            publishToast({ description: t('overview.batchActions.deleteError') });
        }

        setDeleteModalVisible(false);
    };

    return (
        <>
            <div
                className={classnames(
                    'sticky bottom-0 w-full flex justify-center left-0 pointer-events-none',
                    className
                )}
                {...props}
            >
                <div className="flex items-center pl-6 pr-3 gap-2 py-2 h-14 shadow-xl rounded-xl bg-primary text-primary flex-none pointer-events-auto">
                    <div className="text-sm mr-2 flex-none">
                        <strong className="font-medium">{selectedRowKeys.length}</strong>{' '}
                        {t('overview.batchActions.documentsSelected', { count: selectedRowKeys.length })}:
                    </div>

                    <Button variant="ghost" className="flex gap-2 items-center flex-none" onClick={handleOpen}>
                        <span>
                            <ExternalLinkIcon />
                        </span>
                        <span>{t('overview.batchActions.openButton')}</span>
                    </Button>

                    <div className="w-px h-6 bg-tertiary mx-2 flex-none" />

                    <Button
                        variant="ghost"
                        className="flex gap-2 items-center flex-none"
                        onClick={() => setDiscardModalVisible(true)}
                    >
                        <span>
                            <ArchiveIcon />
                        </span>
                        <span>{t('overview.batchActions.discardButton')}</span>
                    </Button>

                    <Button
                        variant="ghost"
                        className="flex gap-2 items-center hover:bg-error text-error active:bg-error active:text-error focus:bg-error focus:text-error flex-none"
                        onClick={() => setDeleteModalVisible(true)}
                    >
                        <span>
                            <DeleteIcon />
                        </span>
                        <span>{t('overview.batchActions.deleteButton')}</span>
                    </Button>
                </div>
            </div>

            <DiscardModal
                visible={isDiscardModalVisible}
                onConfirm={handleDiscard}
                onCancel={() => {
                    setDiscardModalVisible(false);
                }}
            />

            <ConfirmModal
                title={t('deleteModal.title')}
                labelConfirm={t('deleteModal.confirmButton')}
                labelCancel={t('deleteModal.cancelButton')}
                onConfirm={handleDelete}
                onCancel={() => setDeleteModalVisible(false)}
                visible={isDeleteModalVisible}
                danger
            >
                <p>{t('deleteModal.message')}</p>
            </ConfirmModal>
        </>
    );
};

const DocumentOverview = ({ documentConfiguration, props }) => {
    const documentName = capitalizeFirstLetter(documentConfiguration.documentTypeName);

    const { user, finished, showTestingDocuments, onDarkMode, isDarkMode, onLogout } = props;
    const { t } = useTranslation('assistance');

    const navigate = useNavigate();
    const { channelId } = useCurrentChannel();

    const [uploadFileModalVisible, setUploadFileModalVisible] = useState(false);
    const [addChannelVisible, setAddChannelVisible] = useState(false);
    const [activePage, setActivePage] = useState(0);
    const [duplicateChannelVisible, setDuplicateChannelVisible] = useState(false);
    const [automationRateModalVisible, setAutomationRateModalVisible] = useState(false);

    // By default do not show testing documents in the overview page
    const testing = showTestingDocuments || false;
    const [{ queryFilters, userFilters }, { setUserFilters }] = useTaskFilters({
        user,
        channelId,
        finished,
        testing,
    });

    // remove status filter if invalid for selected tab
    const statusFilterChoices =
        !testing && !finished
            ? OPEN_STATUS_FILTER_CHOICES
            : !testing
              ? FINISHED_STATUS_FILTER_CHOICES_WITH_DELETED
              : TESTING_STATUS_FILTER_CHOICES;

    const isStatusFilterValid = userFilters.find((f) => f.name === 'status')?.value in statusFilterChoices;

    const [uploadFile] = useMutation(documentConfiguration.UPLOAD_FILE);
    const [reUploadFile] = useMutation(documentConfiguration.RE_UPLOAD_FILE);
    const [deleteRecord] = useMutation(documentConfiguration.DELETE_RECORD);
    const [createChannel] = useMutation(documentConfiguration.CREATE_CHANNEL);
    const [retryStep] = useMutation(documentConfiguration.RETRY_STEP);

    const handleUploadDone = (isTestingDocument) => {
        if (isTestingDocument) {
            navigate(
                channelId
                    ? url(documentConfiguration.constants.CHANNEL_TESTING_PATH, { channelId })
                    : url(documentConfiguration.constants.OVERVIEW_TESTING_PATH)
            );
        } else {
            navigate(
                channelId
                    ? url(documentConfiguration.constants.CHANNEL_PATH, { channelId })
                    : url(documentConfiguration.constants.OVERVIEW_PATH)
            );
        }
        setUserFilters([]);
        void tableRefetch();
        setUploadFileModalVisible(false);
    };

    const handleUploadCancel = () => {
        setUploadFileModalVisible(false);
    };

    const handleDeleteRecord = (record) => {
        appInsights?.trackEvent(
            ...deleteRecordEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return deleteRecord({
            variables: {
                recordId: record.id,
            },
        }).then((res) => {
            void tableRefetch();
        });
    };

    const handleRetryStep = (record) => {
        appInsights?.trackEvent(
            ...retryDocumentEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return retryStep({
            variables: {
                recordId: record.id,
                stepRunId: record.stepRun.id,
            },
            fetchPolicy: 'no-cache',
        }).then((res) => {
            void tableRefetch();
        });
    };

    const handleReUploadFile = (record) => {
        appInsights?.trackEvent(
            ...reUploadDocumentEvent(user, record, record?.[documentConfiguration.documentTypeName + 'Unsaved'])
        );
        return reUploadFile({
            variables: {
                files: [],
                recordId: record?.id,
                channelId: record?.channel?.id,
                isTestingDocument: true,
            },
            fetchPolicy: 'no-cache',
        }).then((res) => {
            void tableRefetch();
            navigate(
                channelId
                    ? url(documentConfiguration.constants.CHANNEL_TESTING_PATH, { channelId })
                    : url(documentConfiguration.constants.OVERVIEW_TESTING_PATH)
            );
        });
    };

    const handleCreateChannel = ({ name, fromExistingChannelId }) => {
        return createChannel({
            variables: {
                name,
                fromExistingChannelId,
            },
        }).then((res) => {
            const channelId = res?.data?.['create' + documentName + 'ProcessingChannel']?.channel?.id;
            navigate(url(documentConfiguration.constants.CHANNEL_CONFIG_PATH, { channelId }));
        });
    };

    const { publishToast } = useToaster();

    const handleWriteClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => publishToast({ description: t('overview.header.actions.copyEmailAddressSuccess') }))
            .catch(() => publishToast({ description: t('overview.header.actions.copyEmailAddressError') }));
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const itemsPerPage = parseInt(
        searchParams.get('pageSize') || documentConfiguration.constants?.ITEMS_PER_PAGE || 10
    );

    const orderBy = queryFilters.find((f) => f.name === ORDER_BY_FILTER_KEY)?.value;
    const {
        data,
        previousData,
        error,
        loading,
        refetch: tableRefetch,
        client,
    } = useQuery(documentConfiguration.GET_TABLE_OVERVIEW_DATA, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false,
        pollInterval: AUTO_UPDATE_INTERVAL,
        variables: {
            filters: isStatusFilterValid ? queryFilters : queryFilters.filter((f) => f.name !== 'status'),
            orderBy: Array.isArray(orderBy) ? orderBy[0] : orderBy,
            offset: activePage * itemsPerPage,
            first: itemsPerPage,
            channelId: channelId,
        },
    });

    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

    useEffect(() => {
        const currentDocCount = data?.['filtered' + documentName + 'ProcessingRecordsList']?.totalCount;
        const previousDocCount = previousData?.['filtered' + documentName + 'ProcessingRecordsList']?.totalCount;
        if (currentDocCount !== undefined && previousDocCount !== undefined && currentDocCount !== previousDocCount) {
            client.refetchQueries({ include: [GET_NAVIGATION_CHANNELS] });
        }
        const newRecordsIds = data?.['filtered' + documentName + 'ProcessingRecordsList']?.records?.map((r) => r.id);
        setSelectedRowKeys((prev) => prev.filter((id) => newRecordsIds.includes(id)));
    }, [data]);

    const { data: channelsData } = useQuery(documentConfiguration.GET_CHANNELS_OVERVIEW_DATA, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: false,
    });
    const channels = channelsData?.[camelCase(documentName) + 'ProcessingChannels'] || [];
    const activeChannel = channels?.find((channel) => channel.id === channelId);

    const currentData = loading ? previousData : data;
    const totalCount = currentData?.['filtered' + documentName + 'ProcessingRecordsList']?.totalCount;
    const records =
        currentData?.['filtered' + documentName + 'ProcessingRecordsList']?.records?.map((item) => ({
            key: item.id,
            disabled: !!(isRecordLockedByUser(item, user) || item.status === 'PENDING'),
            ...item,
        })) || [];

    const urlSearchParams = {};
    if (channelId) urlSearchParams['channel'] = channelId;

    let columns: RecordTableColumn[] = [];

    columns.push({
        key: 'document',
        label: t('overview.columns.document'),
        render: (record) => {
            const isLocked = isRecordLockedByUser(record, user);
            const isLoading = ['PENDING', 'SCHEDULED', 'RUNNING', 'ASSISTED'].includes(record.status);

            const externalReference = record?.deliveryRecord?.externalReference;
            const externalMessage = record?.deliveryRecord?.externalMessage;
            const externalStatus = record?.deliveryRecord?.externalStatus;

            const externalParts = [
                externalReference && `${t('overview.recordRow.externalReference')}: ${externalReference}`,
                externalStatus && `${t('overview.recordRow.externalStatus')}: ${externalStatus}`,
                externalMessage && `${t('overview.recordRow.externalMessage')}: ${externalMessage}`,
            ].filter((part) => !!part);

            return (
                <Link
                    className="inline-flex gap-3 items-center w-full"
                    to={url(
                        documentConfiguration.constants.ASSISTANCE_PATH,
                        { recordId: record.id },
                        { search: urlSearchParams }
                    )}
                >
                    {isLoading && <LoadingIcon spin className="text-brand" />}
                    {!isLoading && isLocked && <LockedIcon className="text-secondary" />}
                    <span className="inline-flex flex-col gap-1 text-primary">
                        <span>{formatSubject(record)}</span>

                        {externalParts.length > 0 && (
                            <span className="text-xs text-tertiary">{externalParts.join('  -  ')}</span>
                        )}
                    </span>
                </Link>
            );
        },
        className: 'min-w-96',
    });

    columns.push({
        key: 'status',
        label: t('overview.columns.status'),
        render: (record) => (
            <StatusBadge
                status={(() => {
                    if (record?.deletedAt) {
                        return FINISHED_STATUS_FILTER_CHOICES_WITH_DELETED.DELETED;
                    } else if (testing && record?.isTestDocument && record?.canProcessAutomatically) {
                        // testing documents always go to assistance, however,
                        // those testing docs that would go through automatically should be labelled as such
                        return TESTING_STATUS_FILTER_CHOICES.FINISHED_TESTING;
                    } else {
                        return record.status;
                    }
                })()}
            />
        ),
        className: 'min-w-60 w-60',
    });

    if (documentConfiguration?.documentMatchingValidationKey) {
        columns.push({
            key: 'matching',
            label: t(`overview.columns.${camelCase(documentConfiguration?.documentMatchingValidationKey)}`),
            render: (record) => {
                const isMatchingEnabled =
                    documentConfiguration?.documentMatchingValidationKey &&
                    record?.channel?.extractorConfig?.validationChecks?.includes(
                        documentConfiguration?.documentMatchingValidationKey
                    );

                // Overview matching label should only be visible when matching feature is enabled
                // and the document is not finished
                if (!isMatchingEnabled)
                    return <span className="text-secondary">{t('overview.recordRow.matchingStatus.notEnabled')}</span>;
                if (finished) return null;

                const document = record?.[documentConfiguration.documentTypeName + 'Unsaved'];
                const documentNumberField = document?.[documentConfiguration.documentMatchingFieldName];
                const explanation = documentNumberField?.confidenceExplanation;
                const isMatching =
                    explanation?.explanationDetails?.document_matching_result === VALUES_MATCH_TRANSLATION_KEY;
                const documentNumber = documentNumberField?.value || '';
                let updatedAt = explanation?.explanationDetails?.['time_stamp'] || record?.createdAt || '';
                if (updatedAt) updatedAt = moment(updatedAt).format('lll');

                return (
                    <Tooltip
                        content={t(`overview.recordRow.matchingStatus.${isMatching ? 'match' : 'noMatch'}`, {
                            documentNumber,
                            updatedAt,
                        })}
                        placement="bottom"
                    >
                        <span className="inline-flex gap-2 items-center font-medium">
                            {isMatching ? (
                                <MatchIcon className="text-confidence-high" />
                            ) : (
                                <NoMatchIcon className="text-confidence-low" />
                            )}

                            {t(`overview.recordRow.matchingStatusShort.${isMatching ? 'match' : 'noMatch'}`)}
                        </span>
                    </Tooltip>
                );
            },
            className: 'min-w-60 w-60',
        });
    }

    const clientFieldNamesByDocumentType = {
        order: 'client',
        rfq: 'client',
        listOfServices: 'client',
        orderConfirmation: 'supplier',
        invoice: 'supplier',
        deliveryNote: 'supplier',
        propertyBill: 'creditor',
    };

    const clientFieldName = clientFieldNamesByDocumentType[documentConfiguration.documentTypeName] as string;
    if (clientFieldName) {
        columns.push({
            key: clientFieldName,
            label: t(`overview.columns.${clientFieldName}`),
            render: (record) => {
                if (formatCustomer(record.sender) == null) {
                    return <div className="text-tertiary">({t('overview.columns.notFound')})</div>;
                }
                const [name, number] = formatCustomer(record.sender);
                return (
                    <div className="flex flex-col space-y-1">
                        <div>{name}</div>
                        <div className="text-xs text-tertiary">{number || null}</div>
                    </div>
                );
            },
            className: 'min-w-60 w-60',
        });
    }

    if (!channelId) {
        // Add channel column to all channels overview table
        columns.push({
            key: 'channel',
            label: t('overview.columns.channel'),
            render: (record) => record.channel.name,
            className: 'min-w-60 w-60',
        });
    }

    columns.push({
        key: 'createdBy',
        label: t('overview.columns.createdBy'),
        render: (record) =>
            record.createdBy ? (
                <div className="flex flex-col space-y-1">
                    <div>
                        {record.createdBy.firstName || ''} {record.createdBy.lastName || ''}
                    </div>
                    {record.createdBy.email && <div className="text-xs text-tertiary">{record.createdBy.email}</div>}
                </div>
            ) : (
                <div className="flex flex-col space-y-1">
                    <div>{formatSender(record)[0]}</div>
                    <div className="text-xs text-tertiary"> {formatSender(record)[1] || null}</div>
                </div>
            ),
        className: 'min-w-60 w-60',
    });

    columns.push({
        key: 'createdAt',
        label: t('overview.columns.createdAt'),
        render: (record) => formatTime(record.createdAt),
        className: 'min-w-60 w-60',
    });

    if (finished) {
        columns.push({
            key: 'archivedBy',
            label: t('overview.columns.archivedBy'),
            render: (record) => {
                const archivedInfo = getArchivedInfo(record);
                if (!archivedInfo) return '';

                if (!archivedInfo.archivedBy) {
                    return t('overview.archivedByAutomated');
                }

                return <UserCell user={archivedInfo.archivedBy} />;
            },
            className: 'min-w-60 w-60',
        });

        columns.push({
            key: 'archivedAt',
            label: t('overview.columns.archivedAt'),
            render: (record) => (getArchivedInfo(record) ? formatTime(getArchivedInfo(record).archivedAt) : ''),
            className: 'min-w-60 w-60',
        });
    }

    columns.push({
        key: ROW_CONTROLS_KEY,
        label: '',
        render: (record) => {
            return (
                <div className="flex gap-2">
                    <Tooltip content={t('overview.recordRow.openInNewTab')} placement="bottom">
                        <RowControlIconButton asChild>
                            <Link
                                to={url(
                                    documentConfiguration.constants.ASSISTANCE_PATH,
                                    { recordId: record.id },
                                    { search: urlSearchParams }
                                )}
                                target="_blank"
                            >
                                <ExternalLinkIcon />
                            </Link>
                        </RowControlIconButton>
                    </Tooltip>
                    <MoreButton
                        record={record}
                        showDebug={canUseSupportMode(user)}
                        onRetryStep={() => handleRetryStep(record)}
                        onDebugStep={() => handleReUploadFile(record)}
                        onDelete={() => handleDeleteRecord(record)}
                    />
                </div>
            );
        },
        className: 'min-w-28 w-28',
    });

    return (
        <OverviewContextProvider
            finished={finished}
            testing={testing}
            channelId={channelId}
            documentConfiguration={documentConfiguration}
        >
            <Page>
                <AddChannelModal
                    visible={addChannelVisible}
                    onConfirm={handleCreateChannel}
                    onCancel={() => setAddChannelVisible(false)}
                />

                <AddChannelModal
                    visible={duplicateChannelVisible}
                    onConfirm={(formData) =>
                        handleCreateChannel({ name: formData.name, fromExistingChannelId: channelId })
                    }
                    onCancel={() => setDuplicateChannelVisible(false)}
                    title={t('overview.header.actions.duplicateChannel')}
                    initialValue={t('overview.header.actions.duplicateChannelNamePrefix') + activeChannel?.name}
                />

                <UploadFileModal
                    visible={uploadFileModalVisible}
                    activeChannel={activeChannel}
                    uploadFile={uploadFile}
                    onUploadDone={handleUploadDone}
                    channels={channels}
                    onCancel={handleUploadCancel}
                />

                <AutomationRateModal
                    visible={automationRateModalVisible}
                    onClose={() => {
                        setAutomationRateModalVisible(false);
                    }}
                    channelName={activeChannel?.name}
                    channelId={channelId}
                    documentName={documentName}
                    documentConfiguration={documentConfiguration}
                />

                {channelId ? (
                    <SingleChannelHeader
                        activeChannel={activeChannel}
                        onUploadFileModalVisibleChange={setUploadFileModalVisible}
                        onDuplicateChannelVisibleChange={setDuplicateChannelVisible}
                        onAutomationRateModalVisibleChange={setAutomationRateModalVisible}
                        onCopyEmailAddress={handleWriteClipboard}
                        documentConfiguration={documentConfiguration}
                    />
                ) : (
                    <AllChannelsHeader
                        user={user}
                        onAddChannelVisibleChange={setAddChannelVisible}
                        onAutomationRateModalVisibleChange={setAutomationRateModalVisible}
                    />
                )}

                <FilterBar
                    filters={isStatusFilterValid ? userFilters : userFilters.filter((f) => f.name !== 'status')}
                    onFiltersChange={setUserFilters}
                    renderExtraFilters={documentConfiguration.renderExtraFilters}
                    activeChannel={activeChannel}
                    channels={channels}
                    finished={finished}
                />

                <Page.Content lowered className="flex flex-col gap-6 relative">
                    {!loading && totalCount === 0 ? (
                        <EmptyState filters={userFilters} onFiltersChange={setUserFilters} />
                    ) : (
                        <>
                            {!loading && (activePage + 1) * itemsPerPage > MAX_ITEMS ? (
                                <div className="flex flex-col gap-4 items-center justify-center h-full">
                                    <div className="font-medium text-primary text-center text-base">
                                        {t('overview.tooManyDocuments.title')}
                                    </div>

                                    <div className="text-secondary max-w-[32rem] text-center text-base text-pretty">
                                        {t('overview.tooManyDocuments.description', { max_items: MAX_ITEMS })}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={classnames(
                                        'flex flex-col gap-6 transition-all',
                                        loading && 'animate-pulse opacity-50'
                                    )}
                                >
                                    <RecordTable
                                        columns={columns}
                                        records={records}
                                        loading={loading}
                                        selectable={!!documentConfiguration.BATCH_ACTION}
                                        selectedRowKeys={selectedRowKeys}
                                        onSelectedRowKeysChange={setSelectedRowKeys}
                                    />
                                </div>
                            )}

                            {totalCount > 0 && (
                                <div className="flex justify-between">
                                    <span className="text-sm text-secondary">
                                        {Math.min(totalCount, MAX_ITEMS, activePage * itemsPerPage + 1)} -{' '}
                                        {Math.min(totalCount, MAX_ITEMS, (activePage + 1) * itemsPerPage)} of{' '}
                                        {totalCount}
                                    </span>

                                    <Pagination
                                        numItems={totalCount > MAX_ITEMS ? MAX_ITEMS + itemsPerPage : totalCount}
                                        itemsPerPage={itemsPerPage}
                                        activePage={activePage}
                                        onChange={setActivePage}
                                    />

                                    <SelectField
                                        required
                                        className="w-36"
                                        name="pageSize"
                                        value={itemsPerPage.toString()}
                                        options={[
                                            { label: '10', value: '10' },
                                            { label: '20', value: '20' },
                                            { label: '50', value: '50' },
                                            { label: '100', value: '100' },
                                        ]}
                                        renderValue={(value) => t('core:components.pageSizeSelect.option', { value })}
                                        onValueChange={(value) => {
                                            setActivePage(0);
                                            setSearchParams((params) => {
                                                params.set('pageSize', value);
                                                return params;
                                            });
                                        }}
                                    />
                                </div>
                            )}

                            {selectedRowKeys.length > 0 && (
                                <BatchOperationFloatingMenu
                                    selectedRowKeys={selectedRowKeys}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    documentConfiguration={documentConfiguration}
                                    urlSearchParams={urlSearchParams}
                                    tableRefetch={tableRefetch}
                                />
                            )}
                        </>
                    )}
                </Page.Content>
            </Page>
        </OverviewContextProvider>
    );
};

export default DocumentOverview;
