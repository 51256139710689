import { gql } from '@apollo/client';
import { FULL_CHANNEL } from './pages/Configuration/queries';
import { BASE_DOCUMENT, BASE_FIELD, PRIMITIVE_FIELD, SELECT_FIELD_FRAGMENT } from '../assistance/queries';

export const FULL_LIST_OF_SERVICES = gql`

    ${SELECT_FIELD_FRAGMENT}

    fragment FullListOfServices on ListOfServices {
        chapters {
            number
            numberTotal
            numberRelevant
            numberCompleted
            header
        }
        numberOfPositions
        numberOfRelevantPositions
        numberOfPretexts
        ${BASE_DOCUMENT}
        
        customer {
            name
            customerNumber
            customerType
            misc
            contactPerson
            ${BASE_FIELD}
    
            address {
                addressId
                name
                postcode
                city
                country
                phone
                email
                companyName
                streetAndNr
                misc
                bbox
                pageIndex
                predictionConfidence
            }
        }
        
        submissionDate {
            ${PRIMITIVE_FIELD}
        }

        constructionSite {
            ${PRIMITIVE_FIELD}
        }
        
        projectNumber {
            ${PRIMITIVE_FIELD}
        }

        projectName {
            ${PRIMITIVE_FIELD}
        }

        objectNumber {
            ${PRIMITIVE_FIELD}
        }
        
        dynamicSchemaSerialized

        
        positions {
            id
            positionIndex: index
            chapter
            number {
                ${PRIMITIVE_FIELD}
            }
            numberRaw {
                ${PRIMITIVE_FIELD}
            }
            text {
                ${PRIMITIVE_FIELD}
            }
            isRelevant {
                ${PRIMITIVE_FIELD}
            }
            rejectedBy {
                ${PRIMITIVE_FIELD}
            }
            prediction
            parent
            header
            articleSets {
                id
                isComplete
                articles {
                    number {
                        ${PRIMITIVE_FIELD}
                    }
                    articleNumber2 {
                        ${PRIMITIVE_FIELD}
                    }
                    description {
                        ${PRIMITIVE_FIELD}
                    }
                    unit {
                        ...FullSelectField
                    }
                    quantity {
                        ${PRIMITIVE_FIELD}
                    }
                    suggestions {
                       articleId1
                       articleId2
                       description
                       orderUnits
                       quantity
                       unit
                    }
                    dynamicSchemaSerialized
                }
            }
            ${BASE_FIELD}
        }
    }
`;

const FULL_RECORD = gql`
    ${FULL_LIST_OF_SERVICES}
    ${FULL_CHANNEL}
    fragment FullRecord on ListOfServicesProcessingRecord {
        id
        createdAt
        deletedAt
        deletedBy {
            email
            firstName
            lastName
        }

        status

        sender
        subject

        canFinishAssistance
        isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)

        lockedBy {
            email
        }

        putOnHoldReason
        putOnHoldBy {
            email
            firstName
            lastName
        }

        assistedBy {
            email
            firstName
            lastName
        }

        assistedAt

        stepRun {
            id
            stepName
            manualOnly
            executionStatus
            error
            context
        }

        listofservicesprocessingsteprunSet {
            id
            createdAt
            updatedAt
            stepName
            manualOnly
            executionStatus
            context
            error
        }

        channel {
            ...FullChannel
        }

        isDiscarded
        isTestDocument

        discardRecord {
            reason
            user {
                id
                firstName
                lastName
            }
            createdAt
            updatedAt
        }

        sourceFile {
            ... on Email {
                sender {
                    email
                    name
                }
                receivers {
                    email
                    name
                }
                subject
                messageId
                language
                receivedAt

                text
                html
                attachments {
                    cid
                    contentDisposition
                    name
                    type
                    url
                }

                senderClientId
                prediction
                predictionConfidence
                sourceUrl

                predictionConfidences
            }

            ... on File {
                name
                type
                url
            }
        }

        inputFile {
            ... on File {
                name
                type
                url
            }
        }

        outputFile {
            ... on Export {
                files {
                    name
                    url
                }
            }
        }

        listOfServicesUnsaved {
            ...FullListOfServices
        }

        externalReference
        deliveryRecord {
            deliveryMethod
            deliveryStatus
            externalReference
            externalMessage
            externalStatus
        }
        labelingStatus

        masterdataVersionsExtracted
    }
`;

export const GET_OVERVIEW_DATA = gql`
    query {
        listOfServicesProcessingChannels {
            id
            name
        }
    }
`;

export const GET_CHANNELS_OVERVIEW_DATA = gql`
    query {
        listOfServicesProcessingChannels {
            id
            name
            emailAddress
            team {
                id
                users {
                    email
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GET_TABLE_OVERVIEW_DATA = gql`
    query ($filters: [TableFilter], $orderBy: String, $offset: Int, $first: Int) {
        filteredListOfServicesProcessingRecordsList(
            filters: $filters
            orderBy: $orderBy
            offset: $offset
            limit: $first
        ) {
            totalCount
            records {
                id
                sender
                subject
                status
                createdAt
                finishedAt
                assistedAt
                assistedBy {
                    id
                    firstName
                    lastName
                    email
                }
                createdBy {
                    email
                    firstName
                    lastName
                }
                deletedAt
                deletedBy {
                    id
                    firstName
                    lastName
                    email
                }
                lockedBy {
                    email
                }
                stepRun {
                    id
                    stepName
                    manualOnly
                    executionStatus
                    error
                    context
                }
                channel {
                    id
                    name
                }
                isDiscarded
                deliveryRecord {
                    deliveryMethod
                    deliveryStatus
                    externalReference
                    externalMessage
                    externalStatus
                }
                discardRecord {
                    reason
                    user {
                        id
                        firstName
                        lastName
                        email
                    }
                    createdAt
                    updatedAt
                }
                isTestDocument
                canProcessAutomatically
                sourceFile {
                    ... on File {
                        name
                    }
                    ... on Email {
                        sender {
                            name
                            email
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ASSISTANCE_OVERVIEW_DATA = gql`
    query ($recordId: UUID!, $filters: [TableFilter]) {
        listOfServicesProcessingRecordAssistancePagination(recordId: $recordId, filters: $filters) {
            filteredCount
            position
            previousRecordId
            nextRecordId
        }
    }
`;

export const GET_ASSISTANCE_DATA = gql`
    ${FULL_RECORD}
    ${FULL_LIST_OF_SERVICES}
    query ($id: UUID!, $chapter: String) {
        listOfServicesProcessingRecord(id: $id, chapter: $chapter) {
            ...FullRecord

            ocr {
                pagesCount
                processedFile {
                    url
                }
            }
        }
    }
`;

export const GET_LIST_OF_SERVICES_UNSAVED_DATA = gql`
    ${SELECT_FIELD_FRAGMENT}
    query ($id: UUID!, $chapter: String) {
        listOfServicesProcessingRecord(id: $id, chapter: $chapter) {
            listOfServicesUnsaved {
                positions {
                    id
                    positionIndex: index
                    chapter
                    number {
                        ${PRIMITIVE_FIELD}
                    }
                    numberRaw {
                        ${PRIMITIVE_FIELD}
                    }
                    text {
                        ${PRIMITIVE_FIELD}
                    }
                    isRelevant {
                        ${PRIMITIVE_FIELD}
                    }
                    rejectedBy {
                        ${PRIMITIVE_FIELD}
                    }
                    prediction
                    parent
                    header
                    articleSets {
                        id
                        isComplete
                        articles {
                            number {
                                ${PRIMITIVE_FIELD}
                            }
                            articleNumber2 {
                                ${PRIMITIVE_FIELD}
                            }
                            description {
                                ${PRIMITIVE_FIELD}
                            }
                            unit {
                                ...FullSelectField
                            }
                            quantity {
                                ${PRIMITIVE_FIELD}
                            }
                            suggestions {
                            articleId1
                            articleId2
                            description
                            orderUnits
                            quantity
                            unit
                            }
                            dynamicSchemaSerialized
                        }
                    }
                    ${BASE_FIELD}
                }
            }
        }
    }
`;

export const UPDATE_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $itemIndex: Int, $action: String!, $payload: GenericScalar) {
        listOfServicesUpdate(
            recordId: $recordId
            fieldName: $fieldName
            itemIndex: $itemIndex
            action: $action
            payload: $payload
        ) {
            record {
                canFinishAssistance
                isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const RESELECT_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $bbox: [[Float]]!, $pageIndex: Int!, $itemIndex: Int) {
        listOfServicesReselect(
            recordId: $recordId
            fieldName: $fieldName
            bbox: $bbox
            pageIndex: $pageIndex
            itemIndex: $itemIndex
        ) {
            record {
                canFinishAssistance
                isHeaderCorrect: canFinishHeaderAssistance(ignoreThreshold: false)
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const DELETE_LIST_OF_SERVICES = gql`
    mutation ($recordId: UUID!) {
        listOfServicesDelete(recordId: $recordId) {
            success
        }
    }
`;

export const RETRY_STEP = gql`
    mutation ($recordId: UUID!, $stepRunId: UUID!) {
        listOfServicesRetryStep(recordId: $recordId, stepRunId: $stepRunId) {
            success
        }
    }
`;

export const DISCARD_LIST_OF_SERVICES = gql`
    mutation ($recordId: UUID!, $reason: String) {
        listOfServicesDiscard(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const REOPEN_FOR_ASSISTANCE = gql`
    mutation ($recordId: UUID!, $reason: String) {
        listOfServicesReopenForAssistance(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $batchId: String, $isTestingDocument: Boolean!) {
        listOfServicesUploadFile(
            input: { channelId: $channelId, files: $files, batchId: $batchId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const RE_UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $recordId: String!, $isTestingDocument: Boolean!) {
        listOfServicesUploadFile(
            input: { channelId: $channelId, files: $files, recordId: $recordId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const SEND_TO_LABELING = gql`
    mutation ($recordId: UUID!) {
        listOfServicesSendToLabeling(recordId: $recordId) {
            success
        }
    }
`;

export const GET_NEXT_ASSISTANCE_RECORD = gql`
    query ($filters: [TableFilter], $channelId: UUID) {
        nextListOfServicesProcessingRecord(filters: $filters, channelId: $channelId) {
            id
        }
    }
`;

export const CREATE_CHANNEL = gql`
    mutation ($name: String!, $fromExistingChannelId: UUID) {
        createListOfServicesProcessingChannel(name: $name, fromExistingChannelId: $fromExistingChannelId) {
            channel {
                id
            }
            success
        }
    }
`;

export const GET_CUSTOMER_AUTOMATION_RATIO = gql`
    query ($startDate: Date!, $endDate: Date!) {
        customerListOfServicesAutomationRatio(startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_CHANNEL_AUTOMATION_RATIO = gql`
    query ($channelId: UUID!, $startDate: Date!, $endDate: Date!) {
        channelListOfServicesAutomationRatio(channelId: $channelId, startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_ALL_CHANNELS = gql`
    ${FULL_CHANNEL}
    query {
        listOfServicesProcessingChannels {
            ...FullChannel
        }
    }
`;

export const GET_CLIENT_TABLE_FILTER_OPTIONS = gql`
    query ($searchTerm: String) {
        listOfServicesClientTableFilterOptions(searchTerm: $searchTerm) {
            label
            value
            count
        }
    }
`;

export const LIST_OF_SERVICES_CHAPTER_ACTION = gql`
    mutation ($recordId: UUID!, $chapter: String!, $action: String!) {
        listOfServicesChapterAction(recordId: $recordId, chapter: $chapter, action: $action) {
            success
        }
    }
`;
