import React from 'react';
import PageHeader from './Page/internal/PageHeader.tsx';
import PageHeaderTitle from './Page/internal/PageHeaderTitle.tsx';
import classnames from '../utils/classnames.tsx';
import { Slot } from '@radix-ui/react-slot';
import * as userPaths from '../../users/constants.ts';
import * as customerPaths from '../../customer/constants.ts';
import * as teamPaths from '../../teams/constants.ts';
import { useApplicationContext } from '../contexts/ApplicationContext.tsx';
import { Link, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SecondaryNavigationSectionLabel = ({ children, className, ...props }: React.ComponentPropsWithRef<'div'>) => {
    return (
        <div className={classnames('text-sm text-secondary px-3 py-2', className)} {...props}>
            {children}
        </div>
    );
};

const SecondaryNavigationButton = ({
    active = false,
    className,
    asChild,
    ...props
}: React.ComponentPropsWithRef<'button'> & { active?: boolean; asChild?: boolean }) => {
    const Comp = asChild ? Slot : 'button';
    return (
        <Comp
            className={classnames(
                'relative flex w-full items-center gap-2 rounded-md px-3 py-2 text-sm text-primary hover:text-primary h-9 transition-colors',
                'cursor-pointer hover:bg-secondary transition-[padding] ease-in-out duration-200',
                active && '!bg-brand !text-brand',
                className
            )}
            data-active={active}
            {...props}
        />
    );
};

const USER_ITEMS = [
    {
        label: 'components.secondaryNavigation.myProfile',
        path: userPaths.PROFILE_PATH,
    },
    {
        label: 'components.secondaryNavigation.myNotifications',
        path: userPaths.PROFILE_PATH,
    },
];

const COMPANY_ITEMS = [
    {
        label: 'components.secondaryNavigation.companyProfile',
        path: customerPaths.EDIT_PATH,
    },
    {
        label: 'components.secondaryNavigation.companyNotifications',
        path: customerPaths.OVERVIEW_PATH,
    },
    {
        label: 'components.secondaryNavigation.teams',
        path: teamPaths.OVERVIEW_PATH,
    },
    {
        label: 'components.secondaryNavigation.employees',
        path: userPaths.OVERVIEW_PATH,
    },
    {
        label: 'components.secondaryNavigation.singleSignOn',
        path: customerPaths.OVERVIEW_PATH,
    },
    {
        label: 'components.secondaryNavigation.integrations',
        path: customerPaths.INTEGRATIONS_PATH,
    },
    {
        label: 'components.secondaryNavigation.customizations',
        path: customerPaths.OVERVIEW_PATH,
    },
];

const getIsActive = (path: string) => {
    return !!matchPath(path, window.location.pathname);
};

const SecondaryNavigation = ({ className, ...props }: Omit<React.ComponentPropsWithRef<'nav'>, 'children'>) => {
    const { t } = useTranslation('core');
    const { user } = useApplicationContext();

    return (
        <nav
            className={classnames(
                'flex flex-col flex-none w-72 bg-white border-solid border-r border-primary',
                className
            )}
            {...props}
        >
            <PageHeader>
                <PageHeaderTitle>{t('components.secondaryNavigation.title')}</PageHeaderTitle>
            </PageHeader>

            <div className="flex flex-col px-4 p-5 gap-1">
                <SecondaryNavigationSectionLabel>
                    {user?.firstName} {user?.lastName}
                </SecondaryNavigationSectionLabel>

                {USER_ITEMS.map((item) => (
                    <SecondaryNavigationButton key={item.label} active={getIsActive(item.path)} asChild>
                        <Link to={item.path}>{t(item.label)}</Link>
                    </SecondaryNavigationButton>
                ))}

                <SecondaryNavigationSectionLabel className="mt-6">
                    {user?.customer?.name}
                </SecondaryNavigationSectionLabel>

                {COMPANY_ITEMS.map((item) => (
                    <SecondaryNavigationButton key={item.label} active={getIsActive(item.path)} asChild>
                        <Link to={item.path}>{t(item.label)}</Link>
                    </SecondaryNavigationButton>
                ))}
            </div>
        </nav>
    );
};

export default SecondaryNavigation;
