import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../components/DropdownMenu';
import { SecondaryButton } from '../../../core/components/Button';

import '../DocumentConfig/FieldsNav.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const AddIcon = withIcon(faPlus);

export const UniversalFieldsNavHeader = ({ onAddField }) => {
    const { t } = useTranslation('config');

    return (
        <div className="fields-config__header">
            <h2 className="fields-config__title">{t('documentConfig.configuredFields')}</h2>
            <div className="fields-config__actions">
                <DropdownMenu className="fields-dropdown">
                    <DropdownMenu.Trigger>
                        <SecondaryButton
                            label={<AddIcon />}
                            className="button--icon button--small"
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                onAddField();
                            }}
                        />
                    </DropdownMenu.Trigger>
                </DropdownMenu>
            </div>
        </div>
    );
};
