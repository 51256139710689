import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import Form from '../../../core/components/Form';
import { BooleanField, StringField, useForm } from '../../../assistance/containers/Fields';
import Input from '../../../core/components/Input';
import ConfirmModal from '../../../core/containers/ConfirmModal';

import './style.scss';

const SSOConfigModal = (props) => {
    const { config, className, customer, onSubmit, onCancel, ...modalProps } = props;

    useEffect(() => {
        setFormData({
            providerName: config?.providerName || '',
            domain: config?.domain || '',
            allowSelfRegistration: config?.allowSelfRegistration,
            tenantId: config?.tenantId || '',
            clientId: config?.clientId || '',
        });
    }, [config]);

    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`ssoConfigModal.${fieldName}`),
    });

    const { t } = useTranslation('customer');

    return (
        <ConfirmModal
            className={classnames('sso-modal', className)}
            onConfirm={() => onSubmit(formData)}
            onCancel={onCancel}
            labelConfirm={t('ssoConfigModal.submit')}
            labelCancel={t('ssoConfigModal.cancel')}
            title={t('ssoConfigModal.title')}
            noHeader={false}
            {...modalProps}
        >
            <Form className="sso-modal__form">
                <StringField {...getFieldProps('providerName')} className="field--vertical" />
                <StringField {...getFieldProps('domain')} className="field--vertical" />
                <BooleanField {...getFieldProps('allowSelfRegistration')} className="field--vertical" />
                <StringField {...getFieldProps('tenantId')} className="field--vertical" />
                <StringField {...getFieldProps('clientId')} className="field--vertical" />
                <Input
                    id="clientSecret"
                    name="clientSecret"
                    placeholder={t('ssoConfigModal.clientSecretText')}
                    label={t('ssoConfigModal.clientSecret')}
                    readOnly={true}
                    className="field--vertical"
                />
            </Form>
        </ConfirmModal>
    );
};

export default SSOConfigModal;
