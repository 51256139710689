import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAssistanceContext } from './AssistanceContext';
import AssistanceCanvas from '../../../assistance/components/AssistanceCanvas';
import { ImageMap } from '../../../assistance/containers/ImageMap';
import SplitPanes, { Pane } from '../../../core/containers/SplitPanes';
import { useTranslation } from 'react-i18next';
import classnames from '../../../core_updated/utils/classnames';
import { withIcon } from '../../../core_updated/components/Icon';
import { faChevronDown, faChevronUp, faEnvelope, faFile, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import Frame from '../../../core/components/Frame';
import DOMPurify from 'dompurify';
import { EmailAttachment as IEmailAttachment, PURIFY_CONFIG } from '../../../assistance/containers/EmailPreview';
import fontsStyle from '../../../../../stylesheets/fonts.css?inline';
import contentStyle from '../../../assistance/containers/EmailPreview/content.css?inline';
import i18n from 'i18next';
import Button from '../../../core_updated/components/Button';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROCESSING_RECORD_EMAILS, SEND_ASSISTANCE_EMAIL } from '../../../orders/queries';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../../App';
import Loader, { LoaderLine } from '../../../core/components/Loader';
import Tooltip from '../../../core/components/Tooltip';
import EmailEditor, { adjustEmail, DocumentEmail, EmailAvatar } from './components/EmailEditor';

const FileIcon = withIcon(faFile);
const AttachmentIcon = withIcon(faPaperclip);
const EmailIcon = withIcon(faEnvelope);
const ChevronUpIcon = withIcon(faChevronUp);
const ChevronDownIcon = withIcon(faChevronDown);

export const useDocumentEmailBody = (email: DocumentEmail) => {
    const attachmentsWithUrl = (email?.attachments || []).filter((attachment) => attachment?.url);
    const emailContent = DOMPurify.sanitize(email?.body, PURIFY_CONFIG) || '';

    return {
        content: emailContent,
        attachments: attachmentsWithUrl,
    };
};

const MAX_PREVIEW_RECEIVERS = 9;

const EmailHeader = ({ email }: { email: DocumentEmail }) => {
    const { t } = useTranslation('assistance');

    const [isCollapsed, setIsCollapsed] = useState(true);
    const allReceivers = email.receivers.concat(email.cc || []).concat(email.bcc || []);

    const hideButton = (
        <Tooltip content={t('emailsView.email.hideDetails')} placement="top">
            <button
                className="text-sm text-secondary px-0.5 hover:text-primary transition-colors ml-1 translate-y-px"
                onClick={() => setIsCollapsed(true)}
            >
                <ChevronUpIcon />
            </button>
        </Tooltip>
    );

    return (
        <div className="flex gap-6 justify-between items-start">
            <div className="flex flex-col gap-2 w-full">
                <div className="flex gap-1 items-center">
                    <span className="font-medium text-primary">{email.sender.name}</span>
                    <span className="text-secondary text-sm">&lt;{email.sender.email}&gt;</span>

                    <span className="block text-secondary text-sm ml-auto">
                        {new Date(email.createdAt).toLocaleString(i18n.language)}
                    </span>
                </div>
                <div className="text-secondary text-sm flex flex-col gap-0 leading-relaxed">
                    {isCollapsed ? (
                        <span>
                            {t('emailsView.email.to')}:{' '}
                            {allReceivers
                                .slice(0, MAX_PREVIEW_RECEIVERS)
                                .map((r) => r.email.split('@')[0])
                                .join(', ')}
                            {allReceivers.length > MAX_PREVIEW_RECEIVERS &&
                                ` ${t('emailsView.email.moreReceivers', {
                                    count: allReceivers.length - MAX_PREVIEW_RECEIVERS,
                                })}`}
                            <Tooltip content={t('emailsView.email.showDetails')} placement="top">
                                <button
                                    className="text-sm text-secondary px-0.5 hover:text-primary transition-colors ml-1"
                                    onClick={() => setIsCollapsed(false)}
                                >
                                    <ChevronDownIcon />
                                </button>
                            </Tooltip>
                        </span>
                    ) : (
                        <>
                            <span className="flex gap-1">
                                <span className="flex-none w-9">{t('emailsView.email.to')}:</span>
                                <span>
                                    {email.receivers.map((r) => r.email).join(', ')}
                                    {!email.cc && !email.bcc && hideButton}
                                </span>
                            </span>
                            {email.cc && (
                                <span className="flex gap-1">
                                    <span className="flex-none w-9">{t('emailsView.email.cc')}:</span>
                                    <span>
                                        {email.cc.map((r) => r.email).join(', ')}
                                        {!email.bcc && hideButton}
                                    </span>
                                </span>
                            )}
                            {email.bcc && (
                                <span className="flex gap-1">
                                    <span className="flex-none w-9">{t('emailsView.email.bcc')}:</span>
                                    <span>
                                        {email.bcc.map((r) => r.email).join(', ')}
                                        {hideButton}
                                    </span>
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>

            <div className="flex gap-2 items-center text-secondary text-sm">
                {email.attachments.length > 0 && (
                    <span className="text-primary">
                        <AttachmentIcon />
                    </span>
                )}
            </div>
        </div>
    );
};

const EmailContent = ({
    subject,
    content,
    attachments,
}: {
    subject: string;
    content: string;
    attachments: IEmailAttachment[];
}) => {
    const isDarkMode = !!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY);

    return (
        <div className="rounded-xl flex flex-col gap-5 p-7 border border-primary border-solid bg-primary">
            <div className="font-medium text-primary">{subject}</div>
            <div className="flex flex-col gap-4 leading-relaxed">
                <Frame>
                    <style>
                        {fontsStyle.replaceAll('../', '/')}
                        {contentStyle}
                    </style>
                    <div
                        className={classnames('email__html', isDarkMode && 'email__html--dark-mode')}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </Frame>
            </div>

            {attachments.length > 0 &&
                attachments.map((attachment, index) => <EmailAttachment key={index} attachment={attachment} />)}
        </div>
    );
};

const EmailAttachment = ({ attachment }: { attachment: IEmailAttachment }) => {
    return (
        <a
            className="flex gap-3 items-center bg-secondary !text-primary px-4 py-3 border border-primary border-solid rounded-md hover:bg-tertiary hover:border-tertiary cursor-pointer transition-colors"
            target="_blank"
            href={attachment.url}
        >
            <FileIcon />
            <span className="text-primary">{attachment.name}</span>
        </a>
    );
};

const Email = ({ email }: { email: DocumentEmail }) => {
    const { content, attachments } = useDocumentEmailBody(email);
    return (
        <div className="flex gap-3">
            <EmailAvatar emailAddress={email.sender} />

            <div className="flex flex-col gap-4 flex-1">
                <EmailHeader email={email} />
                <EmailContent subject={email.subject} content={content} attachments={attachments} />
            </div>
        </div>
    );
};

const EmailLoader = () => {
    return (
        <div className="flex gap-3">
            <div className="flex flex-none border border-solid border-primary items-center justify-center w-8 h-8 bg-primary rounded-full text-primary font-medium text-sm -mt-1.5" />

            <div className="flex flex-col gap-4 flex-1">
                <div className="flex flex-col gap-4 flex-1">
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-1 items-center">
                            <span className="font-medium text-primary w-32">
                                <LoaderLine />
                            </span>
                            <span className="text-secondary text-sm w-32">
                                <LoaderLine />
                            </span>
                        </div>
                    </div>

                    <div className="rounded-xl flex flex-col gap-5 p-7 border border-primary border-solid bg-primary">
                        <Loader className="p-0 m-0" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const EmailsView = React.memo(() => {
    const { t } = useTranslation('assistance');

    const [isFormVisible, setIsFormVisible] = useState(false);

    const { record } = useAssistanceContext();
    const {
        data: emailsData,
        loading: emailsLoading,
        refetch: emailsRefetch,
    } = useQuery(GET_PROCESSING_RECORD_EMAILS, {
        variables: {
            recordId: record?.id,
        },
        skip: !record?.id,
    });
    const originalEmail: DocumentEmail = emailsData?.orderDocumentEmails?.received;
    const sentEmails: DocumentEmail[] = emailsData?.orderDocumentEmails?.sent || [];

    useEffect(() => {
        if (record?.id && !emailsLoading && sentEmails.length === 0) {
            setIsFormVisible(true);
        }
    }, [record?.id, emailsLoading, sentEmails]);

    const [sendAssistanceEmail] = useMutation(SEND_ASSISTANCE_EMAIL);
    const handleSubmit = async (email: DocumentEmail) => {
        await sendAssistanceEmail({
            variables: {
                recordId: record.id,
                email: email,
            },
        });
        return await emailsRefetch();
    };

    return (
        <SplitPanes initialWidths={[66, 34]} initialHeights={[66, 34]}>
            <Pane className="bg-secondary">
                <AssistanceImageMap />
            </Pane>
            <Pane>
                <aside className="flex flex-col flex-auto min-h-0 overflow-auto bg-secondary">
                    <div className="flex flex-col w-full relative">
                        <div className="flex justify-between gap-4 items-center sticky top-0 z-20 bg-primary p-6 border-b border-solid border-primary text-primary">
                            <h3 className="font-medium">{t('emailsView.title')}</h3>
                        </div>
                        <div className="px-6 py-8 bg-secondary flex flex-col gap-8">
                            {(!record?.id || emailsLoading) && <EmailLoader />}

                            {sentEmails.map((email, index) => (
                                <Email key={index} email={adjustEmail(email)} />
                            ))}

                            {isFormVisible ? (
                                <EmailEditor
                                    record={record}
                                    originalEmail={originalEmail}
                                    sourceFile={emailsData?.orderDocumentEmails?.sourceFile}
                                    onSubmit={async (email) => {
                                        await handleSubmit(email);
                                        setIsFormVisible(false);
                                    }}
                                    onCancel={() => setIsFormVisible(false)}
                                />
                            ) : (
                                <div className="flex justify-end gap-2">
                                    <Button
                                        onClick={() => setIsFormVisible(true)}
                                        className="flex gap-2 items-center"
                                        disabled={!record?.id || emailsLoading}
                                    >
                                        <EmailIcon />
                                        {t('emailsView.newEmailButton')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </aside>
            </Pane>
        </SplitPanes>
    );
});

const AssistanceImageMap = (props: any) => {
    const { ocr } = useAssistanceContext();

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    const handleImageLoad = (evt) => {
        const { width, height } = evt.currentTarget;
        setImageDimensions({ width, height });
    };

    const [imageSrc, setImageSrc] = useState(ocr?.processedFile.url);
    useEffect(() => {
        setImageSrc(ocr?.processedFile.url);
    }, [ocr?.processedFile.url]);

    return (
        <AssistanceCanvas artboardWidth={imageDimensions.width} artboardHeight={imageDimensions.height}>
            <ImageMap src={imageSrc} numPages={ocr?.pagesCount} onLoad={handleImageLoad} />
        </AssistanceCanvas>
    );
};

export default EmailsView;
