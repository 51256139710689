import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import { Link } from 'react-router-dom';

import './style.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faBroom, faCheck, faEdit, faPlus, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';

export interface IProps {
    label?: any;
    className?: string;
    disabled?: boolean;
    onClick?: any;
    linkTo?: string;
    linkExternal?: boolean;
    type?: any;
}

const EditIcon = withIcon(faEdit);
const ClearIcon = withIcon(faBroom);
const DeleteIcon = withIcon(faTrash);
const CancelIcon = withIcon(faTimes);
const CheckIcon = withIcon(faCheck);
const PlusIcon = withIcon(faPlus);

const Button = React.forwardRef((props: IProps, ref: any) => {
    const {
        label,
        className,
        disabled,
        onClick,
        linkTo,
        linkExternal = false,
        type = 'button',
        ...buttonProps
    } = props;
    const handleClick = (e, ...props) => {
        if (!linkTo && type !== 'submit' && onClick) e.preventDefault();
        onClick?.(e, ...props);
    };

    if (linkTo && linkExternal) {
        return (
            <a
                className={classnames('button', linkTo && disabled && 'button--disabled', className)}
                onClick={handleClick}
                href={linkTo}
                target={'_blank'}
            >
                {label}
            </a>
        );
    }

    const Component = linkTo ? Link : 'button';
    return (
        <Component
            className={classnames('button', linkTo && disabled && 'button--disabled', className)}
            onClick={handleClick}
            disabled={disabled}
            to={linkTo}
            type={type}
            {...buttonProps}
            ref={ref}
        >
            {className?.includes('button--icon-edit') && <EditIcon className="icon" />}
            {className?.includes('button--icon-clear') && <ClearIcon className="icon" />}
            {className?.includes('button--icon-delete') && <DeleteIcon className="icon" />}
            {className?.includes('button--icon-cancel') && <CancelIcon className="icon" />}
            {className?.includes('button--icon-check') && <CheckIcon className="icon" />}
            {className?.includes('button--icon-plus') && <PlusIcon className="icon" />}
            {label}
        </Component>
    );
});

export const SecondaryButton = React.forwardRef((props: IProps, ref: any) => {
    const classNames = classnames('button', 'button--secondary', props.className);
    return <Button {...props} ref={ref} className={classNames} />;
});

export default Button;
