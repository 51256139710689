import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import './style.scss';

const AssistanceView = (props) => {
    return <div {...props} className={classnames('assistance-view', props?.className)} />;
};

export default AssistanceView;
