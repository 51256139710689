import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import { Link } from 'react-router-dom';

import './style.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';

const ArrowIcon = withIcon(faArrowRightLong);

interface IProps {
    className?: string;
    label: string;
    value?: string;
    onClick?: any;
    linkTo?: any;
}

const Breadcrumb = (props: IProps) => {
    const { className, label, onClick, value, linkTo } = props;

    const handleClick = () => {
        if (onClick) onClick(value);
    };

    return (
        <Link className={classnames('breadcrumb', className)} onClick={handleClick} to={linkTo || ''}>
            {label}
            <ArrowIcon className="breadcrumb__icon" />
        </Link>
    );
};

export default Breadcrumb;
