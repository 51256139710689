import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { canManageUsers } from '../../../users/utils';
import { SecondaryButton } from '../../../core/components/Button';
import Page from '../../../core/components/Page';
import Loader from '../../../core/components/Loader';
import TeamCard from '../../components/TeamCard';
import { url } from '../../../core/utils/link';

import { ADD_PATH, DETAIL_PATH } from '../../constants';
import { GET_TEAMS } from '../../queries';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const Overview = (props) => {
    const { user } = props;

    const { t } = useTranslation('teams');

    const { data, error, loading } = useQuery(GET_TEAMS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });

    return (
        <Layout>
            <Page className="teams">
                <div className="teams__header">
                    <div className="teams__title">{t('overview.title')}</div>
                    {canManageUsers(user) && <SecondaryButton label={t('overview.addTeam')} linkTo={url(ADD_PATH)} />}
                </div>

                <div className="teams__cards">
                    {loading ? (
                        <Loader />
                    ) : (
                        data?.teams?.map((team) => (
                            <TeamCard
                                key={team.id}
                                id={team.id}
                                name={team.name}
                                isDefault={team.isDefault}
                                description={team.description}
                                users={team.users}
                                url={url(DETAIL_PATH, { teamId: team.id })}
                            />
                        ))
                    )}
                </div>
            </Page>
        </Layout>
    );
};

export default Overview;
