import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

// @ts-ignore
import LogoSvg from '../../../../../static/images/workist_logo.svg?react';

interface IProps {
    className?: string;
}

const Logo = (props: IProps) => {
    const { className } = props;
    return (
        <div className={classnames('logo', className)}>
            <LogoSvg />
        </div>
    );
};

export default Logo;
