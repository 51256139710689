import * as React from 'react';
import { useState } from 'react';
import classnames from '../../../core_updated/utils/classnames';

import Button, { IProps as IButtonProps } from '../../components/Button';

import './style.scss';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

export interface IProps extends IButtonProps {
    labelInProgress?: String;
    inProgress?: boolean;
    setInProgress?: (inProgress: boolean) => void;
    disabled?: boolean;
}

const LoadingIcon = withIcon(faSpinnerThird);

const ProgressButton = (props: IProps) => {
    const {
        className,
        label,
        labelInProgress,
        onClick = undefined,
        inProgress: controlledInProgress = undefined,
        setInProgress: controlledSetInProgress = undefined,
        disabled = false,
    } = props;
    const [inProgress, setInProgress] = useControllableState(false, controlledInProgress, controlledSetInProgress);
    const classNames = classnames('progress-button', inProgress ? 'progress-button--active' : '', className);

    const onClickWrapper = (...props) => {
        if (inProgress || !onClick) return;

        setInProgress(true);
        const result = onClick?.(...props);

        if (result instanceof Promise) {
            return result.then((result) => {
                setInProgress(false);
                return result;
            });
        } else {
            setInProgress(false);
            return result;
        }
    };

    return (
        <Button
            {...props}
            disabled={disabled}
            className={classNames}
            onClick={onClickWrapper}
            label={
                inProgress ? (
                    <span>
                        <LoadingIcon className="progress-button__spinner" /> {labelInProgress}
                    </span>
                ) : (
                    label
                )
            }
        />
    );
};

export default ProgressButton;
