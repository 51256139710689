import Overview, { AddUserFormModal, EditUserFormModal } from './pages/Overview';
import Profile from './pages/Profile';
import Home from './pages/Home';

import { canManageUsers } from './utils';

import { ADD_PATH, EDIT_PATH, OVERVIEW_PATH, PROFILE_PATH } from './constants';
import { HOME_PATH } from '../../constants';

export default [
    {
        path: PROFILE_PATH,
        component: Profile,
    },
    {
        path: OVERVIEW_PATH,
        component: Overview,
        children: [
            {
                path: ADD_PATH,
                condition: ({ user }) => canManageUsers(user),
                component: (props) => <AddUserFormModal {...props} />,
            },
            {
                path: EDIT_PATH,
                condition: ({ user }) => canManageUsers(user),
                component: (props) => <EditUserFormModal {...props} />,
            },
        ],
    },
    {
        path: HOME_PATH,
        component: Home,
    },
];
