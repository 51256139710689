import { useTranslation } from 'react-i18next';
import * as React from 'react';
import Filter from '../../../core_updated/components/Filter.tsx';

export const EXTERNAL_STATUS_FILTER_CHOICES = {
    SUCCESSFULLY_ENTERED_API_STATUS: 'Erfolgreich_erfasst',
    ENTER_MANUALLY_API_STATUS: 'Manuell_erfassen',
    ENTERED_MANUALLY_API_STATUS: 'Manuell_erfasst',
    INCOMPLETE_API_STATUS: 'Unvollständig',
    EMPTY_API_STATUS: '__empty__',
};

const ExternalStatusFilter = ({ filters, onFiltersChange }) => {
    const { t } = useTranslation('assistance');

    const filterName = 'external_status';
    const filter = filters.find((f) => f.name === filterName);

    const selected = filter ? (Array.isArray(filter.value) ? filter.value : [filter.value]) : [];

    const handleSelectedChange = (selected: string[]) => {
        const newFilters = filters.filter((f) => f.name !== filterName);
        if (selected.length) {
            newFilters.push({ name: filterName, value: selected, exclude: false, expression: 'eq' });
        }
        onFiltersChange(newFilters);
    };

    const options = Object.entries(EXTERNAL_STATUS_FILTER_CHOICES).map(([status, value]) => ({
        value,
        label: t(`overview.filterForm.externalStatus.${value}`),
    }));

    return (
        <Filter
            allLabel={t('overview.filters.externalStatus.all')}
            label={t('overview.filters.externalStatus.label')}
            options={options}
            selected={selected}
            onSelectedChange={handleSelectedChange}
            multiple
        />
    );
};

export default ExternalStatusFilter;
