import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import type { IUser } from '../../../users/interfaces';

import List from '../../../core/components/List';
import ListItem from '../../../core/components/ListItem';
import UserListItem from '../../../users/components/UserListItem';
import Checkbox from '../../../core/components/Checkbox';

import './style.scss';

interface IProps {
    className?: string;
    users?: IUser[]; // list of all possible users
    value?: string[]; // list of selected user ids
    onChange: (users) => void;
    viewer?: IUser;
}

const UserSelect = (props: IProps) => {
    const { className, onChange, value = [], users = [], viewer } = props;

    const setUser = (key, checked) => onChange(checked ? [...value, key] : value.filter((k) => k !== key));

    return (
        <div className={classnames('user-select', className)}>
            <List className="user-select__list">
                {(users || []).map((user) => {
                    return (
                        <ListItem key={user.id} attributes={{ roles: user.groups }}>
                            <UserListItem
                                id={user.id}
                                viewerId={viewer?.id}
                                className="user-select__list-item"
                                firstName={user.firstName}
                                lastName={user.lastName}
                                email={user.email}
                                buttons={
                                    <Checkbox
                                        checked={value.includes(user.id)}
                                        onChange={(e) => setUser(user.id, e.target.checked)}
                                    />
                                }
                                onClick={() => setUser(user.id, !value.includes(user.id))}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </div>
    );
};

export default UserSelect;
