import * as React from 'react';

import classnames from '../../../core_updated/utils/classnames';

import Input from '../../components/Input';
import DropDown, { DropDownToggle, DropDownMenu } from '../DropDown';

import './style.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

export interface IProps {
    className?: string;
    children?: any;
    value: string;
    placeholder?: string;
    onChange?: any;
    onFocus?: any;
    onBlur?: any;
}

const ChevronDownIcon = withIcon(faChevronDown);

const Select = React.forwardRef((props: IProps, ref: any) => {
    const { className, children, value, onChange, placeholder, onFocus, onBlur } = props;

    const valueMap = Object.fromEntries(
        React.Children.toArray(children).map((child: any, i) => {
            const { value, label } = child.props;
            return [value, label === undefined ? value : label];
        })
    );
    return (
        <DropDown className={classnames('select', className)} onOpenMenu={onFocus} onCloseMenu={onBlur}>
            <DropDownToggle className="select__field">
                <Input
                    ref={ref}
                    className="select__input"
                    readOnly={true}
                    value={valueMap[value] || value}
                    placeholder={placeholder}
                />
                <div className="select__icon">
                    <ChevronDownIcon />
                </div>
            </DropDownToggle>
            <DropDownMenu onChange={onChange}>{children}</DropDownMenu>
        </DropDown>
    );
});

export default Select;
