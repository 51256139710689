import classnames from '../utils/classnames.tsx';
import { Slot } from '@radix-ui/react-slot';
import * as React from 'react';

const FormField = ({ className, label, required = false, children, error, ...props }: any) => {
    return (
        <div className={classnames('flex flex-col gap-3', className)} {...props}>
            <label className="text-xs text-secondary min-w-36 flex-none flex justify-start gap-1">
                <span className="truncate">{label}</span> {required && <span className="text-error">*</span>}
            </label>
            <div className="flex gap-1 flex-1 min-w-0 flex-col">
                <Slot
                    className={classnames(
                        'hover:!border-confidence-high focus-within:border-confidence-high focus-within:outline-surface-brand',
                        error && 'border-confidence-low'
                    )}
                >
                    {children}
                </Slot>
            </div>
            {error?.message && <span className="text-confidence-low text-xs">{error.message}</span>}
        </div>
    );
};

export default FormField;
