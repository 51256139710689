import * as React from 'react';
import { useState } from 'react';
import classnames from '../../../utils/classnames';
import DropdownMenu from '../../DropdownMenu';
import { NavigationItemProps } from './NavigationItem';
import NavigationButton from './NavigationButton';

const NavigationDropDownItem = ({
    label,
    onOpenChange,
    className,
    active: propsActive,
    children,
    icon,
}: NavigationItemProps & { onOpenChange: any; loading: boolean; children }) => {
    const [active, setActive] = useState(false);

    const handleOpenChange = (open: boolean) => {
        setActive(open);
        onOpenChange?.(open);
    };

    return (
        <li className={classnames('flex flex-none flex-col gap-1 rounded-md', className)}>
            <span className="flex-none px-4 py-1">
                <DropdownMenu onOpenChange={handleOpenChange}>
                    <DropdownMenu.Trigger asChild>
                        <NavigationButton active={propsActive || active} icon={icon} label={label} ellipsis={true} />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content side="right" align="end">
                        {children}
                    </DropdownMenu.Content>
                </DropdownMenu>
            </span>
        </li>
    );
};

export default NavigationDropDownItem;
