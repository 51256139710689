import * as React from 'react';

import './style.scss';
import { Link } from 'react-router-dom';
import classnames from '../../../core_updated/utils/classnames';

interface IProps {
    level?: Number;
    className?: String;
    children?: any;
    onClick?: any;
    linkTo?: string;
}

export const Card = (props: IProps) => {
    const {
        level = 0,
        className,
        children,
        onClick,
        linkTo, // TODO: think about making this explicit with a CardLink component
    } = props;

    const Base = linkTo ? Link : 'div';
    return (
        <Base className={classnames('card', `card--level-${level}`, className)} onClick={onClick} to={linkTo}>
            {children}
        </Base>
    );
};

export default Card;
