import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import './style.scss';

interface IProps {
    className?: string;
    children?: any;
    onChange?: any;
}

const Breadcrumbs = (props: IProps) => {
    const { className, children, onChange } = props;
    return (
        <nav className={classnames('breadcrumbs', className)}>
            {React.Children.map(children, (child, i) => {
                const value = child.props.value || i;

                const handleClick = (event) => {
                    if (onChange) onChange(event);
                    if (child.props.onClick) child.props.onClick(event);
                };

                return <child.type {...child.props} key={value} value={value} onClick={handleClick} />;
            })}
        </nav>
    );
};

export default Breadcrumbs;
