import { useSearchParams } from 'react-router-dom';

const useSearchParamState = (key, fallbackValue = undefined): [string, (newValue: string) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();
    const value = searchParams.get(key) || fallbackValue;

    const setState = (newValue) => {
        setSearchParams((params) => {
            if (newValue) {
                params.set(key, newValue);
            } else {
                params.delete(key);
            }
            return params;
        });
    };

    return [value, setState];
};

export default useSearchParamState;
