import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import './style.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

const DeleteIcon = withIcon(faXmark);

interface IProps {
    prefix?: string;
    label: string;
    deletable?: boolean;
    onDelete?: any;
    onClick?: any;
    className?: string;
}

const Chip = (props: IProps) => {
    const { prefix, label, deletable, onDelete, onClick, className } = props;

    return (
        <div className={classnames('chip', className)} onClick={onClick}>
            {prefix && <span className="chip__prefix">{prefix}</span>}
            <span className="chip__label">{label}</span>
            {deletable && (
                <a className="chip__delete" onClick={onDelete}>
                    <DeleteIcon />
                </a>
            )}
        </div>
    );
};

export default Chip;
