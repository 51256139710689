import * as React from 'react';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

export interface IProps {
    id?: string;
    name?: string;
    label?: string;
    className?: string;
    disabled?: boolean;
    onChange?: any;
    onClick?: any;
    onBlur?: any;
    checked?: boolean;
    required?: boolean;
    readOnly?: boolean;
    alignRight?: boolean;
}

const CheckIcon = withIcon(faCheck);

const Checkbox = React.forwardRef(({ id, name, label = '', className, alignRight, ...props }: IProps, ref: any) => {
    return (
        <label className={classnames('checkbox', className)}>
            {alignRight && label && <span className="checkbox__label__left">{label}</span>}
            <input ref={ref} id={id} className="checkbox__input" name={name} type="checkbox" {...props} />
            <div className="checkbox__box">{props.checked && <CheckIcon className="checkbox__icon" />}</div>
            {!alignRight && label && <span className="checkbox__label">{label}</span>}
        </label>
    );
});

export default Checkbox;
