import { DocumentNode as GraphQLDocumentNode, MutationTuple, useMutation, useQuery } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import {
    GET_ASSISTANCE_DATA as GET_ORDER_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_ORDER_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_ORDER_PROCESSING_RECORD,
} from '../orders/queries';
import {
    GET_ASSISTANCE_DATA as GET_ORDER_CONFIRMATION_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_ORDER_CONFIRMATION_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_ORDER_CONFIRMATION_PROCESSING_RECORD,
} from '../order_confirmations/queries';
import {
    GET_ASSISTANCE_DATA as GET_INVOICE_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_INVOICE_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_INVOICE_PROCESSING_RECORD,
} from '../invoices/queries';
import {
    GET_ASSISTANCE_DATA as GET_RFQ_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_RFQ_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_RFQ_PROCESSING_RECORD,
} from '../rfqs/queries';
import {
    GET_ASSISTANCE_DATA as GET_PROPERTYBILL_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_PROPERTYBILL_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_PROPERTYBILL_PROCESSING_RECORD,
} from '../db_immo/queries';
import {
    GET_ASSISTANCE_DATA as GET_DELIVERY_NOTE_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_DELIVERY_NOTE_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_DELIVERY_NOTE_PROCESSING_RECORD,
} from '../delivery_notes/queries';
import {
    GET_ASSISTANCE_DATA as GET_UNIVERSAL_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_UNIVERSAL_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_UNIVERSAL_PROCESSING_RECORD,
} from '../universal/queries';
import {
    GET_ASSISTANCE_DATA as GET_LIST_OF_SERVICES_PROCESSING_RECORD,
    RESELECT_PROCESSING_RECORD as RESELECT_LIST_OF_SERVICES_PROCESSING_RECORD,
    UPDATE_PROCESSING_RECORD as UPDATE_LIST_OF_SERVICES_PROCESSING_RECORD,
} from '../list_of_services/queries';
import { unpackAndMergeDynamicSchemaFields } from '../utils';
import { DocumentType } from '../generic_document/constants';
import { createContext } from 'react';

interface IState {}
interface IActions {}
interface IQueries {
    getDocumentProcessingRecord: (documentType: DocumentType, recordId: string, queryParams?: any) => QueryResult;
    useUpdateDocument: (documentType: DocumentType, queryParams?: any) => MutationTuple<any, any>;
    useReselectDocument: (documentType: DocumentType, queryParams?: any) => MutationTuple<any, any>;
}
type IStore = IState & IActions & IQueries;

const initialState: IState = {};

// should be provided for document-centric views, e.g. when assisting a document
export const DocumentContext = createContext({ document: null });

/**
 * Store for managing documents of all types
 */
const useStore = create<IStore, [['zustand/devtools', IStore]]>(
    devtools((set, get) => ({
        ...initialState,

        getDocumentProcessingRecord: (documentType, recordId, queryParams = {}) => {
            return buildDocumentQuery(
                documentType,
                {
                    [DocumentType.Order]: GET_ORDER_PROCESSING_RECORD,
                    [DocumentType.OrderConfirmation]: GET_ORDER_CONFIRMATION_PROCESSING_RECORD,
                    [DocumentType.Invoice]: GET_INVOICE_PROCESSING_RECORD,
                    [DocumentType.Rfq]: GET_RFQ_PROCESSING_RECORD,
                    [DocumentType.PropertyBill]: GET_PROPERTYBILL_PROCESSING_RECORD,
                    [DocumentType.DeliveryNote]: GET_DELIVERY_NOTE_PROCESSING_RECORD,
                    [DocumentType.Universal]: GET_UNIVERSAL_PROCESSING_RECORD,
                    [DocumentType.ListOfServices]: GET_LIST_OF_SERVICES_PROCESSING_RECORD,
                },
                {
                    id: recordId,
                },
                queryParams
            );
        },

        useUpdateDocument: (documentType) => {
            return buildDocumentMutation(documentType, {
                [DocumentType.Order]: UPDATE_ORDER_PROCESSING_RECORD,
                [DocumentType.OrderConfirmation]: UPDATE_ORDER_CONFIRMATION_PROCESSING_RECORD,
                [DocumentType.Invoice]: UPDATE_INVOICE_PROCESSING_RECORD,
                [DocumentType.Rfq]: UPDATE_RFQ_PROCESSING_RECORD,
                [DocumentType.PropertyBill]: UPDATE_PROPERTYBILL_PROCESSING_RECORD,
                [DocumentType.DeliveryNote]: UPDATE_DELIVERY_NOTE_PROCESSING_RECORD,
                [DocumentType.Universal]: UPDATE_UNIVERSAL_PROCESSING_RECORD,
                [DocumentType.ListOfServices]: UPDATE_LIST_OF_SERVICES_PROCESSING_RECORD,
            });
        },

        useReselectDocument: (documentType) => {
            return buildDocumentMutation(documentType, {
                [DocumentType.Order]: RESELECT_ORDER_PROCESSING_RECORD,
                [DocumentType.OrderConfirmation]: RESELECT_ORDER_CONFIRMATION_PROCESSING_RECORD,
                [DocumentType.Invoice]: RESELECT_INVOICE_PROCESSING_RECORD,
                [DocumentType.Rfq]: RESELECT_RFQ_PROCESSING_RECORD,
                [DocumentType.PropertyBill]: RESELECT_PROPERTYBILL_PROCESSING_RECORD,
                [DocumentType.DeliveryNote]: RESELECT_DELIVERY_NOTE_PROCESSING_RECORD,
                [DocumentType.Universal]: RESELECT_UNIVERSAL_PROCESSING_RECORD,
                [DocumentType.ListOfServices]: RESELECT_LIST_OF_SERVICES_PROCESSING_RECORD,
            });
        },
    }))
);

const buildDocumentQuery = (
    documentType: DocumentType,
    queriesByDocumentType: { [key in DocumentType]: GraphQLDocumentNode },
    queryVariables = {},
    queryParams: { [key: string]: any } = {}
) => {
    const documentSpecificQuery = queriesByDocumentType[documentType];
    const { onCompleted } = queryParams;

    return useQuery(documentSpecificQuery, {
        variables: queryVariables,
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        ...queryParams,
        onCompleted: (document: any) => {
            // Mutate the result data in-place
            // Note: This is not a promise .then handler, we cannot return a new value here
            unpackAndMergeDynamicSchemaFields(document);
            onCompleted?.(document);
        },
    });
};

const buildDocumentMutation = (
    documentType: DocumentType,
    queriesByDocumentType: { [key in DocumentType]: GraphQLDocumentNode },
    queryParams: { [key: string]: any } = {}
) => {
    const documentSpecificQuery = queriesByDocumentType[documentType];
    const { onCompleted } = queryParams;

    return useMutation(documentSpecificQuery, {
        notifyOnNetworkStatusChange: true,
        ...queryParams,
        onCompleted: (document: any) => {
            // Mutate the result data in-place
            // Note: This is not a promise .then handler, we cannot return a new value here
            unpackAndMergeDynamicSchemaFields(document);
            onCompleted?.(document);
        },
    });
};

export const useDocumentStore = useStore;
