import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import moment from 'moment';

import SplitPanes, { Pane } from '../../../core/containers/SplitPanes';
import Loader from '../../../core/components/Loader';
import ProgressButton from '../../../core/containers/ProgressButton';

import InputDataPreview from '../InputDataPreview';
import { StringField } from '../Fields';

import './style.scss';
import { useSteprunInfo } from '../../../core/utils/steprunConfig';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faCheck, faSpinnerThird, faXmark } from '@fortawesome/pro-regular-svg-icons';

const formatDate = (date) => (date && moment(date).isValid() ? moment(date).format('DD.MM.YYYY, HH:mm:ss') : undefined);

const EXECUTION_STATUS_MAP = {
    PENDING: 'pending',
    SCHEDULED: 'pending',
    RUNNING: 'pending',
    WAITING_FOR_MANUAL: 'pending',

    SUCCEEDED: 'success',
    FAILED: 'error',
};

const LoadingIcon = withIcon(faSpinnerThird);
const SuccessIcon = withIcon(faCheck);
const ErrorIcon = withIcon(faXmark);

const Status = ({ record, status, onRetryStep }) => {
    const { t } = useTranslation('assistance');

    const { steprunConfig } = useSteprunInfo(record, status);

    const stepName = (status?.stepName && camelCase(status?.stepName)) || 'finished';
    const statusClassName = EXECUTION_STATUS_MAP[status?.executionStatus] || 'pending';

    const context = (status?.context && JSON.parse(status?.context)) || {};
    const fields = {
        createdAt: formatDate(status?.createdAt),
        updatedAt: formatDate(status?.updatedAt),
        ...context?.details,
    };

    return (
        <>
            <div className="field-group">
                <div className="field-group__title">
                    <div className={`result-view__status result-view__status--${statusClassName}`}>
                        {statusClassName === 'pending' && <LoadingIcon spin className="result-view__status-icon" />}
                        {statusClassName === 'success' && <SuccessIcon className="result-view__status-icon" />}
                        {statusClassName === 'error' && <ErrorIcon className="result-view__status-icon" />}
                        {statusClassName && t(`resultView.steps.${stepName}`)}
                    </div>
                </div>
                {Object.entries(fields).map(([fieldName, fieldValue]) => (
                    <StringField
                        key={fieldName}
                        label={t(`resultView.fields.${fieldName}`)}
                        value={fieldValue}
                        inputProps={{ disabled: true }}
                    />
                ))}
            </div>
        </>
    );
};

const ResultView = (props) => {
    const { record, file, stepRunList, onRetryStep } = props;
    const { t } = useTranslation('assistance');
    // make sure they are in the right order
    const sortedStepRunList = stepRunList?.sort((a, b) => {
        const aCreatedAt = moment(a.createdAt).unix();
        const bCreatedAt = moment(b.createdAt).unix();
        const aUpdatedAt = moment(a.updatedAt).unix();
        const bUpdatedAt = moment(b.updatedAt).unix();

        if (aCreatedAt > bCreatedAt) {
            return 1;
        }
        if (aCreatedAt < bCreatedAt) {
            return -1;
        }
        if (aUpdatedAt > bUpdatedAt) {
            return 1;
        }
        if (aUpdatedAt < bUpdatedAt) {
            return -1;
        }
        return 0;
    });
    const exportFile = file?.files?.[0];

    return (
        <div className="result-view assistance-view__inner">
            <SplitPanes id="assistance-result">
                <Pane>
                    <div className="assistance-view__preview result-view__preview">
                        {!file ? (
                            <Loader className="result-view__loader result-view__loader--file" />
                        ) : (
                            <InputDataPreview data={file} className="assistance-view__preview-viewer" />
                        )}
                    </div>
                    {exportFile && (
                        <div className="assistance-view__preview-buttons">
                            <a href={exportFile.url} target="_blank" className="button result-view__download-button">
                                {t('resultView.downloadButton')}
                            </a>
                        </div>
                    )}
                </Pane>
                <Pane>
                    <div className="assistance-view__form-wrapper">
                        <div className="assistance-view__form result-view__form">
                            {sortedStepRunList.length === 0 ? (
                                <Loader className="result-view__loader" />
                            ) : (
                                sortedStepRunList?.map((step) => (
                                    <Status key={step.id} record={record} status={step} onRetryStep={onRetryStep} />
                                ))
                            )}
                        </div>
                    </div>
                </Pane>
            </SplitPanes>
        </div>
    );
};

export default ResultView;
