import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(...registerables);

import classnames from '../../../core_updated/utils/classnames';

import './style.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

const CHART_OPTIONS: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                stacked: true,
                ticks: {
                    precision: 0,
                },
                gridLines: {
                    display: false,
                },
            },
        ],
        yAxes: [
            {
                stacked: true,
                ticks: {
                    stepSize: 1,
                },
                gridLines: {
                    display: true,
                },
            },
        ],
    },
    legend: {
        display: false,
        position: 'bottom',
        align: 'start',
        labels: {
            boxWidth: 12,
        },
    },
};

const InfoIcon = withIcon(faInfoCircle);

const AutomationRateChart = (props) => {
    const { data, automationRatio = 0, label, className } = props;
    const { t } = useTranslation('dashboard');

    const [chartData, setChartData] = useState(undefined);
    const chartNode = useRef(null);

    useEffect(() => {
        if (!chartNode?.current) return;

        const style = getComputedStyle(chartNode.current);
        const linkPrimary = style.getPropertyValue('--link-primary');
        const borderColor = style.getPropertyValue('--border-secondary');

        setChartData({
            datasets: [
                {
                    backgroundColor: linkPrimary,
                    hoverBackgroundColor: linkPrimary,
                    data: data.map((p) => p.automaticCount),
                    label: t('assistance:reports.labels.automatedExecutions'),
                    maxBarThickness: 100,
                },
                {
                    backgroundColor: borderColor,
                    hoverBackgroundColor: borderColor,
                    data: data.map((p) => p.assistedCount),
                    label: t('assistance:reports.labels.assistedExecutions'),
                    maxBarThickness: 100,
                },
            ],
            labels: data.map((p) => p.date),
        });
    }, [chartNode, data]);

    return (
        <div className={classnames('chart', className)} ref={chartNode}>
            <div className="chart__chart">
                <div className="chart__controls">
                    <div className="chart__ratio">
                        {t('assistance:reports.automationRatioShort', {
                            percentage: Math.round(automationRatio * 10000) / 100,
                        })}
                    </div>

                    <div className="chart__label-channel">
                        <div className="chart__label">{t('assistance:reports.channelReport.channel')}</div>
                        <div className="chart__channel">
                            {label ? label : t('assistance:reports.channelReport.allChannels')}
                        </div>
                    </div>
                </div>

                <div className="chart__size-helper">
                    {chartData && <Bar data={chartData} options={CHART_OPTIONS} />}
                </div>
            </div>

            <div className="chart__footer">
                <div className="chart__legend legend">
                    <div className="legend__label legend__label--automatic">
                        {t('assistance:reports.labels.automatedExecutions')}
                    </div>
                    <div className="legend__label legend__label--assisted">
                        {t('assistance:reports.labels.assistedExecutions')}
                    </div>
                </div>

                <div className="chart__note">
                    <InfoIcon /> {t('assistance:reports.channelReport.info')}
                </div>
            </div>
        </div>
    );
};

export default AutomationRateChart;
