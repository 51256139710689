import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import classnames from '../../../core_updated/utils/classnames';

import * as React from 'react';

import './style.scss';

/**
 * Shared context for a group of popups, use this to control their behaviour as a group
 */
export const createPopupGroupContext = () => {
    let onPopupOpenListeners = [];

    return createContext({
        notifyPopupOpened: (popupKey: string) => {
            for (let listener of onPopupOpenListeners) {
                listener(popupKey);
            }
        },
        onPopupInGroupOpen: (callback) => () => {
            onPopupOpenListeners.push(callback);
            return () => {};
        },
    });
};

/**
 * Higher order component that automatically renders any children of the wrapped component in a popup
 * Note: Only use this to wrap non-interactive base components (for now)
 *
 * You can provide a group context to control the behaviour for a group of popup components (e.g. only have
 * a single popup in the group opened at all times), like so:
 *
 *     // in parent component
 *     const PopupGroupContext = createPopupGroupContext();
 *     const PopupComponent = WithPopup(BaseComponent);
 *     elements.forEach((e) => <PopupComponent popupId={e.id} popupGroupContext={PopupGroupContext} />);
 *
 */
export const AsPopupComponent = (WrappedComponent) => (props) => {
    const {
        key,
        popupId,
        dismissOnClick = true,
        popup,
        popupGroupContext = createPopupGroupContext(), // separate group for each popup by default
        popperOptions = {
            // Set popper config so that we always try to open to the bottom (if there is space in the parent
            // container / viewport), otherwise popper will position dynamically
            placement: 'bottom-start',
            modifiers: [{ name: 'preventOverflow', enabled: true, options: { altAxis: true } }],
        },
        ...passThroughProps
    } = props;
    const { disabled } = passThroughProps;

    // If there is no popup to render, just render the parent component as is
    if (popup == null) {
        return <WrappedComponent {...passThroughProps} />;
    }

    const { notifyPopupOpened, onPopupInGroupOpen }: any = useContext(popupGroupContext);

    useEffect(
        onPopupInGroupOpen((popupKey) => {
            if (popupKey != popupId) {
                setIsPopupOpen(false);
            }
        }),
        []
    );

    const popperRef = useRef(null);
    const popupTriggerRef = useRef(null);
    const [popupElement, setPopupElement] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const togglePopup = (event) => {
        event.stopPropagation();
        const newOpenState = !isPopupOpen;
        setIsPopupOpen(newOpenState);
        newOpenState && popupId && notifyPopupOpened?.(popupId);
    };

    const popper = usePopper(popperRef.current, popupElement, popperOptions);

    useEffect(() => {
        const onDocumentClick = (event) => {
            const isClickOutside = !popperRef?.current?.contains(event.target);
            dismissOnClick && isClickOutside && setIsPopupOpen(false);
        };
        document.addEventListener('click', onDocumentClick);
        return () => {
            document.removeEventListener('click', onDocumentClick);
        };
    }, [dismissOnClick]);

    return (
        <div key={key} className={classnames('popper-container', isPopupOpen && 'popper--open')} ref={popperRef}>
            <span
                ref={popupTriggerRef}
                className={classnames(
                    'popper-trigger',
                    isPopupOpen && 'popper--open',
                    disabled ? 'popper-trigger--disabled' : 'popper-trigger--enabled'
                )}
                onClick={disabled ? null : togglePopup}
            >
                <WrappedComponent {...passThroughProps} />
            </span>
            {isPopupOpen && (
                <div
                    ref={setPopupElement}
                    role="dialog"
                    className="dialog-sheet"
                    // Inject popper's positioning
                    style={popper.styles.popper}
                    {...popper.attributes.popper}
                >
                    {popup}
                </div>
            )}
        </div>
    );
};
