import * as React from 'react';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';

export interface IProps {
    name?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    onChange?: any;
    onClick?: any;
    value?: string;
    required?: boolean;
    readOnly?: boolean;
    // TODO: maybe inherit from HTMLTextAreaProps
}

const TextArea = React.forwardRef(
    (
        {
            className,
            disabled = false,
            placeholder = '',
            value = '',
            required = false,
            readOnly = false,
            ...textareaProps
        }: IProps & React.HTMLProps<HTMLTextAreaElement>,
        ref: any
    ) => {
        return (
            <textarea
                ref={ref}
                className={classnames('textarea', className)}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                required={required}
                readOnly={readOnly}
                rows={4}
                {...textareaProps}
            />
        );
    }
);

export default TextArea;
