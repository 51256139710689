import * as React from 'react';
import { createContext, useContext } from 'react';

interface AssistanceContextProps {
    record: any;
    setRecord: any;
    refetchData: any;
    document: any;
    ocr: any;
    handlers: any;
    tabs: any;
    documentConfiguration: any;
    pagination: any;
    loading: boolean;
    alert: any;
    readOnly?: boolean;
    // state
    isFinished: boolean;
    isAssistanceNeeded: boolean;
}

const AssistanceContext = createContext<AssistanceContextProps>({} as AssistanceContextProps);

export const AssistanceContextProvider = ({ children, ...props }: AssistanceContextProps & { children: any }) => (
    <AssistanceContext.Provider value={{ ...props }}>{children}</AssistanceContext.Provider>
);

export const useAssistanceContext = () => useContext(AssistanceContext);
