import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import type { IUser } from '../../../users/interfaces';
import type { ITeam } from '../../interfaces';

import ProgressButton from '../../../core/containers/ProgressButton';
import Input from '../../../core/components/Input';
import TextArea from '../../../core/components/TextArea';
import UserSelect from '../UserSelect';

import './style.scss';

interface IProps {
    className?: string;
    team?: ITeam;
    viewer?: IUser;
    selectableUsers?: IUser[];
    submitLabel: string;
    onSubmit: (object) => void;
}

const TeamForm = (props: IProps) => {
    const { className, team, viewer, selectableUsers = [], submitLabel, onSubmit } = props;

    const { t } = useTranslation('teams');

    const [name, setName] = useState(team?.name || '');
    const [description, setDescription] = useState(team?.description || '');
    const [userIds, setUserIds] = useState(team?.users?.map((u) => u.id) || []);

    useEffect(() => {
        setName(team?.name || '');
        setDescription(team?.description || '');
        setUserIds(team?.users?.map((u) => u.id) || []);
    }, [team]);

    const handleSubmit = () => onSubmit({ name, description, users: userIds });

    return (
        <div className={classnames('team-form', className)}>
            <div className="team-form__group">
                <label className="team-form__label">{t('form.name')}</label>
                <Input
                    className="team-form__input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={team?.isDefault}
                />
            </div>

            <div className="team-form__group">
                <label className="team-form__label">{t('form.description')}</label>
                <TextArea
                    className="team-form__input"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    disabled={team?.isDefault}
                />
            </div>

            <div className="team-form__group">
                <label className="team-form__label">{t('form.users')}</label>
                <UserSelect
                    onChange={(users) => setUserIds(users)}
                    value={userIds}
                    users={selectableUsers}
                    viewer={viewer}
                />
            </div>

            <div className="team-form__group">
                <ProgressButton label={submitLabel} onClick={handleSubmit} disabled={team?.isDefault} />
            </div>
        </div>
    );
};

export default TeamForm;
