import classnames from '../../../core_updated/utils/classnames';

import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../../core/components/Card';
import ProgressButton from '../../../core/containers/ProgressButton';

const ConfigurationCard = ({
    title,
    description,
    active,
    onActivate = undefined,
    onDeactivate = undefined,
    children,
    className = undefined,
}) => {
    const [collapsed, setCollapsed] = useState(false);

    const { t } = useTranslation('config');

    return (
        <Card
            className={classnames('configuration-card', 'card--no-padding', className)}
            level={active && !collapsed ? 1 : 0}
        >
            <div className="configuration-card__header">
                <div>
                    <div className="configuration-card__title">{title}</div>
                    <div className="configuration-card__description">{description}</div>
                </div>

                <div className="configuration-card__header-buttons">
                    {onDeactivate && onActivate && (
                        <ProgressButton
                            className="button--secondary button--small configuration-card__switch"
                            onClick={active ? onDeactivate : onActivate}
                            label={active ? t('card.deactivate') : t('card.activate')}
                            disabled={(active && !onDeactivate) || (!active && !onActivate)}
                        />
                    )}
                    <ProgressButton
                        className="button--secondary button--small configuration-card__switch"
                        onClick={() => setCollapsed(!collapsed)}
                        label={collapsed ? t('card.expand') : t('card.collapse')}
                        disabled={!active}
                    />
                </div>
            </div>

            {active && !collapsed && <div className="configuration-card__body">{children}</div>}
        </Card>
    );
};

export default ConfigurationCard;
