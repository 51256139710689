import * as React from 'react';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';

interface IProps {
    children?: any;
    className?: string;
    onClick?: any;
    attributes?: object;
}

const ListItem = (props: IProps) => {
    const { children, className, onClick } = props;

    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <div className={classnames('list-item', className)} onClick={handleClick}>
            {children}
        </div>
    );
};

ListItem.displayName = 'ListItem';

export default ListItem;
