import classnames from '../../../../core_updated/utils/classnames';
import { Slot } from '@radix-ui/react-slot';
import { EmailAttachment as IEmailAttachment, File as IFile } from '../../../../assistance/containers/EmailPreview';
import Checkbox from '../../../../core/components/Checkbox';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TagsField from '../../../../core_updated/components/Fields/TagsField';
import Field from '../../../../core_updated/components/Fields/Field';
import StringField from '../../../../core_updated/components/Fields/StringField';
import WysiwygField from '../WysiwygField';
import Button from '../../../../core_updated/components/Button';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../../../App';
import Frame from '../../../../core/components/Frame';
import { useToaster } from '../../../../core/components/Toast';
import { useMutation, useQuery } from '@apollo/client';
import {
    CREATE_EMAIL_TEMPLATE,
    DELETE_EMAIL_TEMPLATE,
    GET_EMAIL_TEMPLATES,
    UPDATE_EMAIL_TEMPLATE,
} from '../../../../orders/queries';
import { useApplicationContext } from '../../../../core_updated/contexts/ApplicationContext';
import { useDocumentEmailBody } from '../EmailsView';
import { withIcon } from '../../../../core_updated/components/Icon';
import {
    faArrowRightToBracket,
    faChevronDown,
    faChevronUp,
    faEdit,
    faFile,
    faPaperPlaneAlt,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import fontsStyle from '../../../../../../stylesheets/fonts.css?inline';
import contentStyle from '../../../../assistance/containers/EmailPreview/content.css?inline';
import Tooltip from '../../../../core/components/Tooltip';
import ConfirmModal from '../../../../core/containers/ConfirmModal';

export interface DocumentEmailAddress {
    name: string;
    email: string;
}

export interface DocumentEmailAttachment {
    url?: string;
    name?: string;
}
export interface DocumentEmail {
    sender?: DocumentEmailAddress;
    receivers: DocumentEmailAddress[];
    cc?: DocumentEmailAddress[];
    bcc?: DocumentEmailAddress[];
    subject: string;
    createdAt?: Date;
    body?: string;
    mimetype?: string;
    attachments?: DocumentEmailAttachment[];
}

export interface EmailTemplate extends DocumentEmail {
    templateName?: string;
}

const FileIcon = withIcon(faFile);
const SendIcon = withIcon(faPaperPlaneAlt);
const ChevronUpIcon = withIcon(faChevronUp);
const ChevronDownIcon = withIcon(faChevronDown);
const RemoveIcon = withIcon(faTrash);
const FillInIcon = withIcon(faArrowRightToBracket);
const EditIcon = withIcon(faEdit);

export const adjustEmail = (email: any): DocumentEmail => {
    // somehow in the backend the cc and bcc are stored as carbonCopy and blindCarbonCopy

    if (!email) return;

    return {
        ...email,
        cc: email.carbonCopy,
        bcc: email.blindCarbonCopy,
    };
};

const splitEmailAddresses = (emailString: string) =>
    emailString
        ?.split(',')
        .filter(Boolean)
        .map((email: string) => ({
            name: '',
            email: email,
        })) || [];

const containsHtml = (str: string) => /<[^>]*>/.test(str);

const emailToFomData = (email: EmailTemplate) => ({
    templateName: email.templateName || '',
    to: email.receivers?.map((receiver) => receiver.email).join(', ') || '',
    cc: email.cc?.map((receiver) => receiver.email).join(', ') || '',
    bcc: email.bcc?.map((receiver) => receiver.email).join(', ') || '',
    subject: email.subject || '',
    message: email.body || '',
});

const DEFAULT_FORM_VALUES = {
    to: '',
    cc: '',
    bcc: '',
    subject: '',
    message: '',
};

const DEFAULT_EMAIL_FORM_VALUES = {
    ...DEFAULT_FORM_VALUES,
    attachmentIndices: [],
    attachOriginalEmail: false,
};

const DEFAULT_TEMPLATE_FORM_VALUES = {
    ...DEFAULT_FORM_VALUES,
    templateName: '',
};

export const EmailAvatar = ({ emailAddress }: { emailAddress: DocumentEmailAddress }) => {
    const initials = emailAddress.name
        ? emailAddress.name
              .split(' ')
              .map((n) => n[0])
              .join('')
        : emailAddress.email[0];
    return (
        <div className="flex flex-none border border-solid border-primary items-center justify-center w-8 h-8 bg-primary rounded-full text-primary font-medium text-sm -mt-1.5">
            {initials}
        </div>
    );
};

const EmailFormField = ({ className, label, required = false, children, error, ...props }: any) => {
    return (
        <div className={classnames('flex flex-col gap-3', className)} {...props}>
            <label className="text-xs text-secondary min-w-36 flex-none flex justify-start gap-1">
                <span className="truncate">{label}</span> {required && <span className="text-error">*</span>}
            </label>
            <div className="flex gap-1 flex-1 min-w-0 flex-col">
                <Slot
                    className={classnames(
                        'hover:!border-confidence-high focus-within:border-confidence-high focus-within:outline-surface-brand',
                        error && 'border-confidence-low'
                    )}
                >
                    {children}
                </Slot>
            </div>
            {error?.message && <span className="text-confidence-low text-xs">{error.message}</span>}
        </div>
    );
};

const FormAttachment = ({
    label,
    checked,
    onCheckedChange,
}: {
    label: string;
    checked: boolean;
    onCheckedChange: (checked: boolean) => void;
}) => {
    return (
        <div
            className="flex gap-3 items-center text-primary text-sm px-4 py-3 border border-primary border-solid rounded-md hover:bg-secondary cursor-pointer transition-colors"
            onClick={() => onCheckedChange(!checked)}
        >
            <FileIcon />
            <span className="text-primary">{label}</span>

            <div className="ml-auto">
                <Checkbox checked={checked} readOnly className="pointer-events-none" />
            </div>
        </div>
    );
};

const EmailFormFields = ({
    isTemplate = false,
    form,
    originalEmail,
    attachments,
    toRef,
    messageRef,
}: {
    isTemplate?: boolean;
    form: any;
    originalEmail?: DocumentEmail;
    attachments?: IFile[];
    toRef?: any;
    messageRef?: any;
}) => {
    const { t } = useTranslation('assistance');

    const cc = form.watch('cc');
    const bcc = form.watch('bcc');

    const [isCcVisible, setIsCcVisible] = useState(Boolean(cc));
    const [isBccVisible, setIsBccVisible] = useState(Boolean(bcc));

    useEffect(() => {
        if (cc) {
            setIsCcVisible(true);
        }
    }, [cc]);

    useEffect(() => {
        if (bcc) {
            setIsBccVisible(true);
        }
    }, [bcc]);

    const formErrors = form.formState.errors;
    const attachmentIndices = form.watch('attachmentIndices');

    const getValidateEmaiAddressList = (key: string) => (value: string) => {
        // split by , and then check each email
        const emails =
            value
                ?.split(',')
                .map((e) => e.trim())
                .filter(Boolean) || [];
        if (emails.some((e) => !/^\S+@\S+$/i.test(e))) {
            return t(`emailsView.emailForm.${key}Invalid`);
        }
    };
    return (
        <>
            {!isTemplate && (
                <Controller
                    name="to"
                    control={form.control}
                    rules={{
                        required: t('emailsView.emailForm.toRequired'),
                        validate: getValidateEmaiAddressList('to'),
                    }}
                    render={({ field }) => (
                        <EmailFormField label={t('emailsView.emailForm.toLabel')} required={true} error={formErrors.to}>
                            <TagsField
                                required={true}
                                inputRef={toRef}
                                {...field}
                                onValueChange={field.onChange}
                                controls={
                                    <div className="flex-none flex items-center gap-0.5">
                                        {!isCcVisible && (
                                            <Field.ControlButton
                                                active={isCcVisible}
                                                className="px-1.5 box-border"
                                                onClick={() => setIsCcVisible(true)}
                                            >
                                                cc
                                            </Field.ControlButton>
                                        )}
                                        {!isBccVisible && (
                                            <Field.ControlButton
                                                active={isBccVisible}
                                                className="px-1.5 box-border"
                                                onClick={() => setIsBccVisible(true)}
                                            >
                                                bcc
                                            </Field.ControlButton>
                                        )}
                                    </div>
                                }
                            />
                        </EmailFormField>
                    )}
                />
            )}

            <Controller
                name="cc"
                control={form.control}
                rules={{ validate: getValidateEmaiAddressList('cc') }}
                render={({ field }) =>
                    (isTemplate || isCcVisible) && (
                        <EmailFormField label={t('emailsView.emailForm.ccLabel')} error={formErrors.cc}>
                            <TagsField {...field} onValueChange={field.onChange} />
                        </EmailFormField>
                    )
                }
            />

            <Controller
                name="bcc"
                control={form.control}
                rules={{ validate: getValidateEmaiAddressList('bcc') }}
                render={({ field }) =>
                    (isTemplate || isBccVisible) && (
                        <EmailFormField label={t('emailsView.emailForm.bccLabel')} error={formErrors.bcc}>
                            <TagsField {...field} onValueChange={field.onChange} />
                        </EmailFormField>
                    )
                }
            />

            <Controller
                name="subject"
                control={form.control}
                rules={{ required: t('emailsView.emailForm.subjectRequired') }}
                render={({ field }) => (
                    <EmailFormField
                        label={t('emailsView.emailForm.subjectLabel')}
                        required={true}
                        error={formErrors.subject}
                    >
                        <StringField required={true} {...field} />
                    </EmailFormField>
                )}
            />

            <Controller
                name="message"
                control={form.control}
                rules={{ required: t('emailsView.emailForm.messageRequired') }}
                render={({ field }) => (
                    <EmailFormField
                        label={t('emailsView.emailForm.messageLabel')}
                        required={true}
                        error={formErrors.message}
                    >
                        <WysiwygField required={true} {...field} inputRef={messageRef} />
                    </EmailFormField>
                )}
            />

            {attachments.length > 0 || originalEmail ? (
                <EmailFormField label={t('emailsView.emailForm.attachedFiles')} error={formErrors.attachmentIndices}>
                    <div className="flex flex-col gap-3">
                        <FormAttachment
                            label={t('emailsView.emailForm.attachOriginalEmail', { subject: originalEmail?.subject })}
                            checked={form.watch('attachOriginalEmail')}
                            onCheckedChange={(checked) => form.setValue('attachOriginalEmail', checked)}
                        />

                        {attachments.map((a, index) => (
                            <FormAttachment
                                key={a.url}
                                label={a.name}
                                checked={attachmentIndices?.includes(index)}
                                onCheckedChange={(checked) =>
                                    form.setValue(
                                        'attachmentIndices',
                                        checked
                                            ? [...attachmentIndices, index]
                                            : attachmentIndices.filter((i) => i !== index)
                                    )
                                }
                            />
                        ))}
                    </div>
                </EmailFormField>
            ) : null}
        </>
    );
};

const EmailSection = ({
    className,
    originalEmail,
    sourceFile,
    selectedTemplate,
    onSubmit,
    onCreateTemplate,
    onCancel,
}: any) => {
    const { t } = useTranslation('assistance');

    const handleCreateTemplate = async () => {
        onCreateTemplate?.(formDataToEmail(form.getValues()));
    };

    const form = useForm({ defaultValues: DEFAULT_EMAIL_FORM_VALUES, mode: 'onBlur' });

    const formDataToEmail = (values: any) => ({
        receivers: splitEmailAddresses(values.to),
        cc: splitEmailAddresses(values.cc),
        bcc: splitEmailAddresses(values.bcc),
        subject: values.subject,
        body: values.message,
        mimetype: containsHtml(values.message) ? 'text/html' : 'text/plain',
        attachments: values.attachmentIndices.map((i) => {
            const attachment = attachments[i];
            return {
                name: attachment.name,
                url: attachment.url,
            };
        }),
        attachOriginalEmail: values.attachOriginalEmail,
    });

    const to = form.watch('to');

    const toRef = React.useRef(null);
    const messageRef = React.useRef(null);

    useEffect(() => {
        if (originalEmail) {
            form.reset({
                ...DEFAULT_EMAIL_FORM_VALUES,
                to: originalEmail?.receivers.map((receiver) => receiver.email).join(', '),
                subject: `Re: ${originalEmail?.subject}`,
            });

            messageRef?.current?.focus();
        } else {
            toRef?.current?.focus();
        }
    }, [originalEmail]);

    useEffect(() => {
        if (selectedTemplate) {
            form.reset({
                ...DEFAULT_EMAIL_FORM_VALUES,
                ...emailToFomData(adjustEmail(selectedTemplate)),
            });

            messageRef?.current?.focus();
        }
    }, [selectedTemplate?.id]);

    useEffect(() => {
        if (!to && !originalEmail) {
            toRef?.current?.focus();
        } else {
            messageRef?.current?.querySelector('.tiptap')?.focus();
        }
    }, [className]);

    const handleSubmit = (values: any) => onSubmit?.(formDataToEmail(values));
    const attachments = sourceFile ? [sourceFile] : [];

    return (
        <div className={classnames('p-7', className)}>
            <form onSubmit={form.handleSubmit(handleSubmit)} noValidate className="flex flex-col gap-5">
                <EmailFormFields
                    form={form}
                    originalEmail={originalEmail}
                    attachments={attachments}
                    toRef={toRef}
                    messageRef={messageRef}
                />

                <div className="flex gap-2 justify-between">
                    <Button type="button" onClick={handleCreateTemplate}>
                        {t('emailsView.emailForm.saveAsTemplateButton')}
                    </Button>

                    <div className="flex gap-2 justify-end">
                        <Button type="button" onClick={onCancel}>
                            {t('emailsView.emailForm.cancelButton')}
                        </Button>

                        <Button
                            className="border-transparent bg-brand-hover hover:bg-brand-default active:bg-brand-active text-inverted flex items-center gap-2"
                            type="submit"
                        >
                            <SendIcon /> {t('emailsView.emailForm.sendButton')}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
};

const Template = ({
    isCreate = false,
    onCreateCancel,
    template,
    onSelect,
    onSubmit,
    onDelete,
}: {
    isCreate?: boolean;
    onCreateCancel?: () => any;
    template: EmailTemplate;
    onSelect?: () => any;
    onSubmit?: (values: any) => any;
    onDelete?: () => any;
}) => {
    const { t } = useTranslation('assistance');

    const { content, attachments } = useDocumentEmailBody(template);
    const isDarkMode = !!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY);

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const form = useForm({ defaultValues: DEFAULT_TEMPLATE_FORM_VALUES, mode: 'onBlur' });
    const formErrors = form.formState.errors;

    useEffect(() => {
        form.reset({
            ...DEFAULT_TEMPLATE_FORM_VALUES,
            ...emailToFomData(adjustEmail(template)),
        });
    }, [template, isEditing]);

    const handleSubmit = async (values: any) => {
        await Promise.resolve(onSubmit?.(values));
        setIsEditing(false);
    };

    return (
        <>
            <div className="flex flex-col border border-solid border-secondary rounded-md overflow-hidden">
                <div className="px-5 pr-3 py-3 text-primary flex justify-between items-center group">
                    <div className="p-2" onClick={() => setIsCollapsed(!isCollapsed)}>
                        {template?.templateName || t('emailsView.emailForm.untitledTemplate')}
                    </div>
                    {!isCreate && (
                        <div className="flex justify-between items-center">
                            <div
                                className={classnames(
                                    'flex justify-between items-center',
                                    isCollapsed && 'hidden group-hover:flex'
                                )}
                            >
                                <Tooltip content={t('emailsView.emailForm.selectTooltip')} placement="top">
                                    <Button variant="ghost" onClick={onSelect} disabled={isEditing}>
                                        <FillInIcon />
                                    </Button>
                                </Tooltip>

                                <div className="w-0 h-7 border-r border-solid border-primary mx-2" />

                                <Tooltip content={t('emailsView.emailForm.editTooltip')} placement="top">
                                    <Button
                                        variant="ghost"
                                        onClick={() => {
                                            setIsEditing(true);
                                            setIsCollapsed(false);
                                        }}
                                        disabled={isEditing}
                                        active={isEditing}
                                    >
                                        <EditIcon />
                                    </Button>
                                </Tooltip>

                                <Tooltip content={t('emailsView.emailForm.deleteTooltip')} placement="top">
                                    <Button
                                        variant="ghost"
                                        onClick={() => setDeleteModalVisible(true)}
                                        className="text-error hover:bg-error"
                                        disabled={isEditing}
                                    >
                                        <RemoveIcon />
                                    </Button>
                                </Tooltip>

                                <div className="w-0 h-7 border-r border-solid border-primary mx-2" />
                            </div>

                            <Button variant="ghost" onClick={() => setIsCollapsed(!isCollapsed)} disabled={isEditing}>
                                {isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
                            </Button>
                        </div>
                    )}
                </div>

                {(!isCollapsed || isCreate) && (
                    <div className="bg-secondary-light p-7 flex flex-col gap-6">
                        {isEditing || isCreate ? (
                            <form onSubmit={form.handleSubmit(handleSubmit)} noValidate className="flex flex-col gap-5">
                                <Controller
                                    name="templateName"
                                    control={form.control}
                                    rules={{ required: t('emailsView.emailForm.templateNameRequired') }}
                                    render={({ field }) => (
                                        <EmailFormField
                                            label={t('emailsView.emailForm.templateNameLabel')}
                                            required={true}
                                            error={formErrors.templateName}
                                        >
                                            <StringField required={true} {...field} />
                                        </EmailFormField>
                                    )}
                                />

                                <EmailFormFields form={form} attachments={[]} isTemplate />

                                <div className="flex gap-2 justify-end">
                                    <Button
                                        type="button"
                                        onClick={() => (isCreate ? onCreateCancel?.() : setIsEditing(false))}
                                    >
                                        {t('emailsView.emailForm.cancelButton')}
                                    </Button>

                                    <Button
                                        className="border-transparent bg-brand-hover hover:bg-brand-default active:bg-brand-active text-inverted flex items-center gap-2"
                                        type="submit"
                                    >
                                        {t('emailsView.emailForm.saveButton')}
                                    </Button>
                                </div>
                            </form>
                        ) : (
                            <>
                                <div className="flex flex-col gap-2 text-secondary">
                                    {template.receivers && template.receivers.length > 0 && (
                                        <span className="flex gap-1">
                                            <span className="flex-none w-9">{t('emailsView.email.to')}:</span>
                                            <span>{template.receivers.map((r) => r.email).join(', ')}</span>
                                        </span>
                                    )}
                                    {template.cc && template.cc.length > 0 && (
                                        <span className="flex gap-1">
                                            <span className="flex-none w-9">{t('emailsView.email.cc')}:</span>
                                            <span>{template.cc.map((r) => r.email).join(', ')}</span>
                                        </span>
                                    )}
                                    {template.bcc && template.bcc.length > 0 && (
                                        <span className="flex gap-1">
                                            <span className="flex-none w-9">{t('emailsView.email.bcc')}:</span>
                                            <span>{template.bcc.map((r) => r.email).join(', ')}</span>
                                        </span>
                                    )}
                                </div>

                                {template.subject && <div className="font-medium text-primary">{template.subject}</div>}

                                <div className="flex flex-col gap-4 leading-relaxed">
                                    <Frame>
                                        <style>
                                            {fontsStyle.replaceAll('../', '/')}
                                            {contentStyle}
                                        </style>
                                        <div
                                            className={classnames(
                                                'email__html',
                                                isDarkMode && 'email__html--dark-mode'
                                            )}
                                            dangerouslySetInnerHTML={{ __html: content }}
                                        />
                                    </Frame>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>

            <ConfirmModal
                title={t('emailsView.emailForm.deleteConfirmationTitle')}
                labelConfirm={t('emailsView.emailForm.deleteConfirmationButton')}
                labelCancel={t('emailsView.emailForm.deleteConfirmationCancelButton')}
                onConfirm={() => {
                    setDeleteModalVisible(false);
                    return onDelete?.();
                }}
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                danger
            >
                <p>{t('emailsView.emailForm.deleteConfirmationDescription')}</p>
            </ConfirmModal>
        </>
    );
};

const TemplatesSection = ({
    className,
    templates,
    refetchTemplates,
    createTemplateData,
    onCreateTemplateDataChange,
    onSelectTemplate,
}: {
    className?: string;
    templates: any;
    refetchTemplates: any;
    createTemplateData?: any;
    onCreateTemplateDataChange: (data: any) => any;
    onSelectTemplate: (template: EmailTemplate) => any;
}) => {
    const { t } = useTranslation('assistance');

    const { publishToast } = useToaster();

    const [createEmailTemplate] = useMutation(CREATE_EMAIL_TEMPLATE);
    const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE);
    const [deleteEmailTemplate] = useMutation(DELETE_EMAIL_TEMPLATE);

    const formDataToTemplate = (values: any) => ({
        receivers: splitEmailAddresses(values.to),
        cc: splitEmailAddresses(values.cc),
        bcc: splitEmailAddresses(values.bcc),
        subject: values.subject,
        body: values.message,
        mimetype: containsHtml(values.message) ? 'text/html' : 'text/plain',
    });

    const handleCreateTemplate = async (values: any = {}) => {
        const { templateName, ...formValues } = values;
        await createEmailTemplate({
            variables: {
                templateName: templateName,
                templateData: formDataToTemplate(formValues),
            },
        });

        await refetchTemplates();

        onCreateTemplateDataChange(null);

        publishToast({
            title: t('emailsView.emailForm.templateCreateSuccessTitle'),
            description: t('emailsView.emailForm.templateCreateSuccessDescription'),
        });
    };

    const handleUpdateTemplate = async (templateId: string, values: any = {}) => {
        const { templateName, ...formValues } = values;
        await updateEmailTemplate({
            variables: {
                templateId: templateId,
                templateName: templateName,
                templateData: formDataToTemplate(formValues),
            },
        });

        await refetchTemplates();

        publishToast({
            title: t('emailsView.emailForm.templateUpdateSuccessTitle'),
            description: t('emailsView.emailForm.templateUpdateSuccessDescription'),
        });
    };

    const handleDeleteTemplate = async (templateId: string) => {
        await deleteEmailTemplate({
            variables: {
                templateId,
            },
        });

        await refetchTemplates();

        publishToast({
            title: t('emailsView.emailForm.templateDeleteSuccessTitle'),
            description: t('emailsView.emailForm.templateDeleteSuccessDescription'),
        });
    };

    const handleSelectTemplate = (template: EmailTemplate) => {
        onSelectTemplate(template);
    };

    return (
        <div className={classnames('p-7 flex flex-col gap-5 min-h-[32rem] overflow-auto', className)}>
            {createTemplateData && (
                <Template
                    isCreate
                    template={createTemplateData}
                    onSubmit={handleCreateTemplate}
                    onCreateCancel={() => {
                        onCreateTemplateDataChange(null);
                    }}
                />
            )}

            {templates.map((template, index) => (
                <Template
                    key={index}
                    template={adjustEmail(template)}
                    onSelect={() => handleSelectTemplate(template)}
                    onSubmit={(values) => handleUpdateTemplate(template?.id, values)}
                    onDelete={() => handleDeleteTemplate(template?.id)}
                />
            ))}

            {templates && templates.length === 0 && !createTemplateData && (
                <div className="flex flex-col text-secondary items-center justify-center flex-1 p-5 gap-5">
                    <span className="block text-center">{t('emailsView.emailForm.noTemplates')}</span>
                    <Button onClick={() => onCreateTemplateDataChange({})}>
                        {t('emailsView.emailForm.createTemplateButton')}
                    </Button>
                </div>
            )}
        </div>
    );
};

const EmailEditorTabButton = ({
    className,
    active = false,
    ...props
}: { active?: boolean } & React.ComponentPropsWithRef<'button'>) => {
    return (
        <button
            className={classnames(
                'flex flex-1 gap-2 items-center justify-center text-sm font-medium text-primary border-b-2 border-transparent text-center p-4 hover:border-brand hover:text-brand transition-colors -mb-px',
                active && 'border-brand text-brand',
                className
            )}
            {...props}
        />
    );
};

const EmailEditor = ({ record, originalEmail, sourceFile, onSubmit, onCancel }: any) => {
    const { t } = useTranslation('assistance');

    const [activeTab, setActiveTab] = useState<'email' | 'templates'>('email');

    const { user } = useApplicationContext();
    const userEmailAddress = { name: `${user.firstName} ${user.lastName}`, email: user.email };

    const { data: templatesData, refetch: refetchTemplates } = useQuery(GET_EMAIL_TEMPLATES, {
        variables: {
            recordId: record?.id,
        },
        skip: !record?.id,
    });

    const templates = templatesData?.orderDocumentEmails?.templates || [];

    const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | null>(null);
    const [createTemplateData, setCreateTemplateData] = useState<EmailTemplate | null>(null);

    return (
        <div className="flex gap-3">
            <EmailAvatar emailAddress={userEmailAddress} />

            <div className="flex flex-col gap-4 flex-1">
                <div className="flex flex-col gap-2">
                    <div className="flex gap-1 items-center">
                        <span className="font-medium text-primary">{userEmailAddress.name}</span>
                        <span className="text-secondary text-sm">&lt;{userEmailAddress.email}&gt;</span>
                    </div>
                </div>

                <div className="rounded-xl flex flex-col border border-primary border-solid bg-primary">
                    <div className="flex border-b border-solid border-primary px-7">
                        <EmailEditorTabButton active={activeTab === 'email'} onClick={() => setActiveTab('email')}>
                            {t('emailsView.emailForm.emailTabButton')}
                        </EmailEditorTabButton>
                        <EmailEditorTabButton
                            active={activeTab === 'templates'}
                            onClick={() => setActiveTab('templates')}
                        >
                            {t('emailsView.emailForm.templatesTabButton')}{' '}
                            {templates.length > 0 && `(${templates.length})`}
                        </EmailEditorTabButton>
                    </div>

                    <EmailSection
                        className={classnames(activeTab !== 'email' && 'hidden')}
                        originalEmail={originalEmail}
                        sourceFile={sourceFile}
                        onSubmit={onSubmit}
                        onCreateTemplate={(data) => {
                            setCreateTemplateData(data);
                            setActiveTab('templates');
                        }}
                        selectedTemplate={selectedTemplate}
                        onCancel={onCancel}
                    />

                    <TemplatesSection
                        className={classnames(activeTab !== 'templates' && 'hidden')}
                        templates={templates}
                        refetchTemplates={refetchTemplates}
                        createTemplateData={createTemplateData}
                        onCreateTemplateDataChange={setCreateTemplateData}
                        onSelectTemplate={(template) => {
                            setSelectedTemplate(template);
                            setActiveTab('email');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default EmailEditor;
