import * as React from 'react';
import classnames from '../utils/classnames';
import { useControllableState } from '../utils/useControllableState';
import { Slot } from '@radix-ui/react-slot';

interface BaseButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    loading?: boolean;
    asChild?: boolean;
}

export const BaseButton = React.forwardRef<React.ElementRef<'button'>, BaseButtonProps>(
    ({ className, loading, asChild, onClick, disabled, ...props }, ref) => {
        const [isLoading, setIsLoading] = useControllableState(false, loading);

        const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            setIsLoading(true);
            Promise.resolve(onClick?.(e)).finally(() => setIsLoading(false));
        };

        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                ref={ref}
                className={classnames((disabled || isLoading) && 'opacity-50 cursor-not-allowed', className)}
                onClick={handleClick}
                disabled={disabled || isLoading}
                {...props}
            />
        );
    }
);

const Button = React.forwardRef<
    React.ElementRef<'button'>,
    BaseButtonProps & {
        active?: boolean;
        variant?: 'outline' | 'ghost' | 'primary';
    }
>(({ className, active, variant = 'outline', ...props }, ref) => {
    return (
        <BaseButton
            ref={ref}
            className={classnames(
                'bg-primary text-primary rounded px-3 py-1.5 font-medium text-sm border border-solid outline-none',
                'hover:bg-secondary transition-all',
                'data-[state=open]:bg-brand data-[state=open]:text-brand',
                variant === 'outline' && 'bg-primary border-secondary shadow-sm data-[state=open]:border-brand',
                active && variant === 'outline' && '!border-brand',
                variant === 'ghost' && 'bg-transparent border-transparent',
                active && '!bg-brand !text-brand',
                variant === 'primary' &&
                    'bg-brand-default text-inverted hover:enabled:!bg-brand-hover border-transparent focus:enabled:!bg-brand-focus active:enabled:!bg-brand-active disabled:opacity-50',
                className
            )}
            {...props}
        />
    );
});

export default Button;
