import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import {
    faArrowUpRightFromSquare,
    faChevronDown,
    faCircleCheck,
    faDownLeftAndUpRightToCenter,
    faDownload,
    faFilePen,
    faGear,
    faTimes,
    faTriangleExclamation,
    faUpRightAndDownLeftFromCenter,
} from '@fortawesome/pro-regular-svg-icons';
import { faDatabaseOutlined } from './custom-icons';
import { IconPack } from '@fortawesome/fontawesome-svg-core';

import './style.scss';

// Explicitly import & register icons here to allow tree-shaking of unused assets from the icon library
const iconLibrary: IconPack = {
    settings: faGear,
    warning: faTriangleExclamation,
    arrowDown: faChevronDown,
    checkmark: faCircleCheck,
    fileDownload: faDownload,
    openExternalUrl: faArrowUpRightFromSquare,
    editDocument: faFilePen,
    close: faTimes,
    maximizeWindow: faUpRightAndDownLeftFromCenter,
    minimizeWindow: faDownLeftAndUpRightToCenter,
};
const customIconLibrary = {
    database: faDatabaseOutlined,
};

export interface IconProps {
    icon: string;
    onClick?: () => void;
}

export const Icon = ({ icon, onClick, ...props }: FontAwesomeIconProps | IconProps) => {
    let faIcon;

    if (typeof icon === 'string') {
        const iconString = icon;
        faIcon = iconLibrary[icon];

        if (!faIcon && customIconLibrary.hasOwnProperty(icon)) {
            const CustomIcon = customIconLibrary[icon];
            return (
                <CustomIcon
                    className={classnames('icon', onClick && 'icon--onclick-action')}
                    onClick={onClick}
                    {...props}
                />
            );
        }

        if (faIcon == null) {
            console.warn(`Couldn't find icon '${iconString}', make sure to add it to the icon library first.`);
            faIcon = iconLibrary['warning'];
        }
    } else {
        faIcon = icon;
    }

    return (
        <FontAwesomeIcon
            className={classnames('icon', onClick && 'icon--onclick-action')}
            icon={faIcon}
            onClick={onClick}
            {...props}
        />
    );
};

export default Icon;
