import { useEffect, useRef, DependencyList, EffectCallback } from 'react';

/**
 * Custom hook that runs an effect only when dependencies change, skipping the initial mount.
 * @param effect The effect callback to run
 * @param dependencies Array of dependencies that trigger the effect
 */
export const useUpdateEffect = (effect: EffectCallback, dependencies: DependencyList): void => {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        return effect();
    }, dependencies);
};

export default useUpdateEffect;
