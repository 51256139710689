import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

export const typeOfComponent = (component) =>
    component?.displayName ||
    component?.type?.displayName ||
    component?.type?.name ||
    component?.props?.__TYPE ||
    component?.type?.toString().replace('Symbol(react.fragment)', 'react.fragment') ||
    undefined;

export const getChildrenByType = (children, types) =>
    flattenChildren(children).filter((child) => types.indexOf(typeOfComponent(child)) !== -1);
