import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import ConfigurationCard from '../ConfigurationCard';
import Tab from '../../../core/components/Tab';
import Tabs from '../../../core/components/Tabs';
import { UniversalFieldsNavHeader } from './FieldsNav';
import FieldsNav, { useFieldsNav } from '../DocumentConfig/FieldsNav';
import {
    UniversalAddFieldConfigModal,
    UniversalFormField,
    universalGetInputControlComponent,
    UniversalTabMoreButton,
} from './FieldsForm';
import FieldsForm from '../DocumentConfig/FieldsForm';
import {
    getHeaderFields,
    convertHeaderFormData,
    useFieldList,
    convertLineItemFormData,
    getLineItemFields,
} from '../DocumentConfig';
import { UPDATE_FIELDS_CONFIG } from './queries';
import { useForm } from 'react-hook-form';
import ProgressButton from '../../../core/containers/ProgressButton';
import EmptyState from './EmptyState';
import '../DocumentConfig/style.scss';
import './FieldsForm.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

const ErrorIcon = withIcon(faExclamationCircle);

const getDefaultUniversalOptionsValues = () => {
    return [
        {
            name: 'is_required',
            valueType: 'boolean',
            defaultValue: true,
        },
        {
            name: 'data_label_name',
            valueType: 'string',
            defaultValue: null,
        },
        {
            name: 'data_type',
            valueType: 'string',
            defaultValue: 'string',
        },
        {
            name: 'field_label_name',
            valueType: 'string',
            defaultValue: null,
        },
        {
            name: 'data_label_instructions',
            valueType: 'string',
            defaultValue: null,
        },
    ];
};

const UniversalTab = ({ form, formName, fields, setTabSortedFields, setNumberErrorMessages }) => {
    const { t } = useTranslation('config');

    const translationPrefix = 'assistance:headerView.fieldNames';

    const addUniversalField = ({ form, formName, configuredFieldNames, fieldName }) => {
        const fieldNameToAdd = 'untitled' + configuredFieldNames.length;
        const defaultOptionsValues = getDefaultUniversalOptionsValues();
        form.setValue(
            `${formName}.${fieldNameToAdd}.${fieldNameToAdd}`,
            Object.fromEntries(defaultOptionsValues.map((option) => [option.name, option.defaultValue]))
        );
        return [fieldNameToAdd];
    };

    const getConfiguredFields = ({ fieldsWithConfigurableOptions, configuredFieldNames }) => {
        const unsavedFieldForm = {
            enabled: true,
            options: getDefaultUniversalOptionsValues(),
        };

        return configuredFieldNames.map((name) => {
            const field = fieldsWithConfigurableOptions.find((field) => field.name === name);
            return field || { ...unsavedFieldForm, name }; // Provide the default field form if not found, preserving the name
        });
    };

    const {
        availableFields,
        configuredFields,
        addField,
        deleteField,
        setFieldsSort,
        fieldsSort,
        defaultValues,
        formSections,
    } = useFieldList({
        form,
        formName,
        fields,
        translationPrefix,
        getConfiguredFields,
        onCustomAddField: addUniversalField,
    });
    useEffect(() => {
        setTabSortedFields(formName, fieldsSort);
    }, [fieldsSort]);

    const formFields = form.watch(formName) || [];

    const nav = useFieldsNav({
        configuredFields,
        availableFields,
        formFields,
        onSortChange: setFieldsSort,
        fieldsSort,
        addField,
        deleteField,
        translationPrefix,
    });
    const fieldsFormRef = useRef(null);
    const activeField = nav.fields.find((item) => item.name === nav.activeField);

    const hasConfiguredFields = Object.keys(formSections).length > 0;

    const obtainFieldNamesWithErrors = () => {
        const fieldsWithEmptyValues = [];
        const dataLabelNameMap = {};

        nav.fieldNames.forEach((fieldName) => {
            const field = formFields?.[fieldName]?.[fieldName];

            if (
                !field ||
                field.field_label_name === '' ||
                field.data_label_name === '' ||
                field.data_label_instructions === '' ||
                field.data_type === ''
            ) {
                fieldsWithEmptyValues.push(fieldName);
            }

            // Create mapping where key is the data_label_name and the value is a list
            // containing all field names with that name
            if (field && field.data_label_name) {
                if (!dataLabelNameMap[field.data_label_name]) {
                    dataLabelNameMap[field.data_label_name] = [];
                }
                dataLabelNameMap[field.data_label_name].push(fieldName);
            }
        });

        // Get only the field names with duplicated data_label_name
        const fieldsWithDuplicatedNames = Object.values(dataLabelNameMap)
            .filter((fieldNamesList) => Array.isArray(fieldNamesList) && fieldNamesList.length > 1)
            .flat();

        return [fieldsWithEmptyValues, fieldsWithDuplicatedNames];
    };

    const [fieldsWithEmptyValues, fieldsWithDuplicatedNames] = obtainFieldNamesWithErrors();
    const fieldsWithErrors = Array.from(new Set([...fieldsWithEmptyValues, ...fieldsWithDuplicatedNames]));
    setNumberErrorMessages(fieldsWithErrors.length);

    const obtainFieldName = (fieldName, subfieldName = undefined) => {
        const formField = formFields?.[fieldName];
        const field = formField?.[fieldName];
        return field?.field_label_name || t(`documentConfig.fieldNames.untitled`);
    };

    // Modify the names for the fields shown on the left panel and set the errorIcon accordingly
    nav.fields.forEach((field) => {
        field.label = obtainFieldName(field.name);
        field['errorIcon'] = fieldsWithErrors.includes(field.name);
    });

    return (
        <div className="fields-config">
            <div className={hasConfiguredFields ? 'fields-config__left' : 'fields-config__empty'}>
                <UniversalFieldsNavHeader onAddField={nav.onAddField} />
                <FieldsNav fields={nav.fields} fieldsFormRef={fieldsFormRef} />
                {!hasConfiguredFields && <EmptyState onAddField={nav.onAddField} />}
            </div>

            {hasConfiguredFields && (
                <div className="fields-config__right">
                    <div className="fields-config__header">
                        <h2 className="fields-config__title">
                            {nav.activeField ? t('documentConfig.formHeader', { field: activeField?.label }) : ''}
                        </h2>
                    </div>

                    <FieldsForm
                        key={`fields-form-${nav.activeField}`}
                        form={form}
                        formName={formName}
                        fieldName={nav.activeField}
                        formSections={formSections[nav.activeField]}
                        defaultValues={defaultValues[nav.activeField]}
                        hideFirstSectionLabel={true}
                        ref={fieldsFormRef}
                        duplicatedFieldName={fieldsWithDuplicatedNames.includes(nav.activeField)}
                        defaultGetInputControlComponent={universalGetInputControlComponent}
                        FormFieldComponent={UniversalFormField}
                    />
                </div>
            )}
        </div>
    );
};

export const UniversalDocumentConfig = ({ documentTypeName, config, refetch, channelId }) => {
    const { t } = useTranslation('config');

    const [tabs, setTabs] = useState({});
    const [activeTab, setActiveTab] = useState('header');
    const [submitMessage, setSubmitMessage] = useState(null);
    const [numberErrorMessages, setNumberErrorMessages] = useState(0);
    const [addTableModalVisible, setAddTableModalVisible] = useState(false);
    const [tableModalFieldsValues, setTableModalFieldsValues] = useState({});

    const [updateDocumentConfig, { error, loading }] = useMutation(UPDATE_FIELDS_CONFIG);
    const handleSubmit = (values) => {
        const fieldConfigs = [];
        fieldConfigs.push(...convertHeaderFormData(values['header'], tabs['header'].sortedFields));
        for (let tab in tabs) {
            if (tab === 'header') {
                continue;
            }
            const tabOptions = [
                { name: 'data_label_name', value: tab },
                { name: 'field_label_name', value: tabs[tab].label },
                { name: 'group', value: true },
            ];
            fieldConfigs.push({
                fieldName: tab,
                position: Object.keys(tabs).indexOf(tab),
                options: tabOptions,
            });
            fieldConfigs.push(...convertLineItemFormData(values[tab], tabs[tab].sortedFields, tab));
        }

        return updateDocumentConfig({
            variables: {
                channelId,
                documentType: documentTypeName,
                documentConfig: null,
                fieldConfigs,
            },
        })
            .then(() => {
                setSubmitMessage(t('documentConfig.submitMessage.success'));
                setTimeout(() => {
                    setSubmitMessage(null);
                }, 2000);
            })
            .catch(() => {
                setSubmitMessage(t('documentConfig.submitMessage.error'));
                setTimeout(() => {
                    setSubmitMessage(null);
                }, 2000);
            })
            .then(() => refetch());
    };

    const form = useForm({});
    const headerLabel = t('documentConfig.tabs.headerFields');

    const itemTypeDefinitions = useMemo(
        () =>
            (config?.fields || []).filter((field) =>
                field.options?.some((option) => option.name === 'group' && option.value === true)
            ),
        [config?.fields]
    );
    const removeItemTypes = (fields) => {
        return (fields || []).filter((field) => !itemTypeDefinitions.map((f) => f.name).includes(field.name));
    };
    const headerFields = getHeaderFields(removeItemTypes(config?.fields));

    const universalTabs = { header: { fields: headerFields, label: headerLabel } };

    for (const userManagedTab of itemTypeDefinitions) {
        const fieldLabelName = userManagedTab.options.find((option) => option.name === 'field_label_name')?.value;
        const lineItemsFields = getLineItemFields(config?.fields || [], userManagedTab.name);
        universalTabs[userManagedTab.name] = { fields: lineItemsFields, label: fieldLabelName };
    }

    useEffect(() => {
        setTabs(universalTabs);
    }, [config?.fields]);

    const setTabSortedFields = (tabId, sortedFields) => {
        const newTabs = { ...tabs };
        newTabs[tabId].sortedFields = sortedFields;
        setTabs(newTabs);
    };

    const addOrEditTable = (tableConfig, isAddTable = true) => {
        const tabId = tableConfig?.table_data_label_name ?? '';
        const tabLabel = tableConfig?.table_field_label_name ?? '';

        setTabs((prev) => {
            const newTabs = { ...prev };

            if (isAddTable) {
                newTabs[tabId] = { label: tabLabel, fields: [] };
            } else {
                newTabs[tabId].label = tabLabel;
            }

            return newTabs;
        });

        setActiveTab(tabId);
    };

    const deleteTable = (tabId) => {
        form.unregister(tabId);

        setTabs((prevTabs) => {
            const newTabs = { ...prevTabs };
            delete newTabs[tabId];
            return newTabs;
        });

        // If the active tab is removed, reset the active tab to null or the first tab
        if (activeTab === tabId) {
            const remainingTabIds = Object.keys(tabs).filter((id) => id !== tabId);
            setActiveTab(remainingTabIds[0]);
        }
    };

    const onAddTable = (tabId = undefined) => {
        setAddTableModalVisible(true);
        setTableModalFieldsValues({});
    };

    const onEditTable = (tabId) => {
        setAddTableModalVisible(true);
        const initialValues = {
            table_data_label_name: tabId,
            table_field_label_name: tabs[tabId].label,
        };
        setTableModalFieldsValues(initialValues);
    };

    const errorMessage = t('documentConfig.error_message', {
        count: numberErrorMessages,
        num_errors: numberErrorMessages,
    });

    return (
        <ConfigurationCard
            title={t('documentConfig.title')}
            description={t('documentConfig.description')}
            active={!!config}
            className="configuration-card--no-padding-body"
        >
            <div className="fields-tabs">
                <Tabs>
                    {Object.keys(tabs).map((tab) => (
                        <Tab
                            key={tab}
                            label={tabs[tab].label}
                            onClick={() => setActiveTab(tab)}
                            active={activeTab === tab}
                            extraComponent={
                                tab != 'header' && (
                                    <UniversalTabMoreButton
                                        tableId={tab}
                                        tableName={tabs[tab].label}
                                        onDeleteTable={deleteTable}
                                        onEditTable={onEditTable}
                                    ></UniversalTabMoreButton>
                                )
                            }
                        />
                    ))}
                    <div>
                        {Object.keys(tabs).length <= 2 && (
                            <div
                                className="tab action-add-section"
                                onClick={(event) => {
                                    onAddTable();
                                }}
                            >
                                <span>{t('documentConfig.tabs.addTableDataSection')}</span>
                            </div>
                        )}
                    </div>
                </Tabs>

                {addTableModalVisible && (
                    <UniversalAddFieldConfigModal
                        tabs={Object.keys(tabs)}
                        onAddOrEditTable={addOrEditTable}
                        setAddTableModalVisible={setAddTableModalVisible}
                        initialFieldsValues={tableModalFieldsValues}
                    />
                )}
            </div>

            <form onSubmit={form.handleSubmit(handleSubmit)}>
                {Object.keys(tabs).map((tab) => (
                    <div className={tab === activeTab ? '' : 'hidden'}>
                        <UniversalTab
                            form={form}
                            formName={tab}
                            fields={tabs[tab].fields}
                            setTabSortedFields={setTabSortedFields}
                            setNumberErrorMessages={setNumberErrorMessages}
                        />
                    </div>
                ))}
                <div className="fields-form-footer">
                    {submitMessage && <span className="fields-form-footer__message">{submitMessage}</span>}
                    {numberErrorMessages > 0 && (
                        <span className="fields-form-footer__error_message">
                            <ErrorIcon className="fields-form-footer__error_icon" />
                            {errorMessage}
                        </span>
                    )}
                    <ProgressButton
                        type="submit"
                        label={t('documentConfig.submitButton')}
                        inProgress={loading}
                        disabled={!!numberErrorMessages}
                    />
                </div>
            </form>
        </ConfigurationCard>
    );
};
