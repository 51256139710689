import { withIcon } from './Icon.tsx';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import classnames from '../utils/classnames.tsx';

const CheckIcon = withIcon(faCheck);

const Checkbox = ({ checked, disabled, className, ...props }: React.ComponentPropsWithRef<'input'>) => {
    return (
        <div
            data-disabled={disabled ? 'disabled' : undefined}
            data-state={checked ? 'checked' : 'unchecked'}
            className={classnames(
                'relative h-5 w-5 flex items-center justify-center cursor-default select-none rounded text-sm text-primary transition-colors shadow-inner-sm focus-within:border-brand hover:border-brand border-primary border-solid border data-[disabled]:pointer-events-none data-[disabled]:opacity-50 bg-primary data-[state=checked]:bg-brand-active data-[state=checked]:text-inverted data-[state=checked]:border-none',
                className
            )}
        >
            <input
                type="checkbox"
                disabled={disabled}
                checked={checked}
                {...props}
                className="inset-0 absolute opacity-0"
            />
            {checked && <CheckIcon />}
        </div>
    );
};

export default Checkbox;
