import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import classnames from '../../../core_updated/utils/classnames';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../components/DropdownMenu';
import { SecondaryButton } from '../../../core/components/Button';

import './FieldsNav.scss';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import { faExclamationCircle, faGripDotsVertical, faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';

const DRAGGABLE_ITEM_TYPE = 'ITEM';

const ErrorIcon = withIcon(faExclamationCircle);
const DragIcon = withIcon(faGripDotsVertical);
const AddIcon = withIcon(faPlus);
const RemoveIcon = withIcon(faXmark);

export const useFieldsNav = ({
    availableFields,
    configuredFields,
    formFields,
    addField,
    deleteField,
    translationPrefix,
    fieldsSort = [],
    onSortChange,
}) => {
    const { t } = useTranslation('config');

    const [activeField, setActiveField] = useState(null);
    const [unsavedFieldsSort, setUnsavedFieldsSort] = useState([]);
    useEffect(() => {
        setUnsavedFieldsSort(fieldsSort);
        if (!activeField || !configuredFields.find((f) => f.name === activeField)) {
            // if the active field is not in the list of configured fields, select the first one
            setActiveField(fieldsSort[0]);
        }
    }, [fieldsSort]);

    const fieldAndSubfieldNames = {};
    configuredFields?.forEach((field) => {
        // fill a look-up object with all subfield names and their parent field
        const [parent, child] = field.name.split('__', 2);
        if (!fieldAndSubfieldNames[parent]) {
            fieldAndSubfieldNames[parent] = [];
        }
        if (child) {
            fieldAndSubfieldNames[parent].push(child);
        }
    });

    const handleFieldDrop = (item) => {
        setActiveField(item.name);
        onSortChange(unsavedFieldsSort);
    };

    const handleFieldMove = (dragIndex, hoverIndex) => {
        // resort item from fieldIndices based on dragIndex and hoverIndex
        setUnsavedFieldsSort((prev) => {
            const newIndices = [...prev];
            newIndices.splice(hoverIndex, 0, newIndices.splice(dragIndex, 1)[0]);
            return newIndices;
        });
    };

    const handleFieldDragStart = (item) => {
        setActiveField(item.name);
    };

    const handleAddField = (fieldName) => {
        addField(fieldName);
        setActiveField(fieldName);
    };

    const handleAddSubfield = (fieldName) => (subfield) => {
        addField(`${fieldName}__${subfield}`);
        // set parent field as active
        setActiveField(fieldName);
    };

    const fieldNames = Object.keys(fieldAndSubfieldNames);
    fieldNames.sort((a, b) => unsavedFieldsSort.indexOf(a) - unsavedFieldsSort.indexOf(b));

    const addableFields = availableFields
        .filter((field) => !field.name.includes('__') && !configuredFields.find((f) => f.name === field.name))
        .map((field) => ({
            label: t(`${translationPrefix}.${field.name}`),
            name: field.name,
        }));
    addableFields.sort((a, b) => a.label.localeCompare(b.label));

    const isFieldRequired = (fieldName, subfieldName = undefined) => {
        const fullName = subfieldName ? `${fieldName}__${subfieldName}` : fieldName;
        const formField = formFields?.[fieldName];
        if (!formField) return true;

        const field = formField[fullName];
        return field?.is_required === true;
    };

    return {
        activeField,

        onAddField: handleAddField,
        addableFields,

        fieldsSort: unsavedFieldsSort,
        fieldNames,
        fields: fieldNames.map((fieldName) => ({
            name: fieldName,
            label: t(`${translationPrefix}.${fieldName}`),
            note: configuredFields.find((f) => f.name.startsWith(`${fieldName}__`))
                ? t(`documentConfig.fieldNotes.subfields`, {
                      count: fieldAndSubfieldNames[fieldName].length,
                  })
                : isFieldRequired(fieldName)
                  ? t('documentConfig.fieldNotes.required')
                  : t('documentConfig.fieldNotes.optional'),
            index: unsavedFieldsSort.indexOf(fieldName),
            active: activeField === fieldName,
            onDrop: handleFieldDrop,
            onMove: handleFieldMove,
            onDragStart: handleFieldDragStart,
            onClick: () => setActiveField(fieldName),
            onDelete: () => deleteField(fieldName),

            onAddSubfield: handleAddSubfield(fieldName),
            addableSubfields: availableFields
                .filter(
                    (f) =>
                        f.name.startsWith(`${fieldName}__`) &&
                        !fieldAndSubfieldNames[fieldName].includes(f.name.split('__', 2)[1])
                )
                .map((f) => {
                    const [parent, child] = f.name.split('__', 2);
                    return {
                        name: child,
                        label: t(`${translationPrefix}.${parent}__${child}`),
                    };
                })
                .sort((a, b) => a.label.localeCompare(b.label)),

            subfields: fieldAndSubfieldNames[fieldName]
                .map((subfieldName) => ({
                    name: `${fieldName}__${subfieldName}`,
                    label: t(`${translationPrefix}.${fieldName}__${subfieldName}`),
                    note: isFieldRequired(fieldName, subfieldName)
                        ? t('documentConfig.fieldNotes.required')
                        : t('documentConfig.fieldNotes.optional'),

                    index: unsavedFieldsSort.indexOf(`${fieldName}__${subfieldName}`),

                    onDrop: handleFieldDrop,
                    onMove: handleFieldMove,
                    onDragStart: handleFieldDragStart,

                    onDelete: () => deleteField(fieldName, subfieldName),
                }))
                // sort by name in unsavedFieldsSort order
                .sort((a, b) => {
                    return unsavedFieldsSort.indexOf(a.name) - unsavedFieldsSort.indexOf(b.name);
                }),
        })),
    };
};

export const FieldsNavHeader = ({ addableFields, onAddField }) => {
    const { t } = useTranslation('config');

    return (
        <div className="fields-config__header">
            <h2 className="fields-config__title">{t('documentConfig.configuredFields')}</h2>
            <div className="fields-config__actions">
                <DropdownMenu className="fields-dropdown">
                    <DropdownMenu.Trigger>
                        <SecondaryButton
                            label={<AddIcon />}
                            className="button--icon button--small"
                            disabled={addableFields === undefined || addableFields.length === 0}
                        />
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content align="start" className="fields-dropdown__content">
                        {addableFields.map((field) => (
                            <DropdownMenu.Item
                                key={field.name}
                                onClick={(event) => {
                                    // close after selecting the last item
                                    if (addableFields.length > 1) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }
                                    onAddField(field.name);
                                }}
                            >
                                {field.label}
                            </DropdownMenu.Item>
                        ))}
                    </DropdownMenu.Content>
                </DropdownMenu>
            </div>
        </div>
    );
};

const FieldsNavItem = ({
    name: fieldName,
    label,
    note,
    active,
    index,
    errorIcon = undefined,

    subfields = undefined,
    addableSubfields = undefined,

    onClick,
    onDrop,
    onMove,
    onDragStart,
    onDelete,
    onAddSubfield,

    scrollToFormSection = undefined,
}) => {
    const dragRef = useRef(null);
    const previewRef = useRef(null);
    const itemRef = useRef(null);

    const [, drop] = useDrop({
        accept: DRAGGABLE_ITEM_TYPE,
        hover(item: any, monitor) {
            if (!dragRef.current || !previewRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = previewRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            onMove?.(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: DRAGGABLE_ITEM_TYPE,
        item: () => {
            const item = { index, name: fieldName };
            onDragStart?.(item);
            return item;
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            onDrop?.(item);
        },
    });

    drag(dragRef);
    drop(dragPreview(previewRef));
    drop(itemRef);

    return (
        <li
            className={classnames('fields-nav-item', {
                'fields-nav-item--active': active,
                'fields-nav-item--dragging': isDragging,
            })}
            ref={itemRef}
        >
            <span
                className="fields-nav-item__field"
                onClick={(event) => {
                    scrollToFormSection?.(fieldName);
                    onClick?.(event);
                }}
                ref={previewRef}
            >
                <span className="fields-nav-item__drag" ref={dragRef}>
                    <DragIcon />
                </span>

                <a className="fields-nav-item__title-group">
                    <span className="fields-nav-item__title">{label}</span>
                    <span className="fields-nav-item__subline">{note}</span>
                </a>

                <div className="fields-nav-item__actions-main">
                    {errorIcon && <ErrorIcon className="fields-nav-item__error-icon" />}

                    <div className="fields-nav-item__actions">
                        {addableSubfields?.length ? (
                            <DropdownMenu className="fields-dropdown">
                                <DropdownMenu.Trigger>
                                    <SecondaryButton
                                        label={<AddIcon />}
                                        className="button--icon fields-nav-item__button"
                                    />
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content align="start" className="fields-dropdown__content">
                                    {addableSubfields.map(({ name, label }) => (
                                        <DropdownMenu.Item
                                            key={name}
                                            onClick={(event) => {
                                                // close after selecting the last item
                                                if (addableSubfields.length > 1) {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }
                                                onAddSubfield(name);
                                            }}
                                        >
                                            {label}
                                        </DropdownMenu.Item>
                                    ))}
                                </DropdownMenu.Content>
                            </DropdownMenu>
                        ) : null}

                        <SecondaryButton
                            label={<RemoveIcon />}
                            className="button--icon fields-nav-item__button"
                            onClick={() => onDelete()}
                        />
                    </div>
                </div>
            </span>
            {!isDragging && subfields?.length && active ? (
                <ul>
                    {subfields.map(({ name, label, note, onDelete: onDeleteSubfield, ...props }) => {
                        return (
                            <FieldsNavSubItem
                                key={name}
                                name={name}
                                label={label}
                                note={note}
                                onDelete={() => onDeleteSubfield()}
                                scrollToFormSection={scrollToFormSection}
                                {...props}
                            />
                        );
                    })}
                </ul>
            ) : null}
        </li>
    );
};

const FieldsNavSubItem = ({
    index,
    name: fieldName,
    label,
    note,
    onDelete,
    scrollToFormSection = undefined,
    onMove,
    onDragStart,
    onDrop,
}: any) => {
    const dragRef = useRef(null);
    const itemRef = useRef(null);

    const groupName = fieldName.split('__')[0];
    const itemType = `${DRAGGABLE_ITEM_TYPE}_${groupName}`;

    const [, drop] = useDrop({
        accept: itemType,
        hover(item: any, monitor) {
            if (!dragRef.current || !itemRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = itemRef.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            onMove?.(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: itemType,
        item: () => {
            const item = { index, name: groupName };
            onDragStart?.(item);
            return item;
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            onDrop?.(item);
        },
    });

    drag(dragRef);
    drop(dragPreview(itemRef));
    drop(itemRef);

    return (
        <li
            className={classnames('fields-nav-item__subfield', {
                'fields-nav-item__subfield--dragging': isDragging,
            })}
            onClick={() => scrollToFormSection?.(fieldName)}
            ref={itemRef}
        >
            <span className="fields-nav-item__drag" ref={dragRef}>
                <DragIcon />
            </span>

            <a className="fields-nav-item__title-group">
                <span className="fields-nav-item__title">{label}</span>
                {note && <span className="fields-nav-item__subline">{note}</span>}
            </a>

            <div className="fields-nav-item__actions">
                <SecondaryButton
                    label={<RemoveIcon />}
                    className="button--icon fields-nav-item__button"
                    onClick={() => onDelete(fieldName)}
                />
            </div>
        </li>
    );
};

const FieldsNav = ({ fields, fieldsFormRef = undefined }) => {
    const scrollToFormSection = (name) => {
        const fieldsForm = fieldsFormRef?.current;
        const formSection = fieldsForm?.querySelector(`.js-section[data-name="${name}"]`);

        if (formSection) {
            formSection.parentNode.scrollTo({
                top: formSection.offsetTop - 30,
                behavior: 'smooth',
            });
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="fields-nav">
                <ul className="fields-nav__items">
                    {fields.map((field) => (
                        <FieldsNavItem key={field.name} {...field} scrollToFormSection={scrollToFormSection} />
                    ))}
                </ul>
            </div>
        </DndProvider>
    );
};

export default FieldsNav;
