import * as React from 'react';
import { useEffect, useState } from 'react';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import Editor from 'react-simple-code-editor';
import classnames from '../../../core_updated/utils/classnames';

import { IProps as InputProps } from '../../components/Input';

import './style.scss';

interface ISimpleProps extends InputProps {
    className?: string;
    value: string;
    setValue: any;
    invalidSyntaxError: string;
}

// tslint:disable-next-line:variable-name
export const SimpleJsonInput = ({ className, value, setValue, onBlur, ...props }: ISimpleProps) => {
    const [internalValue, setInternalValue] = useState(value);
    useEffect(() => setInternalValue(value), [value]);

    const [isValidJson, setIsValidJson] = useState(true);

    const handleBlur = (e) => {
        try {
            // only formatting on blur so the cursor doesn't jump around
            const parsed = JSON.parse(internalValue);

            setValue(JSON.stringify(parsed, null, 4));

            setInternalValue(JSON.stringify(parsed, null, 4));
            setIsValidJson(true);
        } catch (error) {
            // reset to last valid value
            setInternalValue(value);
            setIsValidJson(true);
        }

        // onBlur will be called on unformatted json
        onBlur?.(e);
    };

    const handleChange = (updatedValue) => {
        try {
            JSON.parse(updatedValue);

            setValue(updatedValue);

            setInternalValue(updatedValue);
            setIsValidJson(true);
        } catch (error) {
            setInternalValue(updatedValue);
            setIsValidJson(false);
        }
    };

    return (
        <div className="json-input">
            <div className={classnames('json-input__editor', !isValidJson && 'json-input__editor-error', className)}>
                <Editor
                    value={internalValue}
                    /* tslint:disable-next-line:jsx-no-lambda */
                    onValueChange={handleChange}
                    /* tslint:disable-next-line:jsx-no-lambda */
                    highlight={(code: any) => highlight(code, languages.json)}
                    padding={15}
                    className="json-input__code"
                    onBlur={handleBlur}
                    {...props}
                />
            </div>
            {!isValidJson && <span className="json-input__error">{props.invalidSyntaxError}</span>}
        </div>
    );
};
