import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import Card from '../Card';

import './style.scss';
import { typeOfComponent } from '../../utils/children.ts';
import { withIcon } from '../../../core_updated/components/Icon.tsx';
import {
    faCheckCircle,
    faExclamationCircle,
    faInfoCircle,
    faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';

const InfoIcon = withIcon(faInfoCircle);
const WarningIcon = withIcon(faTriangleExclamation);
const ErrorIcon = withIcon(faExclamationCircle);
const SuccessIcon = withIcon(faCheckCircle);

const SEVERITY_LEVELS = ['info', 'warning', 'error', 'success'];

interface IProps {
    severity?: string;
    children?: any;
    className?: string;
}

export const Alert = (props: IProps) => {
    const { severity, className, children } = props;
    const severityLevel = SEVERITY_LEVELS.includes(severity) ? severity : SEVERITY_LEVELS[0];

    const content = React.Children.map(children, (child) => {
        if (child && ['AlertTitle', 'AlertContent'].includes(typeOfComponent(child))) return child;
        return <AlertContent>{child}</AlertContent>;
    });

    return (
        <Card className={classnames('alert', `alert--${severityLevel}`, className)} level={1}>
            <span className="alert__icon">
                {severityLevel === 'info' && <InfoIcon />}
                {severityLevel === 'warning' && <WarningIcon />}
                {severityLevel === 'error' && <ErrorIcon />}
                {severityLevel === 'success' && <SuccessIcon />}
            </span>
            {content}
        </Card>
    );
};

export const AlertBar = (props: IProps) => {
    const { severity, className, children } = props;
    const severityLevel = SEVERITY_LEVELS.includes(severity) ? severity : SEVERITY_LEVELS[0];

    const content = React.Children.map(children, (child) => {
        if (child && ['AlertTitle', 'AlertContent'].includes(typeOfComponent(child))) return child;
        return <AlertContent>{child}</AlertContent>;
    });

    return <div className={classnames('alert-bar', `alert-bar--${severityLevel}`, className)}>{content}</div>;
};

Alert.displayName = 'Alert';

export const AlertTitle = (props) => <h4 className="alert__title">{props.children}</h4>;

AlertTitle.displayName = 'AlertTitle';

export const AlertContent = (props) => <div className="alert__content">{props.children}</div>;

AlertContent.displayName = 'AlertContent';

export default Alert;
