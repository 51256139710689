import * as React from 'react';
import { useState } from 'react';
import classnames from '../../../core_updated/utils/classnames';

import Chip from '../../components/Chip';
import { IProps as InputProps } from '../../components/Input';

import './style.scss';

interface IProps extends InputProps {
    children?: any;
    onSubmit?: any;
    submitKeys?: string[];
    separator?: string;
}

const ChipInput = (props: IProps) => {
    const {
        children,
        className,
        onSubmit,
        onChange,
        submitKeys = ['Enter', 'Tab'],
        separator = ',',
        ...inputProps
    } = props;

    const [value, setValue] = useState('');

    const handleKeyDown = (event) => {
        if (![...submitKeys, separator].includes(event.key) || !value) return;
        event.preventDefault();

        // this way we can validate the value on the outside and can explicitly stop clearing it
        if (value && onSubmit([event.target.value]) !== false) setValue('');
    };

    const handleBlur = (event) => {
        event.preventDefault();
        if (value && onSubmit([event.target.value]) !== false) setValue('');
    };

    // This could be cool if pasting a list - maybe it's confusing though
    const handlePaste = (event) => {
        const pasteValue = (event.clipboardData || (window as any).clipboardData).getData('text');
        if (pasteValue.includes(separator)) {
            onSubmit(pasteValue.split(separator));
            event.preventDefault();
        }
    };

    const handleChange = (event) => {
        if (onChange) onChange(event);
        setValue(event.target.value);
    };

    return (
        <div className={classnames('input', 'chip-input', className)}>
            {children}
            <input
                className="chip-input__input"
                onKeyDown={handleKeyDown}
                value={value}
                onChange={handleChange}
                onPaste={handlePaste}
                onBlur={handleBlur}
                {...inputProps}
            />
        </div>
    );
};

export default ChipInput;

interface ISimpleProps extends IProps {
    value: any;
    setValue: any;
}

export const SimpleChipInput = (props: ISimpleProps) => {
    const { value = [], setValue, readOnly = false, ...chipInputProps } = props;

    const handleSubmit = (values) => setValue([...(value || []), ...values.map((v) => v.trim()).filter((v) => v)]);
    const handleDelete = (cid) => setValue((value || []).filter((chip, chipId) => cid != chipId));

    return (
        <ChipInput onSubmit={handleSubmit} readOnly={readOnly} {...chipInputProps}>
            {(value || []).map((chip, cid) => (
                <Chip key={cid} label={chip} deletable={!readOnly} onDelete={() => handleDelete(cid)} />
            ))}
        </ChipInput>
    );
};
