import Checkbox from '../../core_updated/components/Checkbox.tsx';
import * as React from 'react';

const CheckboxGroup = ({
    options,
    value,
    onValueChange,
}: {
    options: { value: string; label: string; [key: string]: string }[];
    value: string[];
    onValueChange: (value: string[]) => void;
}) => {
    return (
        <div className="flex flex-col">
            {options.map((option) => (
                <label
                    key={option.value}
                    className="flex gap-2 items-center group hover:bg-secondary-light p-1 rounded transition-colors focus-within:bg-secondary-light"
                >
                    <Checkbox
                        checked={value.includes(option.value)}
                        onChange={(e) => {
                            if (e.target.checked) {
                                onValueChange([...value, option.value]);
                            } else {
                                onValueChange(value.filter((v) => v !== option.value));
                            }
                        }}
                        disabled={!!option.disabled}
                        className="group-hover:border-brand"
                    />

                    <span>{option.label}</span>
                </label>
            ))}
        </div>
    );
};

export default CheckboxGroup;
