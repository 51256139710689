import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ChannelContext, IChannel, useChannelStore } from '../../../stores';
import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash';
import Loader from '../../components/Loader';
import classnames from '../../../core_updated/utils/classnames';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';
import { DocumentType } from '../../../generic_document/constants';

export const useChannels = (documentType: DocumentType) => {
    const { channelId } = useParams();
    const activeChannelId = useChannelStore((state) => state.channelId) || channelId;

    const { data, error, loading } =
        documentType != null
            ? useChannelStore().getDocumentChannels(documentType)
            : { data: {}, error: null, loading: false };

    const channels = loading || !data ? [] : (flatten(Object.values(data)) as IChannel[]);
    const activeChannel = channels.find((channel) => channel.id === activeChannelId);

    return { channels, activeChannel, loading, error };
};

const MainShell = (props) => {
    const { children, documentType, title, subtitle, hasChannelContext = true, className } = props;

    const { t } = useTranslation('config');
    const { activeChannel, loading } = useChannels(documentType);

    const hasHeader = subtitle || title;

    return (
        <Layout>
            <style>{'.header, .footer {display: none}'}</style>

            <ChannelContext.Provider value={{ activeChannel, documentType }}>
                <div className={classnames('main-shell dark-mode-ready', className)}>
                    <div className="main-shell__inner">
                        {hasHeader && (
                            <hgroup className="main-shell__right-header">
                                {subtitle && <h2 className="main-shell__subtitle">{t(subtitle)}</h2>}
                                {title && <h1 className="main-shell__title">{t(title)}</h1>}
                            </hgroup>
                        )}
                        {loading || (hasChannelContext && activeChannel == null) ? (
                            <>
                                <Loader />
                                <Loader />
                            </>
                        ) : (
                            <div>{children}</div>
                        )}
                    </div>
                </div>
            </ChannelContext.Provider>
        </Layout>
    );
};

export default MainShell;
