import * as React from 'react';
import { BooleanField, SelectField, StringField, TextField } from '../Fields';
import { useController, useWatch } from 'react-hook-form';
import classnames from '../../../core_updated/utils/classnames';
import { useTranslation } from 'react-i18next';

import Switch from '../../../core/components/Switch';

import '../DocumentConfig/FieldsForm.scss';
import { useState } from 'react';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import { withIcon } from '../../../core_updated/components/Icon';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import DropdownMenu from '../../../core_updated/components/DropdownMenu';

// based on ValueType from backend
const FIELDS = {
    string: StringField,
    boolean: BooleanField,
};

export const universalGetInputControlComponent = (t, formField, value) => {
    let component = FIELDS[formField.valueType] || StringField;
    let maxLength = null;
    let readOnly = false;
    let choices = null;

    if (formField.name === 'data_label_instructions') {
        component = TextField;
        maxLength = 500;
    }

    if (formField.name == 'data_type') {
        component = SelectField;
        choices = ['string', 'decimal', 'date'];
    }

    // When the value is not 'undefined' is because it has already been saved into the DB,
    // therefore it should be displays as read only.
    if (formField.name === 'data_label_name' && formField.value !== undefined) {
        readOnly = true;
    }

    return {
        component: component,
        inputProps: {
            placeholder: t(`documentConfig.fieldNames.${formField.name}`),
            required: value !== null,
            readOnly: readOnly,
        },
        fieldProps: {
            maxLength: maxLength,
            choices: choices,
        },
    };
};

export const UniversalFormField = ({
    control,
    fieldName,
    name,
    label,
    render,
    className,
    nullable,
    defaultValue = '',
    valueType,
    duplicatedFieldName,
}: any) => {
    if (!defaultValue) defaultValue = '';

    const {
        field: { onChange, onBlur },
    } = useController({
        name: name,
        control: control,
    });

    // field.value uses useWatch with "exact = true" which doesn't work for nested fields,
    // so we need to make it explicit without
    const value = useWatch({ control, name: name });

    const isBoolean = valueType === 'boolean';
    const [isFocus, setIsFocus] = useState(false);

    const handleChange = (value) => {
        onChange(value);
    };

    const handleOnFocus = (value) => {
        setIsFocus(true);
    };

    const handleOnBlur = (value) => {
        setIsFocus(false);
    };

    const handleToggleSwitch = (checked) => {
        if (isBoolean) {
            onChange(checked);
        } else if (nullable) {
            onChange(checked ? defaultValue : null);
        }
    };

    const obtainTooltipMessage = (fieldName, value, isFocus) => {
        const { t } = useTranslation('config');

        const message = t(`documentConfig.fieldNames.${fieldName}_explanation`);
        const notErrorMessageStyle = 'form-field__tooltip';
        const errorMessageStyle = 'form-field__tooltip_error';

        if (value === '') {
            const requiredFieldMessage = t('documentConfig.fieldNames.required_field');
            return isFocus ? [message, notErrorMessageStyle] : [requiredFieldMessage, errorMessageStyle];
        }

        if (duplicatedFieldName && fieldName === 'data_label_name' && !isFocus) {
            const uniqueFieldMessage = t('documentConfig.fieldNames.unique_field');
            return [uniqueFieldMessage, errorMessageStyle];
        }

        return [message, notErrorMessageStyle];
    };

    const [tooltipMessage, tooltipClassName] = obtainTooltipMessage(fieldName, value, isFocus);

    return (
        <div className={classnames('form-field', className)}>
            <label htmlFor={name} className={`form-field__label ${!isBoolean ? 'form-field__required' : ''}`}>
                <span>{label}</span>
                {isBoolean && (
                    <Switch
                        value={isBoolean ? value : value != undefined}
                        onValueChange={(checked) => handleToggleSwitch(checked)}
                    />
                )}
            </label>
            {!isBoolean &&
                render?.({ name, value, onChange: handleChange, onFocus: handleOnFocus, onBlur: handleOnBlur })}
            {!isBoolean && (
                <label htmlFor={name} className={tooltipClassName}>
                    {tooltipMessage}
                </label>
            )}
        </div>
    );
};

export const UniversalAddFieldConfigModal = ({
    tabs,
    onAddOrEditTable,
    setAddTableModalVisible,
    initialFieldsValues,
}) => {
    const { t } = useTranslation('config');

    const [fieldsValues, setFieldsValues] = useState(initialFieldsValues);
    const [focusedField, setFocusedField] = useState(null);

    const fieldNamesToAdd = ['table_data_label_name', 'table_field_label_name'];
    const onAddTableMode = Object.keys(initialFieldsValues).length === 0;
    let isConfirmButtonDisabled = false;

    const handleFocus = (fieldName) => {
        setFocusedField(fieldName);
    };

    const handleBlur = () => {
        setFocusedField(null);
    };

    const handleFieldValueChange = (fieldName, value) => {
        setFieldsValues((prevFieldsValues) => ({
            ...prevFieldsValues,
            [fieldName]: value,
        }));
    };

    const obtainTooltipMessage = (fieldName, value) => {
        const { t } = useTranslation('config');

        const message = t(`documentConfig.fieldNames.${fieldName}_explanation`);
        const notErrorMessageStyle = 'form-field__tooltip';
        const errorMessageStyle = 'form-field__tooltip_error';
        const isFocus = fieldName === focusedField;

        if (value === '') {
            isConfirmButtonDisabled = true;
            const requiredFieldMessage = t('documentConfig.fieldNames.required_field');
            return isFocus ? [message, notErrorMessageStyle] : [requiredFieldMessage, errorMessageStyle];
        }

        if (fieldName === 'table_data_label_name' && onAddTableMode && tabs.includes(value)) {
            isConfirmButtonDisabled = true;
            const uniqueFieldMessage = t('documentConfig.fieldNames.unique_field');
            return isFocus ? [message, notErrorMessageStyle] : [uniqueFieldMessage, errorMessageStyle];
        }

        return [message, notErrorMessageStyle];
    };

    let fieldsInfo = {};
    fieldNamesToAdd.map((fieldName) => {
        const [tooltipMessage, tooltipClassName] = obtainTooltipMessage(fieldName, fieldsValues?.[fieldName]);
        fieldsInfo[fieldName] = {
            tooltipMessage: tooltipMessage,
            tooltipClassName: tooltipClassName,
        };
    });

    return (
        <ConfirmModal
            title={
                onAddTableMode
                    ? t('documentConfig.universal.fieldConfigModal.title_add')
                    : t('documentConfig.universal.fieldConfigModal.title_edit')
            }
            labelConfirm={
                onAddTableMode
                    ? t('documentConfig.universal.fieldConfigModal.addButton')
                    : t('documentConfig.universal.fieldConfigModal.saveButton')
            }
            labelCancel={t('documentConfig.universal.fieldConfigModal.cancelButton')}
            noHeader={false}
            onConfirm={() => {
                setAddTableModalVisible(false);
                onAddOrEditTable(fieldsValues, onAddTableMode);
            }}
            onCancel={() => setAddTableModalVisible(false)}
            confirmDisabled={isConfirmButtonDisabled}
        >
            {fieldNamesToAdd.map((fieldName, index) => {
                const fieldInfo = fieldsInfo?.[fieldName];
                return (
                    <div className="form-field" key={index}>
                        <label className="form-field__required">
                            <span>{t(`documentConfig.fieldNames.${fieldName}`)}</span>
                        </label>
                        <div className="field field__content">
                            <input
                                className="field__input field__input--string"
                                placeholder={t(`documentConfig.fieldNames.${fieldName}`)}
                                value={fieldsValues?.[fieldName]}
                                onChange={(e) => handleFieldValueChange(fieldName, e.target.value)}
                                onFocus={() => handleFocus(fieldName)}
                                onBlur={handleBlur}
                                readOnly={index === 0 && !onAddTableMode}
                            />
                        </div>
                        <label className={fieldInfo?.tooltipClassName}>{fieldInfo?.tooltipMessage}</label>
                    </div>
                );
            })}
        </ConfirmModal>
    );
};

interface TabControlIconButtonProps extends React.ComponentPropsWithRef<'button'> {
    active?: boolean;
}

const TabControlIconButton = React.forwardRef(
    ({ className, active = false, ...props }: TabControlIconButtonProps, ref: any) => {
        return (
            <button
                className={classnames(
                    'flex items-center justify-center gap-2 rounded transition-colors duration-200 hover:bg-tertiary w-8 h-8 cursor-pointer text-sm font-medium text-primary hover:text-primary disabled:opacity-50 disabled:pointer-events-none data-[state=open]:bg-brand data-[state=open]:text-brand',
                    active && '!bg-brand !text-brand',
                    className
                )}
                {...props}
                ref={ref}
            />
        );
    }
);

export const UniversalTabMoreButton = ({ tableId, tableName, onDeleteTable, onEditTable }) => {
    const { t } = useTranslation('config');

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const MoreIcon = withIcon(faEllipsisVertical);

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger>
                    <TabControlIconButton className={'tab-more-button'}>
                        <MoreIcon />
                    </TabControlIconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content align="end">
                    <DropdownMenu.Item onClick={() => onEditTable(tableId)}>
                        {t('documentConfig.universal.moreTabOptions.edit')}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item
                        className="text-error hover:text-error hover:!bg-error"
                        onClick={() => setDeleteModalVisible(true)}
                    >
                        {t('documentConfig.universal.moreTabOptions.delete')}
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu>
            <ConfirmModal
                title={t('documentConfig.universal.deleteTableModal.title')}
                labelConfirm={t('documentConfig.universal.deleteTableModal.confirmButton')}
                labelCancel={t('documentConfig.universal.deleteTableModal.cancelButton')}
                noHeader={false}
                onConfirm={() => {
                    setDeleteModalVisible(false);
                    onDeleteTable(tableId);
                }}
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                danger
            >
                <p>{t('documentConfig.universal.deleteTableModal.message', { table_name: tableName })}</p>
            </ConfirmModal>
        </>
    );
};
