import { useState } from 'react';

export const useControllableState = <T,>(
    initialValue: T,
    controlledValue?: T,
    setControlledValue?: (value: T) => void
): [T, (value: T | ((prevValue: T) => T)) => void] => {
    const [stateValue, setState] = useState<T>(initialValue);
    const effectiveValue = controlledValue !== undefined ? controlledValue : stateValue;
    return [
        effectiveValue,
        (newValue: T | ((prevValue: T) => T)) => {
            setState(newValue);
            if (setControlledValue) {
                if (typeof newValue === 'function') {
                    setControlledValue((newValue as (prevValue: T) => T)(effectiveValue));
                } else {
                    setControlledValue(newValue);
                }
            }
        },
    ];
};
