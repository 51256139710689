import * as React from 'react';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from '../../../core_updated/utils/classnames';
import { useAssistanceFieldContext } from './AssistanceFieldContext';
import { fieldIsGreen, fieldIsRed, fieldIsYellow } from './utils';
import { useAssistanceContext } from './AssistanceContext';
import Button from '../../../core_updated/components/Button';

export const useConfidenceExplanations = ({ wrapperRef, isFocusActive }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [alignment, setAlignment] = useState<'start' | 'center' | 'end'>('start');

    const updateAlignment = () => {
        const wrapperRect = wrapperRef.current?.getBoundingClientRect();
        const formGroup = wrapperRef.current?.closest('.js-form-item-group');
        const formGroupRect = formGroup?.getBoundingClientRect();
        if (!wrapperRect || !formGroupRect) return;
        // distance to the left and right INCLUDING the width of the current field
        const spaceLeft = wrapperRect?.right - formGroupRect?.left;
        const spaceRight = formGroupRect?.left + formGroup?.scrollWidth - wrapperRect?.left;

        if (spaceRight >= 450) {
            setAlignment('start');
        } else if (spaceRight < 450 && spaceLeft > 450) {
            setAlignment('end');
        } else {
            setAlignment('center');
        }
    };

    const handleFocus = (e) => {
        setIsOpen(true);
        updateAlignment();
    };

    const handleBlur = (e) => {
        if (!isMouseOver) {
            setIsOpen(false);
        }
    };

    const handleIconMouseEnter = (e) => {
        setIsOpen(true);
        updateAlignment();
    };

    const handleMouseEnter = (e) => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = (e) => {
        setIsMouseOver(false);
        if (!isFocusActive) {
            // keep explanation open while cursor moves around
            setIsOpen(false);
        }
    };

    const handleMouseOut = (e) => {
        // this can be bubbled from children
        setIsMouseOver(false);
    };

    return {
        isOpen,
        alignment,
        onIsOpenChange: setIsOpen,
        onFieldFocus: handleFocus,
        onFieldBlur: handleBlur,
        onIconMouseEnter: handleIconMouseEnter,
        onFieldMouseEnter: handleMouseEnter,
        onFieldMouseLeave: handleMouseLeave,
        onFieldMouseOut: handleMouseOut,
    };
};

const ConfidenceExplanation = ({
    confidenceMessage,
    matchingMessage,
    confidence,
    severity,
    acceptVisible,
    acceptAction,
    onAccept,
}) => {
    const { t } = useTranslation('assistance');

    const { readOnly } = useAssistanceContext();
    const { data, config, value } = useAssistanceFieldContext();
    const cleanedMatchingMessage = DOMPurify.sanitize(matchingMessage);
    const cleanedConfidenceMessage = DOMPurify.sanitize(confidenceMessage);

    return (
        <div className="flex flex-col overflow-hidden">
            {cleanedConfidenceMessage && (
                <div
                    className={classnames(
                        'text-xs leading-relaxed break-words mt-1 p-2 text-primary bg-secondary border border-secondary border-solid rounded w-full flex-1',

                        fieldIsGreen({ data, config, value }, { includeMatching: false }) &&
                            'text-success bg-success border-success',
                        fieldIsYellow({ data, config, value }, { includeMatching: false }) &&
                            'text-warning bg-warning border-warning',
                        fieldIsRed({ data, config, value }, { includeMatching: false }) &&
                            'text-error bg-error border-error'
                    )}
                    dangerouslySetInnerHTML={{ __html: cleanedConfidenceMessage }}
                />
            )}

            {cleanedMatchingMessage && (
                <div
                    className={classnames(
                        'text-xs break-words mt-1 p-2 text-primary bg-secondary border border-secondary border-solid rounded w-full flex-1',

                        // explicitly set the color based only on the severity level
                        severity === 'error' && 'text-error bg-error border-error',
                        severity === 'warning' && 'text-warning bg-warning border-warning',
                        severity === 'success' && 'text-success bg-success border-success'
                    )}
                >
                    <span dangerouslySetInnerHTML={{ __html: cleanedMatchingMessage }} />
                    {acceptVisible ? (
                        <Button
                            onClick={(e) => onAccept()}
                            className={classnames(
                                'text-xs mt-2',
                                severity === 'error' && 'border-error ',
                                severity === 'warning' && 'border-warning',
                                severity === 'success' && 'border-success'
                            )}
                            disabled={readOnly}
                        >
                            {t(`assistance:${acceptAction}`)}
                        </Button>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default ConfidenceExplanation;
