import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classnames from '../../utils/classnames';
import { withIcon } from '../Icon';
import { faCheck, faCircleNotch, faChevronDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';
import { mergeRefs } from '../../utils/mergeRefs';

export interface Option {
    label: string;
    value: string;
    role?: string;
    description?: string;
    disabled?: boolean;
    [key: string]: any;
}

interface SelectProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'value' | 'defaultValue'>,
        FieldProps {
    options: Option[];
    renderValue?: (value: string, selectedOption?: Option) => React.ReactNode;
    renderOption?: (option: Option, value: string) => React.ReactNode;
    emptyMessage?: string;
    isLoading?: boolean;
    disabled?: boolean;
    required?: boolean;
    placeholder?: string;
    readOnly?: boolean;
}

const ClearIcon = withIcon(faXmark);
const CheckIcon = withIcon(faCheck);
const ChevronDownIcon = withIcon(faChevronDown);
const LoadingCircleIcon = withIcon(faCircleNotch);

const defaultRenderValue = (value: string, selectedOption?: Option) => selectedOption?.label || value;

const defaultRenderOption = (option: Option) => (
    <span>
        <span className="text-primary">{option.label}</span>
        {option.label !== option.value ? <span className="block text-xs text-tertiary">{option.value}</span> : null}
        {option.description ? <span className="block text-xs text-tertiary">{option.description}</span> : null}
    </span>
);

const NonModalSelectField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            onFocus,
            onBlur,
            readOnly,
            disabled,
            required,
            controls,
            className,
            inputRef = undefined,
            options = [],
            renderValue = defaultRenderValue,
            renderOption = defaultRenderOption,
            isLoading = false,
            ...props
        }: SelectProps,
        ref: any
    ) => {
        const triggerRef = React.useRef<HTMLButtonElement>(null);
        const [value, setValue] = useControllableState(defaultValue, propsValue, (value) => {
            onValueChange?.(value);
            // @ts-ignore
            onChange?.({ target: { value } });
        });
        const selectedOption = options.find((option) => option.value === value);

        const handleSelectOption = (option: Option) => {
            if (!option.disabled) {
                setValue(option.value);
            }
            // force mouseout to close confidence explanation
            triggerRef.current?.dispatchEvent(new MouseEvent('mouseout', { bubbles: true }));
        };

        const handleClear = () => {
            setValue('');
            // simulate pointer down event to open dropdown - update is saved when dropdown is closed
            triggerRef.current?.dispatchEvent(
                new PointerEvent('pointerdown', {
                    bubbles: true,
                    cancelable: true,
                    button: 0,
                })
            );
        };

        const handlePointerDownOutside = (event) => {
            // Prevent closing if the click is on the anchor element
            if (triggerRef.current && triggerRef.current.contains(event.target)) {
                event.preventDefault();
            } else {
                // refocus the trigger element to prevent focus loss
                setTimeout(() => {
                    triggerRef.current?.focus();
                }, 0);
            }
        };

        return (
            <DropdownMenu.Root modal={false}>
                <Field
                    className={classnames('shadow-sm group', className)}
                    readOnly={readOnly}
                    disabled={disabled}
                    ref={ref}
                >
                    <Field.Input>
                        <DropdownMenu.Trigger
                            className="px-2 py-1.5 flex-1 min-w-0 justify-between items-center flex bg-transparent text-left min-h-8 relative"
                            disabled={disabled || readOnly}
                            {...props}
                            onClick={(e) => onFocus?.(null)}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            ref={mergeRefs(inputRef, triggerRef)}
                        >
                            {value ? (
                                renderValue(value, selectedOption)
                            ) : (
                                <span className="text-tertiary">{props.placeholder}</span>
                            )}
                        </DropdownMenu.Trigger>
                    </Field.Input>
                    <Field.Controls>
                        {!readOnly && (
                            <Field.ControlButton className="group-hover:opacity-100 point !bg-transparent">
                                {isLoading ? (
                                    <LoadingCircleIcon spin />
                                ) : (
                                    <ChevronDownIcon
                                        onClick={() => {
                                            // trigger on pointer down on trigger ref
                                            if (triggerRef.current) {
                                                triggerRef.current.dispatchEvent(
                                                    new PointerEvent('pointerdown', { bubbles: true })
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Field.ControlButton>
                        )}
                        {!required && !readOnly && value && (
                            <Field.ControlButton onClick={handleClear}>
                                <ClearIcon />
                            </Field.ControlButton>
                        )}
                        {controls}
                    </Field.Controls>
                </Field>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        className="p-1 z-[60000] w-full max-h-96 overflow-hidden rounded-md border bg-primary shadow-md outline-none animate-in fade-in-0 zoom-in-95"
                        align="start"
                        sideOffset={5}
                        onPointerDown={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        onFocusOutside={(e) => {
                            e.preventDefault();
                        }}
                        onPointerDownOutside={handlePointerDownOutside}
                    >
                        <div className="max-h-[300px] overflow-auto">
                            {options.map((option: Option, i: number) => (
                                <DropdownMenu.Item
                                    key={i}
                                    onSelect={() => handleSelectOption(option)}
                                    disabled={option.disabled}
                                    className={classnames(
                                        'flex items-center gap-2 w-full',
                                        'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-4 text-sm outline-none hover:bg-secondary focus:bg-secondary',
                                        option.disabled && 'opacity-50 pointer-events-none',
                                        value === option.value && 'bg-brand text-neutral-900'
                                    )}
                                >
                                    {renderOption(option, value)}
                                    {value === option.value && (
                                        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center text-primary">
                                            <CheckIcon className="h-4 w-4" />
                                        </span>
                                    )}
                                </DropdownMenu.Item>
                            ))}
                        </div>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        );
    }
);

export default NonModalSelectField;
