import * as React from 'react';
import classnames from '../../../core_updated/utils/classnames';

import { getCopyrightUrl, getImprintUrl, getPrivacyUrl } from '../../../support/constants';

import './style.scss';

interface IProps {
    children: any;
    className?: string;
}

const LoginWrapper = (props: IProps) => {
    const { children, className } = props;

    const languageCode = navigator.language.split('-')[0] === 'de' ? 'de' : 'en';

    return (
        <main className={classnames('login', className)}>
            <div className="login__card-wrapper">
                <div className="login__card-fly-in-wrapper">{children}</div>
            </div>

            <footer className="login__footer">
                <a href={getCopyrightUrl(languageCode)}>&copy; {new Date().getFullYear()} workist &trade;</a>
                <span>
                    <a href={getImprintUrl(languageCode)}>Impressum</a>
                    <a href={getPrivacyUrl(languageCode)}>Datenschutz</a>
                </span>
            </footer>
        </main>
    );
};

export default LoginWrapper;
